
import React from "react";
import NoDataImg from '../../assets/images/not-found.gif'

function NoData() {
  return (
    <>
      <div className="no-data">
        <img  src={NoDataImg} alt="No Data Found"  className="img-fluid" loading="lazy"/>
        <p>Data Not Found.</p>
      </div>
    </>
  );
}

export default NoData;
