import React, { useEffect, useRef } from "react";
import serviceBlockImg from "../assets/images/home/img-3.svg";
import Title from "./Title/Title";
import Para from "./Title/Para";
import { AiFillCar } from 'react-icons/ai'
import {  GiWorld } from 'react-icons/gi'
import { FaTruckMoving } from 'react-icons/fa'
import { BiCurrentLocation } from 'react-icons/bi'
import { RiCustomerService2Fill, RiShip2Fill } from 'react-icons/ri'
import { MdMoneyOff } from 'react-icons/md'
import { GrDocumentUser } from 'react-icons/gr'
import AOS from "aos";

const Features = () => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      AOS.init({
        duration: 50,
      });
      AOS.refresh();
    }
    return () => (mounted.current = false);
  }, []);

  return (
    <>
      <section className="features_wrapper py-5">
        <Title title="Why Choose Us" />
        <Para para="We offer you comprehensive quality service and the best rate in town. With little to no paperwork, hassle-free service, and world-class customer service, we are the best choice in car removal, buying a used car in Australia." />
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="card bg-transparent border-0">
                <div className="card-body text-start">
                  <div className="features" data-aos="fade-down" data-aos-duration='500'>
                    <div className="feature-icon">
                      <span>
                        <AiFillCar className="icons"/>
                      </span>
                    </div>
                    <div className="feature-text">
                      <h3>Good tracking system</h3>
                      <p>
                      Always Know about your vehicle location.
                      </p>
                    </div>
                  </div>

                  <div className="features" data-aos="fade-down" data-aos-duration='700'>
                    <div className="feature-icon">
                      <span>
                        <BiCurrentLocation className="icons"/>
                      </span>
                    </div>
                    <div className="feature-text">
                      <h3>Sydney location</h3>
                      <p>
                      We Deal in every location and condition.
                      </p>
                    </div>
                  </div>
                  <div className="features" data-aos="fade-down" data-aos-duration='900'>
                    <div className="feature-icon">
                      <span>
                        <RiShip2Fill className="icons"/>
                      </span>
                    </div>
                    <div className="feature-text">
                      <h3>pickup in time</h3>
                      <p>
                      Instant service and also Emergency service available.
                      </p>
                    </div>
                  </div>
                  <div className="features" data-aos="fade-down" data-aos-duration='1100'>
                    <div className="feature-icon">
                      <span>
                        <GiWorld className="icons"/>
                      </span>
                    </div>
                    <div className="feature-text">
                      <h3>huge storage</h3>
                      <p>
                      Provide huge storage for your junk vehicles.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4 feature-img">
              <div className="card bg-transparent border-0">
                <div className="card-body text-center">
                  <img
                    src={serviceBlockImg}
                    alt=""
                    className="img-fluid mb-4 service_phone"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="card bg-transparent border-0">
                <div className="card-body text-start">
                <div className="features" data-aos="fade-down" data-aos-duration='500'>
                    <div className="feature-icon">
                      <span>
                        <FaTruckMoving className="icons"/>
                      </span>
                    </div>
                    <div className="feature-text">
                      <h3>instant payment</h3>
                      <p>
                      We always offer you cash almost instantly.
                      </p>
                    </div>
                  </div>
                  <div className="features" data-aos="fade-down" data-aos-duration='700'>
                    <div className="feature-icon">
                      <span>
                        <RiCustomerService2Fill className="icons"/>
                      </span>
                    </div>
                    <div className="feature-text">
                      <h3>hassle free service</h3>
                      <p>
                      We value your time, Doing fast action.
                      </p>
                    </div>
                  </div>
                  <div className="features" data-aos="fade-down" data-aos-duration='900'>
                    <div className="feature-icon">
                      <span>
                        <MdMoneyOff className="icons"/>
                      </span>
                    </div>
                    <div className="feature-text">
                      <h3>no charges</h3>
                      <p>
                      We provide our services in affordable charges.
                      </p>
                    </div>
                  </div>
                  <div className="features" data-aos="fade-down" data-aos-duration='1100'>
                    <div className="feature-icon">
                      <span>
                        <GrDocumentUser className="icons"/>
                      </span>
                    </div>
                    <div className="feature-text">
                      <h3>paper work</h3>
                      <p>
                      We offer you cash almost instantly with few paperwork.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
