import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaArrowAltCircleUp } from 'react-icons/fa'
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const scrollToTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"})
  };

  return (
    <div className="scroll-fixed" onClick={scrollToTop}>    
      <FaArrowAltCircleUp className="scroll-icon"/>
  </div>
  );
}