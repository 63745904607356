import React, { useEffect, useRef } from "react";
import AboutImg from "../assets/images/services/32.jpeg";
import { Link } from "react-router-dom";
import { AiFillCar } from "react-icons/ai";
import { GiSteeringWheel } from 'react-icons/gi'
import { SiTransportforlondon } from "react-icons/si";
import { FaWarehouse } from "react-icons/fa";
import AOS from "aos";

const About = () => {

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      AOS.init({
        duration: 50,
      });
      AOS.refresh();
    }
    return () => (mounted.current = false);
  }, []);
  return (
    <>
      <section className="about_wrapper">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-5 col-sm-12 mb-4 mb-md-0">
              <h2 className="about-heading text-left">What We Offer</h2>
              <p className="sub-text">Auswide Car Removal is a car removal service provider in Sydney, offering you the best price on your junk cars. Established since 2014.</p>
              <img src={AboutImg} alt="" className="img-fluid" data-aos="fade-right" data-aos-duration='700' loading="lazy"/>
            </div>
            <div className="col-md-7 col-sm-12 text-md-start">
              <div className="row">
                <Link className="col-lg-5 col-md-12 col-11 service-box" to="/services/1" >
                <div className="icon-img">
                      <AiFillCar className="about-icon"/>
                    </div>
                  <h6>
                    Good price & High Cost
                  </h6>
                  <p className="m-0">
                  After contacting our company, our fully licensed and expert drivers will be you within 30 minutes. 
                  </p>
                </Link>
                <Link className="col-lg-5 col-md-12 col-11 service-box" to="/services/2">
                <div className="icon-img">
                      <GiSteeringWheel />
                    </div>
                  <h6>
                    
                    Quick Support
                  </h6>
                  <p className="m-0">
                  After contacting our company, our fully licensed and expert drivers will be you within 30 minutes. 
                  </p>
                </Link>
              </div>
              <div className="row">
                <Link className="col-lg-5 col-md-12 col-11 service-box" to="/services/3" data-aos="zoom-in" data-aos-duration='700'>
                <div className="icon-img">
                      <SiTransportforlondon />
                    </div>
                  <h6>
                    
                    Fast Services & Instant Pay
                  </h6>
                  <p className="m-0">
                  After contacting our company, our fully licensed and expert drivers will be you within 30 minutes. 
                  </p>
                </Link>
                <Link className="col-lg-5 col-md-12 col-11 service-box" to="/services/8" data-aos="zoom-in" data-aos-duration='700'>
                <div className="icon-img">
                      <FaWarehouse />
                    </div>
                  <h6>
                 
                    Keep Legal & No Paper Work
                  </h6>
                  <p className="m-0">
                  After contacting our company, our fully licensed and expert drivers will be you within 30 minutes. 
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
