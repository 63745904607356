import React from "react";
import CountUp from 'react-countup';

const Email = () => {
  return (
    <>
      <section className="blog_wrapper">
        <div className="newsletter_wrapper">
          <div className="container py-5">
            <div className="row">
              <div className="col-12">
                <div className="counter text-center">
                  <div className="couter-content">
                    <h3 className="text-white counter-number"><CountUp end={61250}  duration={5.75}/><span></span></h3>
                    <p>Scrap Cars</p>
                  </div>
                  <div className="couter-content">
                    <h3 className="text-white counter-number"><CountUp end={210}  duration={5.75}/><span> +</span></h3>
                    <p>Locations</p>
                  </div>
                  <div className="couter-content">
                    <h3 className="text-white counter-number"><CountUp end={1186}  duration={5.75}/><span> +</span></h3>
                    <p>Happy Clients</p>
                  </div>
                  <div className="couter-content">
                    <h3 className="text-white counter-number"><CountUp end={508}  duration={5.75}/><span> +</span></h3>
                    <p>Best Clients</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Email;
