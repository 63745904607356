import React from "react";
import customerCare from "../assets/images/services/54.jpeg";
import service1 from '../assets/images/services/56.jpeg';
import service2 from '../assets/images/services/69.jpeg';
import service3 from '../assets/images/services/73.jpeg';
import service6 from '../assets/images/services/10.jpeg';
import ServiceImg from '../assets/images/services/50.jpeg';
import Title from "./Title/Title";
import Para from './Title/Para'
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      <section className="services_wrapper py-5">
        <Title title="Our Services" />
        <Para para="Auswide Car Removal is a car removal service provider, buy junk cars in sydney, offering you the best price on your junk cars. Established since 2014." />
        <div className="container">
          <div className="row pb-5">
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card bg-white border-1 rounded-2 text-center services-card">
                <div className="service-image-div">
                  <img src={service1} alt="" className="img-fluid" loading="lazy"/>
                </div>
                <div className="card-body p-3">
                  <Link to="/scrap-car.html"><h3>Scrap car in Sydney</h3></Link>
                  <p className="text-center">
                    We offer a full turn-key package that includes web design,
                    web promotion, software development and domain names
                    registration.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card bg-white border-1 rounded-2 text-center services-card">
              <div className="service-image-div">
              <img src={service2} alt="" className="img-fluid" loading="lazy"/>
              </div>
                <div className="card-body p-3">
                  <Link to="car-removal.html"><h3>Damage Car removal in Sydney</h3></Link>
                  <p className="text-center">
                    We offer a full turn-key package that includes web design,
                    web promotion, software development and domain names
                    registration.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card bg-white border-1 rounded-2 text-center services-card">
              <div className="service-image-div">
              <img src={service6} alt="" className="img-fluid" loading="lazy"/>
              </div>
                <div className="card-body p-3">
                  <Link to="/car-wreckers-in-sydney.html"><h3>Car wreckers in Sydney</h3></Link>
                  <p className="text-center">
                  Are you looking for car #wreckers? You have come to the right place. At Auswide Car Removal Service, we help you provide car
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card bg-white border-1 rounded-2 text-center services-card">
              <div className="service-image-div">
              <img src={service3} alt="" className="img-fluid" loading="lazy"/>
              </div>
                <div className="card-body p-3">
                  <Link to="/cash-for-scrap-cars-in-sydney.html"><h3>Cash for Scrap Cars in Sydney</h3></Link>
                  <p className="text-center">
                    We offer a full turn-key package that includes web design,
                    web promotion, software development and domain names
                    registration.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card bg-white border-1 rounded-2 text-center services-card">
              <div className="service-image-div">
              <img src={customerCare} alt="" className="img-fluid" loading="lazy"/>
              </div>
                <div className="card-body p-3">
                  <Link to="/old-car-removal.html"><h3>Car Removal</h3></Link>
                  <p className="text-center">
                    We offer a full turn-key package that includes web design,
                    web promotion, software development and domain names
                    registration.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card bg-white border-1 rounded-2 text-center services-card">
              <div className="service-image-div">
              <img src={ServiceImg} alt="" className="img-fluid" loading="lazy"/>
              </div>
                <div className="card-body p-3">
                  <Link to="/cars-for-disposal-in-sydney.html"><h3>Cars for Disposal</h3></Link>
                  <p className="text-center">
                    We offer a full turn-key package that includes web design,
                    web promotion, software development and domain names
                    registration.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
