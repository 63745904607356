import React from 'react'

const Map = () => {
  return (
    <div className="container-fluid my-5">
        <div className="row">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3310.3944321276417!2d150.998696!3d-33.93098200000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12be6246ba643b%3A0xf33dc40910330026!2sUnit%209%20Number%2F13%20Cooraban%20Rd%2C%20Milperra%20NSW%202214%2C%20Australia!5e0!3m2!1sen!2sin!4v1660482003403!5m2!1sen!2sin"
            width="600"
            height="450"
            loading="lazy"
          ></iframe>
        </div>
      </div>
  )
}

export default Map