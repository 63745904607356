import React from "react";
import ServiceItem from "./ServiceItem";

const ServiceLists = ({ services }) => {

  return (
    <div className="col-lg-12">
      <div className="row mb-4">
        {services.filter(servicetype => servicetype.type === "service").map((service) => (
          <ServiceItem service={service} /> 
        ))}
      </div>
    </div>
  );
};

export default ServiceLists;
