import React from "react";
import About from "../components/About";
import ServicesSection from "../components/ServicesSection";
import Testimonial from "../components/Testimonial";
import Email from "../components/Email";
import Blog from "../components/Blog";
import Features from "../components/Features";
import MetaTags from "react-meta-tags";
import Map from "../components/Contact/Map";
import Brands from "../components/Brands";
import Banner from "../components/Elements/Banner";
import HomeContent from "../components/HomeContent";

const Home = () => {
  return (
    <>
      <MetaTags>
        <title>Unwanted car removal in Sydney | cash for car Sydney</title>
        <meta
          name="description"
          content="Auswide Car Removal is a car removal service provider in Sydney, offering you $30000 the best price on your junk cars. Established since 2014."
        />
        <meta
          name="keywords"
          content="cash for car Sydney, junk car removals sydney, damaged car removal sydney,unwanted car removals sydney, sell your car for cash sydney, scrap car removal sydney, sydney car removal, buy junk cars sydney, cash for junk cars in sydney, cash for scrap cars in sydney, unwanted car removals in sydney, cash for cars in sydney"
        />
      </MetaTags>
      <Banner />
      <ServicesSection />
      <HomeContent />
      <About />
      <Features />
      <Email />
      <Testimonial />
      <Brands />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-10 offset-lg-1 offset-md-1 mt-2">
              <iframe
                width="100%"
                height="435"
                src="https://www.youtube.com/embed/jfKFXKUDf9Q"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <Blog />
      <Map />
    </>
  );
};

export default Home;
