import React, { useState, useEffect, useRef } from "react";
import { servicelist } from "../assets/data/ServicesData";
import AOS from "aos";
import ServiceLists from "../components/Service/ServiceLists";
import NoData from "../components/NoData/Nodata";
import MetaTags from "react-meta-tags";

const Services = () => {
  const [services, setServices] = useState(servicelist); 

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      // setLoading(true);
      AOS.init({
        duration: 50,
      });
      AOS.refresh();
    }
    return () => (mounted.current = false);
  }, []);

  return (
    <><MetaTags>
    <title>[Unwanted car removal] Our Services</title>
    <meta name="description" content="Auswide Car Removal is a car removal service provider in Sydney, offering you $30000 the best price on your junk cars. Established since 2014." />
    <meta
      name="keywords"
      content="cash for car Sydney, junk car removals sydney, damaged car removal sydney,unwanted car removals sydney, sell your car for cash sydney, scrap car removal sydney, sydney car removal, buy junk cars sydney, cash for junk cars in sydney, cash for scrap cars in sydney, unwanted car removals in sydney, cash for cars in sydney"
    />
  </MetaTags>
      <section className="services_page_wrapper mt-5">
        <div className="container">
          <div className="row">
            {
              services.length > 0 ? (
                <ServiceLists
              services={services}
            />
              ) : (
                <>
                
                  <div className="col-9 text-center">
                  <NoData />
                </div>
                </>
              )
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
