import React, { useState, useEffect, useRef } from "react";
import ContactForm from "../components/Contact/ContactForm";
import { MdPhone } from "react-icons/md";
import { FaRegEnvelope } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import AOS from "aos";
import MetaTags from "react-meta-tags";
import Map from '../components/Contact/Map'

const Contact = () => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      AOS.init({
        duration: 50,
      });
      AOS.refresh();
    }
    return () => (mounted.current = false);
  }, []);

  return (
    <>
    <MetaTags>
          <title>{Contact}-Get Cash for car</title>
          <meta name="description" content="When you call Auswide car removals, you receive up to $35,000 for your unwanted car. The number one reason to select Auswide Car removals is that we are Sydney's most respected and professional car removal service that offers top dollar for your unwatched car removals in Sydney." />
          <meta
            name="keywords"
            content="junk car removals sydney, damaged car removal sydney,unwanted car removals sydney, sell your car for cash sydney, scrap car removal sydney, sydney car removal, buy junk cars sydney, cash for junk cars in sydney, cash for scrap cars in sydney, unwanted car removals in sydney, cash for cars in sydney"
          />
        </MetaTags>
      <div className="contact-section container">
        <div className="row contact-content">
          <div className="contact-text-section col-lg-6 col-md-6 col-12">
            <h4>If you have any questions, Please send your message</h4>
            <h2 className="contact-head">Get In Touch With Our Experts</h2>
            <p className="desc-section">
              Auswide Car Removal is a car removal service provider in Sydney,
              offering you the best price on your junk cars. Established since
              2014, we buy all types, makes, models, and years of junk cars,
              trucks, vans, and SUVs.
            </p>
            <ul className="contact-list">
              <li
                className="contact-lists"
                data-aos="flip-left"
                data-aos-duration="600"
              >
                <div className="contact-icon">
                  <FiMapPin />
                </div>
                <div className="contact-text">
                  <h4 className="contact-tect-heading">Office Address</h4>
                  <p className="text-left">
                    9/13 Cooraban rd Milperra Nsw 2214 Australia.
                  </p>
                  <p className="text-left">19 Venn Avenue Lalor Park NSW NSW 2147 Australia</p>
                </div>
              </li>
              <li
                className="contact-lists"
                data-aos="flip-left"
                data-aos-duration="800"
              >
                <div className="contact-icon">
                  <MdPhone />
                </div>
                <div className="contact-text">
                  <h4 className="contact-tect-heading">Telephone Number</h4>
                  <p className="text-left">+61402700111</p>
                </div>
              </li>
              <li
                className="contact-lists"
                data-aos="flip-left"
                data-aos-duration="600"
              >
                <div className="contact-icon">
                  <FaRegEnvelope />
                </div>
                <div className="contact-text">
                  <h4 className="contact-tect-heading">Mail Address</h4>
                  <p className="text-left">
                    contact@unwantedcarremovalsydney.com.au
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="contact-form-section col-lg-6 col-md-6 col-12">
            <ContactForm />
          </div>
        </div>
      </div>
      <Map />
    </>
  );
};

export default Contact;
