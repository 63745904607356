import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { bloglist } from "../assets/data/BlogsData";
import AOS from "aos";
import ReactHtmlParser from "react-html-parser";
import MetaTags from "react-meta-tags";


const Detail = () => {
  const { id } = useParams();
  const [service, setService] = useState(null);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      AOS.init({
        duration: 50,
      });
      AOS.refresh();
    }
    return () => (mounted.current = false);
  }, []);

  useEffect(() => {
    let service = bloglist.find((service) => service.id + ".html" == id);
    if (service) {
      setService(service);
    }
  }, [service]);

  return (
    <>
    <MetaTags>
          <title>[Unwanted car removal] in Sydney|cash for car Sydney</title>
          <meta name="description" content="Auswide Car Removal is a car removal service provider in Sydney, offering you $30000 the best price on your junk cars. Established since 2014." />
          <meta
            name="keywords"
            content="cash for car Sydney, junk car removals sydney, damaged car removal sydney,unwanted car removals sydney, sell your car for cash sydney, scrap car removal sydney, sydney car removal, buy junk cars sydney, cash for junk cars in sydney, cash for scrap cars in sydney, unwanted car removals in sydney, cash for cars in sydney"
          />
        </MetaTags>
      {service ? (
        <>
          <div
            className="servicepage_background-banner-1 py-5"
            data-aos="fade-up"
            data-aos-duration="100"
          >
            <div className="container py-5">
              <h3 className="text-left text-light">{service.name}</h3>
              <p className="text-left text-light wid-50">
                you want to sell your junk vehicle, you are in the right place.
                We buy all sorts of junk vehicles and give you a very good
                quote. We Buying Junk Cars, SUV, Trucks, Vans, and more
              </p>
            </div>
          </div>
          <section className="servicepage2_wrapper">
            <div className="container">
              <div className="row py-5">
                <div className="col-lg-6 col-md-6 col-12 content-div">
                  <div>{ReactHtmlParser(service.content)}</div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 content-div">
                  <div className="servicepage-img">
                    <img src={service.image01} alt="" className="img-fluid" loading="lazy"/>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-12">
                  <p>{ReactHtmlParser(service.someData)}</p>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        "Blog Detail Page no Content"
      )}
    </>
  );
};

export default Detail;
