import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { animateScroll } from "react-scroll";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import AOS from "aos";

const Footer = () => {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      AOS.init({
        duration: 50,
      });
      AOS.refresh();
    }
    return () => (mounted.current = false);
  }, []);


  
  return (
    <>
      <section className="footer" data-aos="zoom-in" data-aos-duration="700">
        <footer className="footer-top">
          <div className="container">
            <div className="row">
              <div className="footer-col col-lg-4 col-md-6 col-sm-6 col-12 sm-mb-3">
                <h3 className="main-heading">AUSWIDE CAR REMOVALS</h3>
                <p>
                  Auswide Car Removal is a car removal service provider in
                  Sydney, offering you the best price on your junk cars.
                  Established since 2014, we buy all types, makes, models, and
                  years of junk cars.
                </p>
                <div className="social-links mb-4">
                  <a
                    href="https://www.facebook.com/UnwantedCarRemovalinsydneyAustralia"
                    target="_blank"
                  >
                    <FaFacebookF />
                  </a>
                  <a
                    href="https://www.instagram.com/unwantedcarremovalsydney/"
                    target="_blank"
                  >
                    <FaInstagram />
                  </a>
                  <a href="https://www.linkedin.com/company/unwanted-car-removals-in-sydney">
                    <FaLinkedin />
                  </a>
                  <a href="https://twitter.com/unwantedcarremo">
                    <FaTwitter />
                  </a>
                </div>
              </div>
              <div className="footer-col col-lg-2 col-md-6 col-sm-6 col-12">
                <h4>Services</h4>
                <ul>
                  <li>
                    <NavLink to="/scrap-car.html">Scrap car removals</NavLink>
                  </li>
                  <li>
                    <NavLink to="/buying-junk-cars.html">buy junk cars</NavLink>
                  </li>
                  <li>
                    <NavLink to="/car-removal.html">
                      unwanted car removal
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/old-car-removal.html">
                      old car removals
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/accident-car-removal.html">
                      accident car removals
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/car-wreckers-in-sydney.html">
                      car wreckers
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/cash-for-junk-cars-in-sydney.html">
                      cash for junk cars
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="footer-col col-lg-2 col-md-6 col-sm-6 col-12">
                <h4>Locations</h4>
                <ul>
                  <li>
                    <a href="/liverpool-nsw-sydney-australia.html">
                      Liverpool NSW
                    </a>
                  </li>
                  <li>
                    <a href="/newtown-nsw-sydney-australia.html">Newton NSW</a>
                  </li>
                  <li>
                    <a href="/rockdale-nsw-sydney-australia.html">
                      Rockdale NSW
                    </a>
                  </li>
                  <li>
                    <a href="/milperra-nsw-sydney-australia.html">
                      Milperra NSW
                    </a>
                  </li>
                  <li>
                    <a href="/cash-car-removals-lalor-park-nsw.html">
                      Lalor Park NSW
                    </a>
                  </li>
                  <li>
                    <a href="/bankstown-nsw-sydney-australia.html">
                      Bankstown NSW
                    </a>
                  </li>
                  <li>
                    <a href="/smithfield-nsw-sydney-australia.html">
                      Smithfield NSW
                    </a>
                  </li>
                  <li>
                    <a href="/campbelltown-nsw-sydney-australia.html">
                      Campbelltown NSW
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-col col-lg-4 col-md-6 col-sm-6 col-12">
                <h4>Contact Us</h4>
                <div className="contact-box mt-30">
                  <div className="contact-details">
                    <p className="need-help-text">
                      9/13 Cooraban rd Milperra Nsw 2214 Sydney Australia
                    </p>
                  </div>
                </div>
                <div className="contact-box mt-30">
                  <div className="contact-details">
                    <p className="need-help-text">
                      19 Venn Avenue Lalor Park NSW NSW 2147 Australia
                    </p>
                  </div>
                </div>

                <div className="contact-box mt-30">
                  {/* <div className="contact-icon">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </div> */}
                  <div className="contact-details">
                    <a
                      href="mailto:contact@unwantedcarremovalsydney.com.au"
                      title="Toll Free Number"
                    >
                      contact@unwantedcarremovalsydney.com.au
                    </a>
                  </div>
                </div>
                <div className="contact-box mt-30">
                  <div className="contact-details">
                    <a href="tel:61402700111" title="Toll Free Number">
                      +61402700111
                    </a>
                  </div>
                </div>
                <div className="contact-box mt-30">
                  <div className="contact-details">
                    <NavLink to="/privacy-policy.html" exact>Our Privacy Policy</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="text-center">
                  © 2022 AUSWIDE CAR REMOVALS. All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
