export const faqs = [
    {
        id: 1,
        ques: 'What kind of Junk car do you buy ?',
        ans: 'We buy all types of junk cars. Most of our clients sell us their junk cars that are beyond quality repair. We also buy cars that are unregistered. But if you are selling the car with title then first you need to cancel the registration by submitting a notice of disposal form.'
    },
    {
        id: 2,
        ques: 'How much do you pay for scrapping a car ?',
        ans: 'We pay up to $30,000 depending upon your car make, model and year.'
    },
    {
        id: 3,
        ques: 'What are your working hours ?',
        ans: 'We are available 7 days a week 24*7.'
    },
    {
        id: 4,
        ques: 'Is it worth scrapping a car ?',
        ans: 'Yes, it is worth getting rid of your car. Instead of an old car taking up space on your property, call Junk Car Removal Sydney to remove it for free. We pay instant cash up to $30,000 and remove car in eco friendly manner.'
    },
    {
        id: 5,
        ques: 'What should I remove from my car before I junk it ?',
        ans: 'Remove important belongings from your vehicle before disposing it. If you can, remove your license plates. If you can not remove your license plates, a junk car recycler can do it for you when they pick up your junk car'
    },
    {
        id: 6,
        ques: 'How to transfer ownership of a junk car ?',
        ans: 'You should not think about it. At junk car removal company, it is our responsibility to transfer your car ownership.'
    },
    {
        id: 7,
        ques: 'Do I have to tell my insurance company if I junk my car?',
        ans: 'Yes, you should cancel your junk car insurance. So you don not have to make any payments before the policy expires. But, if you paid for the full coverage in advance, you might be eligible for a refund, so call your provider.'
    },
    {
        id: 8,
        ques: 'What happens to the car after their pickup?',
        ans: 'The metal components of the casing and body boards will be reused and utilized in new items. On the off chance that a couple of parts of your vehicle are in great shape, they will be reestablished and sold. Assuming zero benefits come from your car the car gets destroyed and reused by the scrapyard.'
    }
]