import React from "react";
import BlogItem from "./BlogItem";

const ServiceLists = ({ blogs }) => {

  return (
    <div className="col-lg-9">
      <div className="row mb-4">
        {blogs.map((service) => (
          <BlogItem service={service} /> 
        ))}
      </div>
    </div>
  );
};

export default ServiceLists;
