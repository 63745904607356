import React from "react";
import about1 from "../../assets/images/about/best-towing.svg";
import about2 from "../../assets/images/about/sydney-location.svg";
import about3 from "../../assets/images/about/full-satis.svg";

const AboutJoin = () => {
  return (
    <>
      <section className="about-join">
        <h2 className="join-head">Why we are the best</h2>

        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 join-content">
              <div className="img-div">
                <img src={about1} className="img-fluid" loading="lazy"/>
              </div>
              <h4>Car Picked Up</h4>
             
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 join-content">
              <div className="img-div">
                <img src={about2} className="img-fluid" loading="lazy"/>
              </div>
              <h4>Sydney Location</h4>
             
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 join-content">
              <div className="img-div">
                <img src={about3} className="img-fluid" loading="lazy"/>
              </div>
              <h4>Full Satisfaction</h4>
            </div>
            {/* <div className="col-lg-3 col-md-3 col-sm-6 col-12 join-content-1">
              <AiOutlineFundProjectionScreen className="about-icon" />
              <h4>200</h4>
              <p>
                Projects
              </p>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutJoin;
