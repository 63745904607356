import React from 'react';
import { NavLink } from 'react-router-dom';

const NavLinks = () =>{

     return <ul className="nav-links">
         <li>
             <NavLink to="/" exact>Home</NavLink>
         </li>
         <li>
             <NavLink to="/about.html" exact>About</NavLink>
         </li>
         <li>
             <NavLink to="/services.html" exact>Service</NavLink>
         </li>
         <li>
             <NavLink to="/blogs.html" exact>Blogs</NavLink>
         </li>
         <li>
             <NavLink to="/locations.html" exact>Location</NavLink>
         </li>
         <li>
             <NavLink to="/contact.html" exact>Contact</NavLink>
         </li>
         {/* <li>
             <NavLink to="/privacy-policy.html" exact>Privacy&nbsp;Policy</NavLink>
         </li> */}
     </ul>
}

export default NavLinks;