import imageTwo from '../../assets/images/home/1.png'


export const slides = [
    {
        name: 'Patricia Ventigadoo',
        testimonial: `Thank you very much for your friendly and professional service adam. I got the price I wanted for picking up the car.
        Happy new year . Stay blessed`,
        city: 'Syndey',
        image: imageTwo,
        alt: 'Imageone',
    },
    {
        name: 'Diyanshi Ecommerce',
        testimonial: 'Best car removals company  in sydney good price',
        city: 'Lalor Park',
        image: imageTwo,
        alt: 'ImageTwo',
    },
    {
        name: 'Rahul Singh',
        testimonial: 'Good price for car',
        city: 'SOuth Cooggee',
        image: imageTwo,
        alt: 'Imagethree',
    },
    {
        name: 'Arun Rawat',
        testimonial: 'This company offer best deals in old cars. I recommend everyone',
        city: 'Campbelltown',
        image: imageTwo,
        alt: 'Imagethree',
    }
]