import service1 from "../images/services/1.jpg";
import service2 from "../images/services/2.jpeg";
import service3 from "../images/services/3.jpeg";
import service4 from "../images/services/4.jpeg";
import service5 from "../images/services/5.jpeg";
import service6 from "../images/services/6.jpeg";
import service7 from "../images/services/7.jpeg";
import service8 from "../images/services/8.jpeg";
import service9 from "../images/services/9.jpeg";
import service10 from "../images/services/10.jpeg";
import service11 from "../images/services/11.jpeg";
import service12 from "../images/services/12.jpeg";

import location1 from "../images/services/21.jpeg";
import location2 from "../images/services/22.jpeg";
import location3 from "../images/services/23.jpeg";
import location4 from "../images/services/24.jpeg";
import location5 from "../images/services/25.jpeg";
import location6 from "../images/services/26.jpeg";
import location7 from "../images/services/27.jpeg";
import location8 from "../images/services/28.jpeg";
import location9 from "../images/services/29.jpeg";
import location10 from "../images/services/30.jpeg";
import location11 from "../images/services/31.jpeg";
import location12 from "../images/services/32.jpeg";

export const servicelist = [
  {
    id: "buying-junk-cars",
    name: "Buying Junk Cars",
    type: "service",
    image01: service1,
    description:
      "We buy every vehicle of any make and model and pay cash for them. It does not matter if you own an American, European, or Japanese make. We buy all models of vehicles, even if they are not in running condition.",
    tabHeading1: "How it Works",
    tabContent1: `We buy every vehicle of any make and model and pay cash for them. It does not matter if you own an American, European, or Japanese make. We buy all models of vehicles, even if they are not in running condition.`,
    tabHeading2: "Why do people sell Old Car for Cash",
    tabContent2: `<ul>
    <li>You need cash during a crisis.</li>
    <li> Your car is broken/ destroyed/spoiled and is beyond repair.</li>
   <li> You no longer need your old car.</li>
    <li>Your car needs a lot of services.</li>
   <li> Your repairing cost is growing, and the tops the value of your car.</li>
   <li> You want to purchase a new car by selling your old one.</li>
</ul>`,
    tabHeading3: "Facilities",
    tabContent3: `Get quick money by selling your car with us as we give the best market rates by examining your car. Our process is very straight forward and does not take your valuable time.`,
    someData: `<div class="new">
    <h3>We buy junk cars Sydney</h3>
    <p>We all move on in life. Be it with a job or even a car. We cannot just drive one car throughout our lives. At some point, we need to remove our old car and get a new one. At times, you get a new one even if your car is not very old. When it comes to searching for the best cash for junk cars in Sydney, only one name comes to everyone’s mind – Auswide Car Removal Pvt Ltd.

    </p>
    <p>If you have an old car sitting at your home, taking up so a lot of space and collecting rust, think no more. Sell it right away for a good 
    price. You have come to the right place. Auswide Car Removal Pvt Ltd offers the most known old cars removal service with free towing and attractive
    money for junk cars. We are the number one buyer for junk cars Sydney. You do not need to spend a fortune to sell your old junk. We buy all types of
    scrap, used, and abandoned cars. We buy junk cars of all makes and models. Be it cars, trucks, vans, 4WDs, whatever it is; you name it. Sell junk car
    easily with Auswide Car Removal Pvt Ltd. 

    </p>
    <p>Usually disposing of your old car can be a really complex process. Firstly, it is difficult to find the right buyer who is willing to pay the right price for an
    old car. At the same time, the value of your old cars is depreciating daily and using a lot of space in your garage. Also, it becomes very difficult if you purchase
    your new car and then have no space for two cars! Auswide Car Removal Pvt Ltd is here to lighten your burden. There are so many reasons one should sell their old cars 
    to us. Our process is straightforward and easy. Even people who have knowledge about cars can easily sell it. And the best part is, at the end day you get what you 
    deserve – your cash for your old car. You can use this cash and get your new car or just use it the way you like!
    </p>
   
    <p>Auswide Car Removal Pvt Ltd is the best car removal in Sydney because:

    </p>
    <ul>
        <li>We give you the best quote for your old car.

        </li>
        <li>We give you the fastest service. In fact, we take only one day and give you a payment on the spot.

        </li>
        <li>We have no hidden cost. We don’t believe in keeping anything as a surprise and are very transparent. 

        </li>
        <li>We offer the best free quotation for our customers. We don’t charge you for giving a quotation.

        </li>
        <li>We offer world-class customer service. Our team is well trained and the best in Sydney. 

</li>
    </ul>
    <p>With simple three steps, you can junk your car for cash:
</p>
<ul>
<li>Call us on +61402700111 and describe your car details. We then offer you a free quote. </li>
<li>Our team would come and pick up your car from the location you give us.
</li>
<li>Get instant payment. 
</li>
</ul>
    <p>We offer the right price and the most competitive in the market seeing many factors. We don’t care if your car is very old, ugly or not even in a working state. All we want is customer satisfaction. We are proud to help maintain the environment by disposing of the car in the right way. 
    </p>
    <p>Auswide Car Removal Pvt Ltd has a vast experience in old car removal, and thus we handle everything seamlessly. We buy cars for cash. Just in a matter of a day, you will get rid of your old car and get the best and right price of it. End your search for junk cars near me, buy junk cars near me, or junk car removal near me with Auswide Car Removals. 
</p>
    <p>Wait no more! Call us right now at +61402700111 and explain us your car’s make, year, brand and model. Just within a few hours, our reliable and professional team will come to you to remove your old car and pay you in cash. At Auswide Car Removal Pvt Ltd, selling your old cars is so easy. You could also email us on contact@unwantedcarremovalsydney.com.au.
    </p>
</div>`,
  },
  {
    id: "cash-for-junk-cars-in-sydney",
    name: "Cash for junk cars",
    type: "service",
    image01: service2,
    description:
      "It is hard to get the right cash for junk cars in today’s market for your car, let alone the junk car. Selling a junk car is not easy and it may take weeks to find the right buyer or a dealer. Auswide Car Removal Service aims to provide its customers with the easiest way to sell their junk car. In simple steps, you can get rid of your junk car and use the cash in something more useful. It is the right place to sell your junk cars Sydney.",
    tabHeading1: "What is a Junk Car",
    tabContent1: `<div class="card-body">
    <p>Junk cars are those vehicles that are very old and/or damaged enough that selling it for its spare parts is more feasible than spending cash on the repairs of the junk car. The car is so severely damaged, that it is beyond repairs, or its significant part is broken, for instance, motor or tires.
             </p>
             <p>This is when it is wise to sell it to the right dealer like Auswide Car Removal Services to get the right value of your junk car. A lot of people think when the car is junk, it does not fetch them any price and they just sell it off for a very low value. This is not true. A lot of cars have good upgrades or parts which are valuable and can be used with in other cars to make them in a running condition. So, eventually, you should get the price of the spare parts (working) and the metal scrap. Another thing to keep in mind is that a car gives different kinds of metal, where some are pricey and some are not. Depending on the brand, you may get a good value of the scrap too. And if your car metal is not rusted, the value is even better. The overall price is good token money to use it perhaps in your next brand-new car.</p>
</div>`,
    tabHeading2: "How Does it Work ?",
    tabContent2: `<div class="card-body">In four simple steps, you can sell your junk car to us.
    <ul>
        <li>Submit your junk car information (like make and model) with us over the phone or email. Get a free quote from us.

</li>
        <li> Once you are happy with the offer, we inform you of all the documents we would need to verify your ownership.

</li>
       <li> We arrange a free tow up for your junk vehicle, or you could simply drop off your vehicle to our store, whatever suits you the best.</li>
        <li>Get cash from us instantly and bid goodbye to your junk car.

</li>
     
    </ul>
</div>`,

    someData: `<div class="new">
    <h3>Why Should I Sell My Vehicle to Auswide Car Removal Services?
</h3>
    <ul>
        <li>We offer the best money for junk cars, trucks, SUVs, or Jeeps. You will not find such a competitive price elsewhere. We buy junk cars of all kinds. 

</li>
<li>We take care of the paperwork, and we believe in minimal paperwork.

</li>
<li>Our towing service is completely free and we have no hidden charge.
</li>
<li>We recycle cars in an environmentally friendly way and dispose of it respecting the country’s laws and regulations.

</li>
<li>We offer great customer service and our tow trucks are fully equipped with the latest technologies. 

</li>
<li>We buy junk cars models and makes of cars of all kinds, and no matter if your car is burned, not operating, wrecked or is not even a running model, we accept and pay its value accordingly.
</li>
<li>We are the best cash for junk cars near me. 
</li>
    </ul>
    <p>So, next time you do not need to search for “sell my junk car near me” or “junk my car near me”. Just log in to our website or give us a quick call. 
</p>

  <h3>Environmentally Friendly Disposal
</h3>
<p> At <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> Services, we ensure we dispose of the car parts which are not working in the right manner. We remove and recycle all the hazardous materials and fluids from the junk car. We remove the good parts and sell them as spare parts, and offer you the right value. The remaining scrap material is scraped off and used to recreate new products. We do our bit by recycling and eliminating the need for production of more parts, thus eventually reducing the wastage of energy, water, and other production wastes. We have recycled a lot of cars over the years, helping the community by reducing the wastage and making our earth a better planet to live in. It is also your responsibility to remove the junk car from your garage. The junk car emits fumes which are hazardous to your health. The car takes unnecessary space in your garage and is an ugly sight for your family. 
</p>
<p>Cash For Junk Cars is a service offered by Auswide Car Removal Service that enables you to easily get cash in exchange for your junk car. The best part about it is that we make it hassle-free because we are experts at providing exceptional service. We are honest, transparent and offer same day free towing service. It’s time to get rid of your junk car for the best cash in the market. Call Auswide Car Removal Service today to junk your car for cash.


</p>
    
</div>`,
  },
  {
    id: "cash-for-scrap-cars-in-sydney",
    name: "Cash for Scrap cars",
    type: "service",
    image01: service3,
    description:
      "When thinking of selling your scrap car removals, the first question that comes in your mind is â€œWhat value or price would I get for my scrap car?â€, or â€œWhat is the best scrap car removal near me. There are a lot of factors that affect the price of your scrap car. There are a lot of steps involved as well, and the most difficult part is to get the right buyer. You may find buyers but not the one that gives you the right price. To put a full stop to all your problems, Auswide Car Removal Service offers you an incredible offer on your scrap car and is the best scrap vehicle removal in Sydney. In just one day, you can permanently get rid of your headache.",
    tabHeading1: "Why Select Auswide Car Removal Service",
    tabContent1: `<div class="card-body">
    <p></p><ul>
        <li>We offer the best car scrap pickup near me with no car towing charges.

</li>
<li>We are the best scrap car removal for cash.

</li>
<li>We finish the entire process in a day rather than taking weeks, with minimal paperwork.

</li>
<li>We are conveniently located and offer our services to all the towns in Australia. 
</li>
<li>We do not have any hidden charges and offer a free quote. 
</li>
    </ul>
             
</div>`,
    someData: `<div class="new">
          
    <p>There are a lot of factors that affect the price of your scrap car. There are a lot of steps involved as well, and the most difficult part is to get the right buyer. You may find buyers but not the one that gives you the right price. To put a full stop to all your problems, Auswide Car Removal Service offers you an incredible offer on your scrap car and is the best scrap vehicle removal in Sydney. In just one day, you can permanently get rid of your headache. 

    </p>
    <h3>What factors determine your scrap car value?
</h3>
    <p>Although we use a lot of algorithms, there are certain important aspects that determine the value of your scrap car. Here they are:
</p>

    <ul>
        <li>The condition of your car: If the car is not running, or in a working condition, then the value you get is only the scrap metal. Now that depends on the metal rate going on in the market. Further, the make and model also make a huge impact on the pricing of the scrap car. It is obvious that Toyota will give you a lesser value than a Mercedes. If the car is in running condition, we reuse the spare parts of your scrap car and thus, we give you a better price for it. No matter, if it is working on not, we do accept your scrap car and also, we offer a free tow service, unlike many other providers in Australia. 
        </li>
        <li>Is your scrap car rusted: If your scrap car is totally rusted, it gives a much lesser value than what it would otherwise? The quality of the metal is lower and it gets complex to create good quality crude metal through processing, making it harder for dealers to resell the metal parts to recycle plants. Eventually, you get a lower value of your scrap car.

        </li>
        <li>Type of engine: Typically, engines are defaced by scrap dealers and not sold again. The older your engine, the more emissions it would release. You can say an older engine emits ten times more emissions than a newer one. So, if your engine is very old, it would surely give you a lower price. 

        </li>
        <li>Scrap Metal market value: The markets keep changing and so does the scrap metal price. A lot of metal is extracted from your scrap car, and depending on each metal, the prices vary. Some metal gives you a higher value as it is already closer to the recycle product. 

        </li>
        <li>Other parts: Dealers take into consideration the other parts as well when you sell your scrap car, like tyres, seat cover, etc. For instance, the rubber that comes out of your tyres, can be recycled to use for other materials as well.


</li>
    </ul>
    <h3>How do I get Cash For Scrap Car?
</h3>
    <p>
The process is very simple as Auswide Car Removal Services. All you need to do is follow these three simple steps. 


    </p>
    <ul>
        <li>Connect Auswide Car Removal Service for scrap car removal for cash through email, or phone. Our customer representative would be more than happy to assist you. We will guide you and give you a free quote after you describe your car details, like make and model, etc.

</li>
<li>If you are happy with the quote, we will ask you to keep a few documents to verify your car purchase. We then come to your location on your given time and date. 

</li>
<li>We tow your vehicle for free and give you cash instantly.
</li>
    </ul>
    <h3>What kind of scrap car do we accept?
</h3>
    <p>At Auswide Car Removal Service, we accept cards of all kinds and makes. We donâ€™t care if your car is running, not running, burned, damaged beyond repair, very old, wrecked, or even if the model is no longer available in the market. We accept all kinds of cars, jeeps, SUVs, trucks, and vehicles. The price we offer for your car depends on the market value and the condition of your scraped car. With our vast experience and knowledge, we offer you the best deal in Australia and our entire process is very simple. You no longer need to spend weeks to find the right buyer for your scrap vehicle removal near me. 
Connect with Auswide Car Removal Service today for your scrap metal car removal! 

    </p>
</div>`,
  },
  {
    id: "unwanted-car-removal",
    name: "Unwnated Car Removal Sydney",
    type: "service",
    image01: service4,
    description:
      "Unwanted Car removals are needed. One cannot keep a car for long if it is not worth it. Below are some of the reasons given on the necessity of unwanted car removals.",
    tabHeading1: "Getting cash for cars removal in Sydney",
    tabContent1: `<div class="card-body">
    <p>You can easily find us on Google or call us if you want any car removal.
         We not only buy all kinds of vehicles but also provide <b><a href="https://unwantedcarremovalsydney.com.au/car-removal.html">
                car removal</a>
</b> to all 
         our customers in Sydney.
        </p>
</div>`,
    tabHeading2: "Why choose our car removals Sydney Service ?",
    tabContent2: `<div class="card-body">
    We have been part of the automobile field for many decades and therefore have the required experience along with the talent to deal with any car-related situations. We ensure that you get the right direction with a real diagnostic of your vehicle.

    <br>Our staff consists of professionals with immense knowledge in the field of car removals. 
    
    <br>We also offer many value-added services like a free online quote and free paperwork completion to ensure the fast and smooth deal.
    
</div>`,
    tabHeading3: "How our car removal sydney program operates ?",
    tabContent3: `<div class="card-body">
    <ul>
        <li>Share your vehicle details with us and receive an instant quote on your vehicle.
         </li>
        <li>After the physical inspection done by our experts, we make the offer. 
         </li>
        <li>Once you take the offer, we will tow away your vehicle as per your instructions.</li>
        <li>Get instant cash when your car is towed.</li>
        
    </ul>
 </div>`,
    someData: `<div class="new">
    <h3>Unwnated Car Removal Sydney
    </h3>
    <p>An unwanted car is a liability to anyone. One cannot use the car and also selling the damaged car is nearly impossible. Who would anyone buy a damaged car? Welcome to CompanyName, the best company that deals with unwanted car removals without any hassle. We do not care how much your car is damaged. We just get the burden off your head in minutes and offer you the best cash for unwanted cars. Your search for â€œcash for unwanted cars near meâ€ ends on us. 

        </p>
        <p>Finding the best company to get rid of your damaged car usually means a unwanted vehicle removal company that works around your timings. One that doesnâ€™t require you to do a lot of paperwork for the vehicle. One that doesnâ€™t charge you a fee for towing and one that leaves you with a cash payment for your damaged vehicle that is the right price for the car. At CompanyName car removal, we buy unwanted cars, unwanted junk cars, vans, 4x4s, trucks, Utes, jeeps, commercial vehicles, SUVs, buses and bikes for cash and offer just that. We arenâ€™t fussy or selective as to the make or on the condition of the vehicles as we are the most expert â€œAuto Wreckers in Sydneyâ€.
        </p>
       <p>Our car removal services at CompanyName are popular in the entire town. Scheduling a removal is also very simple, but first, a cash quote for the damaged car must be obtained and accepted. We make the cash for damaged cars offers over the telephone, email or by meeting us. Once a vehicle owner likes and accepts our quote, we then schedule an easy free car removal. Our team comes to your location anywhere in Sydney at a time convenient for you. 

        </p>
        <p>Auswide car removal is a fully licensed used and scrap car buyer that is also the best Auto Wrecker in Sydney. Using the latest technology, we dispose of cars in an environment-friendly way. Our Auto Wreckers get the best value out of vehicles by using the principles of â€œGreen Car Recyclingâ€. Using this method, eighty to one hundred per cent of vehicles are recycled, creating the least threat of environmental hazards to the environment. 


        </p>
        <p>We get rid of your damaged cars, no matter what the model and brand. We are the most talented car removal specialists that pay vehicle owners the right cash. We give cash for your damaged vehicle, whether it has been involved in an auto accident or just needs a few repairs to be back on the road in running condition. 

<br>
We buy cars that are severely damaged and get them recycled, keeping the environment green.

        </p>
        <ul>
            <li>Call us and describe your car to us. 
</li>
<li>Our team shall come and examine your car and give you the best quote.
</li>
<li>On accepting the quote, we buy your damaged car and give you the best cash.
</li>
        </ul>
        <p>We also take into account a variety of factors when making you an offer for your damaged vehicle. For example, your location, the salvage value of your car pre-accident, the extent of damage or mechanical and electrical problems, the current market demand for your vehicle and its parts, its make, model, mileage and age all play a role of deciding the value.
</p>
<p>Call us at  +61402700111 today and get rid of your unwanted junk vehicle and sell your unwanted car!
</p>
       
</div>`,
  },
  {
    id: "cars-for-disposal-in-sydney",
    name: "Cars for diposal",
    type: "service",
    image01: service5,
    description:
      "A lot of people wonder how to dispose of their old cars in the right way. Disposal is to get rid of your old car which you no longer use because they are either too old to drive, or not even in a working condition. They take ample space in your garage and are of no use to you or anyone in your home.",
    tabHeading1: "How it Works",
    tabContent1: `<div class="card-body">
    <p><a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> Services helps its customers to dispose of the car easily. We are conveniently located and offer services in the most remote area as well in Australia. What more? You can call us anytime and get your free quote. Our team tows your old car absolutely for free and you don’t have to spend an entire day filling out papers.

             </p>
</div>`,
    tabHeading2: "How do i start disposing of my car?",
    tabContent2: `<div class="card-body">
    We believe in a hassle-free and transparent process. Here are simple steps you would need to follow to dispose of your old car.

   </div>`,

    someData: `<div class="new">
    <h3>Disposing of A Car</h3>
    <p>There are mainly three ways one can dispose of an old vehicle.
    </p>
    <h3>Recycle</h3>
    <p>You could always recycle cars, and this is especially for those who are not in a running condition. If there are some good parts, the company removes it and uses it in a working car with a bad spare part. A lot of oil and other resources are saved when you recycle a car. This also helps you to give your bit to the environment. Parts like wheels, engines, doors, windows, etc., can be reused in other cars. If you opt-in for this method, ensure you chose a dealer who does it in the right way or has the license to do it. 


    </p>
    <h3>Trade</h3>
    <p>Another way to deal with your old, <b style="color: #ff0000;">"damaged car"</b> is to sell it off for the right price. You may not get a big enormous amount, but it would be a decent one and would help you a bit in other important work. It is extremely important to find a right dealer, and here is where <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> Service comes into action. We are the best dealers and give you the right price for your old, scrap car. 

    </p>
    <h3>Donation</h3>
    <p>There are a lot of companies, mainly non-profit, which accept your donated car. Even if your car is not in a working condition, you still can donate it. The company uses the scrap and metal parts and since it is a charity, the end-user surely benefits with this cause. However, you would not get any cash in return. This is for a good cause.

    </p>
  
    <p>No matter what your choice is, you could always speak to our customer representative to know more and understand the entire process. We assure you that you will never regret your decision of selling your car to us.
    </p>
    <h3>Why Should You Select Us?
</h3>
    <p>A majority of the people think that it is best to sell off their old junk piece to a junkyard and make some quick bucks. But this is not a great idea. These junkyards give you just the scrap or metal prices, which are usually very low. Also, they do not value any upgrades or additions you have done on your car as a great music system installed or anything else which is worth the money even today. End of the day, you may get $250, and you also have to pay an additional car towing price to them. So, eventually, you have nothing left in your pockets.
    </p>
    <p>On the other hand, at <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> Service, we offer a great price because we value the car. Our expert team carefully analyses your car and gives the best quote because we have a lot of algorithms based on current market price, value, and other factors. Our quote is given absolutely free. Our car towing service is also free. So, it is a win-win situation for you. All you need to do is, to call us and get a free quote. You can get your cash in hand instantly and the way you prefer.
</p>
<h3>What are the benefits you get using our services?
</h3>
<p>There are a lot of benefits you get when you pick our services:
</p>
<ul>
<li>A transparent, hassle-free, no-nonsense offer, where you can get easy cash in hand in a very short time.
</li>
<li>Free Car Towing Service and a free quote.
</li>
<li>A great customer service that guides you in every step. Our team speaks fluent English and is highly knowledgeable.
</li>
<li>Services available on weekends as well. You can<b style="color: #00ff00;">"dispose"</b> of your car at the time and day you prefer.
</li>
<li>We believe in minimal paperwork.
</li>
<li>We offer services in remote areas as well making it easier for our customer.
</li>
<li>We are always on time and we finish the entire process in no time.
</li>
</ul>
<h3>How do I start disposing of my car with Auswide Car Removal Service?
</h3>
<p>We believe in a hassle-free and transparent process. Here are simple steps you would need to follow to dispose of your old car.
</p>
<ul>
<li>Call us or email us to get your free quote. We may ask you a few questions relating to your car, including the model, make and other relevant details.
</li>
<li>We give you a quote online or if you wish we can come and inspect your car, depending on what is convenient for you.
</li>
<li>We offer an ideal quote and if you are content, we decide the time and location of the car pickup.
</li>
<li>Our team comes and picks your car for free and gives you the cash amount decided for it.
</li>
</ul>
<p>We may require a few documents to ensure that the car you sell is not a stolen one. We inform our customers beforehand to keep the documents ready to save their valuable time. We organize every aspect so that things speed up and in no matter of time you are done selling/disposing of your <b><a href="https://unwantedcarremovalsydney.com.au/cash-for-junk-cars-in-sydney.html">junk old car</a></b>.
</p>
<p><b style="color: #00ff00">"Car Disposal"</b> is now simple. You can find reviews and see why are we popular. You can always store our number in case you need it later. You can also recommend our service to your friends and colleagues and help them. At <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> Service, we assure you will sell your car quickly, and at the best price. You don’t have to wander anywhere else. Just give us a call and find out more today!
</p>
</div>`,
  },
  {
    id: "cash-for-cars-in-sydney",
    name: "Cash for Cars",
    type: "service",
    image01: service6,
    description: `We all love driving good cars. But even the best of all cars one day depreciates and we have to sell it. The first step is we search for cash for cars near me. Old and junk cars get the money based on the scrap that comes out. These days, unfortunately, the metal prices are not that great, giving out a low offer and making it hard to sell or get a good cash for car deal. Things get even difficult when your car is new, but due to an accident, it is now not in a working condition.

    You can now bid a goodbye to your all issues relating to selling your vehicle for the best cash for cars with Auswide Car Removal Service. Auswide Car Removal Service is one name that comes in everyone’s mind because of the best services we offer. We buy cars for cash, or in other words cash cars quickly. We specialize in towing all kinds of cars, trucks, SUVs, Jeeps, etc. and we offer free towing, no matter how remote the location is. We are located in many districts in Australia, making it easy for us and our clients as well. We offer the best price in town, by carefully keeping in mind all the factors like the current market price, the amount of scrap and other relevant details.`,
    tabHeading1: "Why Auswide Car Removal Service ?",
    tabContent1: `<div class="card-body">
    <ul>
        <li>We offer the best price in town and we even pay for cars that are not even in a working condition. We offer the best <b style="color: #ef3f49;">cash for scrap cars</b> service in Australia.  
</li>
        <li>We offer outstanding customer service. Our representatives guide our clients and explain the process.
</li>
        <li>Our service is not only the best but also the quickest in town. In a span of thirty minutes, we reach the location. You can sell your car hassle-free with us.</li>
        <li>We offer our services in all areas and we even offer emergency services if needed.
</li>
        <li>We buy cash 4 cars without much paperwork.
.</li>
    </ul>
  </div>`,
    tabHeading2: "When should you sell your car?",
    tabContent2: `<div class="card-body">
    <p>There are a lot of reasons one can sell his car. It could be because:
</p>
    <ul>
        <li>It is very old and not in working condition.
</li>
        <li>The user wants to buy a new car or is in the need of money.
</li>
        <li>The car is broken or beyond repair due to an accident.
</li>
        <li>No space in the garage for parking.</li>
    </ul>
    <p>Whatever the reason is, <b><a href="http://www.auswidecarremovals.com.au/" target="_blank">Auswide Car Removal Service</a></b> Service is at your service to help you sell your car easily. We just require a few documents, about which we inform you beforehand, so you are prepared. We also don’t care if the car is not working or totally wrecked. The best part is you can get the quote right on the phone, or email and then make a decision to sell it or not.
</p>
</div>`,

    tabHeading3: `Facilitites`,
    tabContent3: `<div class="card-body">
You can now bid a goodbye to your all issues relating to selling your vehicle with Auswide Car Removal Service. <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> Service is one name that comes in everyone’s mind because of the best services we offer. We specialize in towing all kinds of cars, trucks, SUVs, Jeeps, etc. and we offer free towing, no matter how remote the location is. We are located in many districts in Australia, making it easy for us and our clients as well. We offer the best price in town, by carefully keeping in mind all the factors like the current market price, the amount of scrap and other relevant details. 


</div>`,

    someData: `<div class="new">
    <h3>Easy Process</h3>
Selling your car is a very easy and straight forward process at <b><a href="http://www.auswidecarremovals.com.au/" target="_blank">Auswide Car Removal Service</a></b> Service. Here are the simple steps you would need to follow:

<ul>
<li>Connect with us using any options like email, or phone. 
</li>
<li>We offer you the best quote after hearing the description of your car. If you wish, we can personally come to your given location and check the status. Our quote is free. 

</li>
<li>If you are happy with the quote, we come with our team, tow the vehicle, and give you cash instantly. Sell car for cash with <b><a href="http://www.auswidecarremovals.com.au/" target="_blank">Auswide Car Removal Service</a></b> Service instantly. 

</li>
</ul>
    
    <p>In three simple steps, you can sell your car without any hassle. This same process if you go with an agent or yourself, would increase your steps and the paperwork. Also, agents can be a fraud and would cheat you and give you either less money or take a too long time. Also, some of them are not licensed and do not dispose of the <strong style="color: green;">wrecked vehicles</strong> in an environmentally friendly way.


    </p>
    <p>We offer you instant payment, this is very useful if you want to invest in some other car. We offer a complete comprehensive car check and then give you a price. You will know the right value of your car, so you are aware and you can make informed decisions. We don’t believe in huge paperwork. All you need to do is just sign up a few documents, sit back and relax, while we do all the work for you. Further, you don’t have to take a lot of hours to sell your car. In a day’s time, we would help you sell your car and even get the cash payment. We offer services on weekends and weekdays, so you don’t have to manage time. 

    </p>
    <h3>What do we do with your car?
</h3>
    <p>Once we tow your vehicle, we carefully examine your car. If your car is not working but has a lot of good spare parts, then we take them out and resell the parts. This way, the parts can be used in other cars and people can buy it for a valued price. If the car is totally wrecked, we dispose it in an environmentally friendly way. We ensure that no harm is done to any life and we give our bit to nature. We have proper areas to dispose of vehicles and we have a contract with the right authorities. Everything is licensed and done as per the rules of the country.
Using the parts again in other vehicles help to reduce the cost of manufacturing new parts. Also, people can buy them at a lower price. 


    </p>
    <h3>Our Team
</h3>
    <p>Our customer services are bilingual. They know how to handle the situation and guide clients accordingly. They are polite and knowledgeable and highly trained to resolve customer issues quickly.

    </p>
    <p>Our tow truck drivers are licensed and know well how to tow the vehicle safely and quickly. They are trained to tow all kinds of vehicles. They also clean up the mess if the car has met an accident.
<br>Our fleet of tow trucks are well equipped and also use GPS systems so you can track where they are. 

<br>Our company offers the best services and informs you about every step so that you can easily sell your vehicle with the best knowledge. 


</p>
<p>So, if you are looking for sell my car for cash today, connect with <b><a href="http://www.auswidecarremovals.com.au/" target="_blank">Auswide Car Removal Service</a></b> today, and get your free quote in minutes. Selling vehicles have never been so easy before, thanks to Auswide Car Removal Service – the best place where you sell your <strong style="color: #ffa500;">car for cash</strong>. 
</p>
</div>`,
  },
  {
    id: "scrap-car",
    name: "SCRAP CAR",
    type: "service",
    image01: service7,
    description: `Selling an old car for the right price has never been easy. People take weeks or even months to find the right buyer and get the desired amount.`,
    tabHeading1: "No burden of Paperwork",
    tabContent1: `<div class="card-body">
    <p>We give instant cash and with almost no paperwork. Our customers need to sign the papers. 
        We buy all sorts of cars. No matter if your vehicle is working or not. No matter how ugly it looks or if it an ancient model with plenty of defects. Our customers praise us because of our quick service and also because they get easy cash, which is right for their car. 
        
        </p>
</div>`,
    tabHeading2: "What reasons do people need our scraping service",
    tabContent2: `
    <div class="card-body">
    All you need to do is provide us your vehicle details like the year, make, model, mileage, and
    information about damage or missing parts (if any). Once we receive everything, our team
    shall reach the point you want us to come and tow your <b style="color: #ef3f49;">"scrap car"</b>.
        
</div>`,

    tabHeading3: `What is the charge for my Scrap Car in Australia ?`,
    tabContent3: `<div class="card-body">
Get cash for scrap cars of any make or model. If you are looking for a better price and a
hassle-free service,  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD offers the most effective worth in the ___ area. Our pickup times are
usually less than an hour. If youâ€™re not at home even then, we can schedule a pickup time,
and our tow truck driver will offer you cash and receipt on arrival. We are the leading buyer
of scrap cars. We offer a free quote and guide you well throughout the process.
</div>`,

    someData: `<div class="new">
    <h3>Cash for Scrap Car
    </h3>
    <p>Selling a brand-new car is painful at times because people usually do not get the right buyer. But if your car is scrap, then it gets even more complex. Who will buy a scrap, junk, old and not working car for cash? If you are thinking to sell your scrap <b><a href="https://unwantedcarremovalsydney.com.au/cash-for-cars-in-sydney.html">car for cash</a></b> and are thinking how to get rid of it â€“ worry no more! You have come to the right place! With  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD., you can enter your enquiry into our website or simply call us and receive a quote and if youâ€™re happy with the offer, accept it, and we will simply pay you your money and pick the vehicle up to take it out of your hands. Our service is very reliable, cost-effective, and we pride ourselves in giving our customers the best valuation for their vehicle. We are well-known in <b style="color: #00ff00">"Sydney Australia"</b> for providing steady and outstanding services to our clients.
        At  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD., you can easily sell your scrap car for the best price in Sydney Australia in simple and easy steps.
        </p>
        <p>We are the best in the town when buying your scrap car because:
        </p>
        <ul>
            <li>We accept all sort of scrap cars no matter what piece of junk it is.
            </li>
            <li>We have minimal paperwork.
            </li>
            <li>Everything is done in a matter of one day, saving your valuable time.
            </li>
            <li>Our customer representatives are fluent in English and very knowledgeable. You can relax once you contact us. We handle everything while you can relax and think about your next plans.
            </li>
            <li>We have vast experience and our tow truck drivers have full license and permit to tow your <b style="color: #ef3f49;">"scrap car"</b>.
            </li>
        </ul>
        <p>Removing a scrap car is always important. The scrap car not only sits in your garage and eats up all the space which is limited, but it also releases poisonous fumes, which is very harmful. At  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD., we know the right method to dispose of the car in an environmentally friendly way without harming nature. Also, if you have a car which no longer running efficiently, is beginning to fail miserably and is becoming unreliable â€“ then the expense of maintaining to run the car will begin to increase, and you will no longer feel the need to keep running or keeping the car. In the long run, you will be saving money and will have received cash from us allowing you to put it towards a deposit for a newer car.
        </p>
        <p>Here are the simple steps you need to follow to sell your scrap car for cash:
        </p>
        <ul>
            <li>Call us and give us your car details.
            </li>
            <li>Our agent shall come over, examine and give you the best quote for your scrap car.
            </li>
            <li>If you agree, we give you your cash and tow your scrap car for free!
            </li>
        </ul>
</div>`,
  },
  {
    id: "pick-up-my-car-for-cash-near-me",
    name: "pick up my car for cash near me",
    type: "service",
    image01: service12,
    description: `We #buy every vehicle of any make and model and pay cash for them. It does not matter if you own an American, European, or Japanese make. We buy all models of vehicles, even if they are not in running condition.`,
    tabHeading1: "How it works",
    tabContent1: `<div class="card-body">
    <p>Share your #vehicle details with us and receive an instant quote on your vehicle.
            After the physical inspection done by our experts, we make the offer. 
            Once you take the offer, we will scrap away your vehicle as per your instructions.
            Get #instant cash when your car is scraped.
             </p>
</div>`,
    tabHeading2: "Why do people sell Old car for cash",
    tabContent2: `
    <div class="card-body">
                                <ul>
                                    <li>You need cash during a crisis.</li>
                                    <li> Your car is broken/ destroyed/spoiled and is beyond repair.</li>
                                   <li> You no longer need your old car.</li>
                                    <li>Your car needs a lot of services.</li>
                                   <li> Your repairing cost is growing, and the tops the value of your car.</li>
                                   <li> You want to purchase a new car by selling your old one.</li>
                                </ul>
                            </div>`,

    tabHeading3: `Facilities`,
    tabContent3: `<div class="card-body">
Get quick money by selling your car with us as we give the best market rates 
by examining your car. Our process is very straight forward and does not take
 your valuable time. 

</div>`,

    someData: `<div class="new">
    <h3>Which Car Removals Service Should I Contact ?</h3>
    <p>You should look for a company that offers the most attractive deal on cash for junk cars near me for your vehicle. You can easily get a quote over a phone call or through online forms. Make sure that the company is licensed and insured because this job needs expertise. One has to have environmental clearances and must have due certificates for the job.
        </p>
        <p>
        Please don’t think that the old cars are a burden. If you are thinking about how to dispose of your old, used and wrecked vehicle, do it in a manner that gives you hard cash instantly. There are several car wreckers and Car Removals services that do it in Australia – such as Auswide Car Removals PTY LTD. One might be wondering as to how or why would anyone be willing to pay you cash for the car which is either old or damaged. But it is worth knowing more about this business! 
        
    </p>
    <h3>How Does the junk car pick up services Work in Australia? </h3>
    <p>There is a booming trade in waste material and the recycling process. The car that is sold has value in terms of the metal that will be extracted and the spare parts which are sold off. The right company will give you cash for junk cars.</p>
    <p>
        They are not worried about the condition of the car. They will pick up junk cars for cash from you but the prices might vary depending on several factors such as the make, age, design, model, and extent of the damage. So, the vehicle could have hit another car or against a wall. It can be damaged completely or partially in such accidents. The gearbox or engine may be missing, the seats may be ripped off, or the car can be damaged, gutted, or flooded in a natural calamity. For instance, Auswide Car Removals PTY LTD. buy broken cars near me that are unwanted, scrap, and even water damage regularly, paying cash for cars near me for them. 
        So, if you have a private car that you want to sell off, or a fleet of utes vans, trucks, in your business organization, contact Auswide Car Removals PTY LTD.  in Sydney NSW today. We work all year round to pick up my car for cash near me and come to your place to tow away the vehicle for free – anytime, day or night! All you require to have is the title of ownership/registration documents and a photo ID for the relevant legal paperwork. You only have to put a few signatures and we’ll give you the cash and take away the car.                 
    </p>
    <h3>Eco-friendly car disposal by Auswide Car Removals PTY LTD</h3>
    <p>Going green is a trend that we all love to practice and if it pays top cash, that’s even better. Eco-friendly standards are here to stay and have made such a significant impact on eco-conscious minds that anything that can be recycled is recycled. Recycling includes cars. A move that every car owner should make when the car is old or in scrap condition as it will save the environment of hundreds of years of toxins seeping into our environment if it is disposed of in a landfill. Today, landfills are out with old and scrap cars &amp; trucks. If a scrap or old car owner cannot consider the cost to the environment if their cars are disposed of in a landfill; then, they can consider the cash that they will get into their hands if they dispose of it in an environmentally sound manner.</p>
    <p> Recycling cars is eco-friendly for the environment; and a way to dispose of a car or truck that puts cash into the hands of the owner. Auswide Car Removals PTY LTD Recycles scrap and old cars to Provide cash for junk cars to the owners. This is what makes us liked by most of the people across Australia. So the next time you are searching for something to do with your car, just give us a call. We will just come across to your end and get you the best deal for your scrap car.</p>
</div>`,
  },
  {
    id: "car-removal",
    name: "Damaged Car Removal",
    type: "service",
    image01: service8,
    description: `We buy every vehicle of any make and model and pay cash for them. It does not matter if you own an American, European, or Japanese make. We buy all models of vehicles, even if they are not in running condition.`,
    tabHeading1: "How it works",
    tabContent1: `<div class="card-body">
    <p>Share your vehicle details with us and receive an instant quote on your vehicle.
            After the physical inspection done by our experts, we make the offer. 
            Once you take the offer, we will scrap away your vehicle as per your instructions.
            Get instant cash when your car is scraped.
             </p>
</div>`,
    tabHeading2: "Why should you choose auswide car removal",
    tabContent2: `
    <div class="card-body">
    <ul>
        <li>Hassle-free service
</li>
        <li>Quick service
</li>
       <li> No fee for towing your vehicle
</li>
        <li>Our expert drivers are fully licensed
</li>
       <li> We finish the entire job in a few hours
</li>
       <li>Minimal paperwork
</li>
    </ul>
</div>`,

    tabHeading3: `Facilities`,
    tabContent3: `<div class="card-body">
Get quick #money by selling your car with us as we give the best market rates 
by examining your car. Our process is very straight forward and does not take
 your valuable time. 

</div>`,

    someData: `<div class="new">
    <h3>#Damaged Car Removal</h3>
    <p>When you think of Car Removals, the first name that comes in everyone’s mind is <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD.! We are well-known all over Australia for our exceptional Car Removals service. No matter if it is for old car removals, accidental <b style="color: #ef3f49;">"car removals"</b> or just a damaged car removal. We are number one in this service with our vast experience. Get cash for damaged cars; your damaged car could be worth up to a thousand dollars. So instead of keeping it in the garage, you may sell and get that cash which can be used in buying other valuables or even a new car. We add up all the valuable factors of your damaged car to make you their best or highest quote.

    </p>
    <p>We will buy your damaged car in any condition, whether it’s running or non-running. Even if your vehicle failed your state’s smog emission test, we would buy it from you for cash. The best part is - we even offer free towing, and you do not need to come to us. We’ll come to you! 

    </p>
    <h3>We are environmentally friendly!
</h3>
    <p>We deal with a high volume of car towing orders for the unwanted, accident, junk, rusted and old card in Sydney. It is not necessary that what we remove has to be a junk piece. A lot of times, people remove a car because they never found a buyer or because they do not have time to go through all the process. Whatever the case, we responsibly dispose of all the cars to protect our environment. We have a dedicated team and possibly the best one in <b style="color: #00ff00;">"Australia"</b> who knows very well to dispose of the car in the right way. They begin by stripping the vehicle of all its scrap parts and components until it turns out to be a bare shell. Next, they crush and press the vehicle, making scrap metal from its mainframe. The scrap metal of the vehicle and the components are then recycled, reused, and resold, thus giving you the best value for your car! 

    </p>
    <h3>What types of makes and models do we accept?
</h3>
    <p>The good news is we accept all sorts of makes and models, even the one which is no longer in the market. For us, what matters is you getting your right cash and quick, hassle-free service. No matter If your car is not running, ugly, unused, used, broken, damaged, we take it and give you the right price as per the markets. We also give a free towing service.  
    </p>
    <h3>How does the process work?
</h3>
<p>All you need to do is call our team, and we shall analyse your car and give you the right quote. You can sit, relax and sip your coffee while we do all the paperwork for you and you can then just sign and get free of your damaged car. No matter what location you want us to pick your car from, we will do it with utmost care and concern.
Our expert drivers are fully licensed. They know very well how to tow your car. We know even if it is a damaged car, there is a proper way to pick up the car. Additionally, we also share the GPS, so you can know where our drivers reached. Things are all easy as far as you book <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD. for your <b style="color: #ff0000;">"car removals"</b>.
</p>
<h3>Sell A Broken Or Damaged Car
</h3>
<p>There are different reasons which can cause a car to be called as <b><a href="https://unwantedcarremovalsydney.com.au/accident-car-removal.html">damaged or broken</a></b>. The causes fall into one of the following three categories – engine or parts damage, bodywork damage, or interior damages. It is usually a part which is damaged, and not the whole of the car, it means that the car can often be sold as salvage and the car parts will be used to help repair other vehicles.<br>

For instance, if you had two of the same cars in terms of year, make, and model but one of the car had a damaged engine or some part and was a non-starter, and one of the cars had a smash-damaged shell, a car specialist would be able to use parts of each car to create one proper working vehicle. But no one has one make or the capability of fixing damaged cars.
<br>
Also, it can be time-consuming and expensive. People don’t have the needed talent to be able to do so themselves, which is the reason why vehicles that require a certain level of work. To save one from all these complex things, people prefer selling their car. Also, sometimes even repairing does not help, and the car keeps getting the same issues. The end result – you tend to spend a lot more on the car, and you do not feel safe driving it because you will have a fear that it shall break down in the middle of the journey. 
<br>
Driving a damaged vehicle is also dangerous because if example the breaks are damaged, and you are on a high speed, you will meet up an accident which can cost you your life and life of your loved ones! Better than facing all the consequences is to go for car removal from a quick and professional operator. That is when <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD. comes into action. 

</p>
<h3>Does it take a long time to finish the process?
</h3>
<p>Once you have made your mind, you can simply call us +61402700111, and we shall give you the best quote, something you would not find in the entire Sydney or even Australia. Further, if you have any questions or are not ready but need guidance, you can still call our customer service and understand how the entire process works. On contentment, you can then decide if you want to remove your damaged car or not. We are sure you would, and you will never regret your decision because we are known internationally for our incredible services. 

What are you waiting for? Give us a call now! 
</p>
  
</div>`,
  },
  {
    id: "old-car-removal",
    name: "Old Car Removal",
    type: "service",
    image01: service9,
    description: `If you are thinking to sell your #old car or remove it, only one place will give you the best cash for your vehicle â€“ Auswide Car Removal PTY LTD. We are known for providing not only the best price but also provide the quickest and hassle-free service.`,
    tabHeading1: "Why should you sell your old car",
    tabContent1: `<div class="card-body">
    <ul>
        <li>It is ancient, and you wish to buy a new one
.</li>
        <li> It is not working properly and giving you a maintenance cost every month
.</li>
       <li> The parts of the car are not easily available
</li>
        <li>You do not have space for your old car parking after getting a new one
.</li>
       
    </ul>
</div>`,
    tabHeading2: "What is the procedure to sell the old car ?",
    tabContent2: `
    <div class="card-body">
                                <ul>
                                    <li>Call our +0410 300 150, and our customer service representative shall guide you. 
</li>
                                    <li>We give you the quote which we assure you is the best in the market.</li>
                                   <li> We come and pick your car from the location you want us to and give you instant cash.</li>
                                </ul>
                            </div>`,

    tabHeading3: `Facilities`,
    tabContent3: `<div class="card-body">
Get quick money by selling your car with us as we give the best market rates 
by examining your car. Our process is very straight forward and does not take
 your valuable time. 

</div>`,

    someData: `<div class="new">
    <h3>What kind of car do we buy?
</h3>
    <p>We understand you may be hesitant to contact us, especially if your car is a very old model. But the good news is, we accept all cars, all makes, all models and all conditions. Even if your car is not working properly, or the model is discontinued, you do not have to worry. We still accept it and provide you with the best quote in <b style="color: 00ff00;">"Sydney, Australia"</b>. 

    </p>
    <h3>Are we reliable?
</h3>
    <p>Yes! Absolutely. With a massive experience in this field, we are proudly one of the top leading service providers. Our drivers are experienced with a full license, and we have a fleet of equipped vehicle that can easily tow any sort of vehicle without damaging it. We also have GPS installed so you can know where our tow truck has reached. Further, our customer service is polite and knowledgeable and can answer your queries patiently. We know selling an old car has a lot of queries and you can find all the answers here. Additionally, we provide emergency services for car towing if your car breaks down in the middle of the night. All you need to do is save our number and call us when in need. In a matter of minutes, our team would be there at the spot to help you.



    </p>
    <h3>How do we contribute to nature?
</h3>
    <p>We contribute our share to nature by disposing of the <b style="color: #ef3f49;">"wrecked and damaged cars"</b> in an environmentally friendly way. We have a special team which handles this in such a way that it does not affect nature in a harmful way. We care for our country and the health of our people.

    </p>
<h3>What sets us apart for other providers?
</h3>
    <ul>
        <li>Our services are super quick. If you call us, we would be there in a matter of minutes. We value time and understand your time is valuable.

        </li>
        <li>Our services are transparent. Unlike other providers, we do not have any hidden cost. We give you a clear price which is also pocket-friendly, so in the end, there are no unpleasant surprises for you.
        </li>
        <li>Our services are hassle-free. We do not expect you to fill forms or take multiple rounds to our office. All you need to do is give us a quick call, and things can happen in a dayâ€™s time without any hassle. 
        </li>
        <li>Our services are round the clock and even on weekends. We understand our customersâ€™ needs and are super flexible for them. 
        </li>
        <li>We give the best price in the industry which no one can beat. 

</li>
    </ul>
    <h3>Benefits of Old Car Removal
</h3>
    <p>If you own a car that is no longer in use, you may want to consider the benefits of old car removal by a professional company like  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a>PTY LTD.

    </p>
   <ul>
       <li>Others Can Save on Used Parts:</li>
       <p>By old car removal, you are indirectly helping other people get scrap parts off your car so they can use in their cars. This helps them save money on parts when compared to buying new car parts. Parts like fenders, side panels, hoods, doors and engine parts are just some examples of the scrap parts that other drivers may need. And if your car is in a working condition, people may benefit by buying your car from us.</p>
       <li>Helps Prevent Environmental Pollution</li>
       <p>You keep the old car from causing environmental pollution. The fumes these cars releases are harmful, especially when it is deteriorating and rusting in your garage. In addition, you prevent the car and its oil and fluids, tires and other elements from being in a landfill.</p>
       <li>Removes Potential Hazards and Eyesores from Your Property</li>
       <p> Hiring professionals like  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD. to tow off your old vehicle removes an eyesore and potential hazard from your property. Often children can get injured by playing near your old junk. The fluids leak from your car can be a potential threat to them as well as it would contaminate the soil and groundwater.</p>
       <li>Increases the Value of Your Property</li>
       <p>Cleaning up your property by removing an old car will maintain or even increase your property value. The entire view is better to look at.</p>
       <li>Prevents Possible Parking Fines or Legal Issues</li>
       <p>At times, a few local authorities or neighbourhoods you live in, may have certain regulations about parking an unusable car on your property, especially if it is very old and rusted. By having it towed away, you might avoid legal issues or fines. And in case you are paying a parking fee for that old junk, old car removal will help you relieve your burden.
</p>
<li>Fetches You Extra Cash</li>
<p> Above all the other benefits, old car removal helps you gain some extra cash. It would easily fetch a few hundred dollars. You could always use this for some better investments or might as well buy a brand-new car.
</p>
   </ul>
</div>`,
  },
  {
    id: "car-wreckers-in-sydney",
    name: "Car Wreckers in Sydney",
    type: "service",
    image01: service10,
    description: `Are you looking for car #wreckers? You have come to the right place. At Auswide Car Removal Service, we help you provide car wrecking services in Sydney, and most of the other towns in Australia as well. We offer the best rate in town and are reliable.`,
    tabHeading1: "Why Auswide Car Removal ?",
    tabContent1: `<div class="card-body">
    <ul>
        <li>   We give the best price in town.
</li>
<li>   Our towing service is FREE of cost. 
</li>
<li> We dispose of the car in the most environmentally friendly manner. 
</li>
<li>We use the latest tow truck, equipped with GPS systems.
</li>
<li>Our drivers are fully licensed and are experts in their roles. </li>
<li>We offer quick, reliable and hassle-free service.
</li>
<li>We offer a FREE quote to our customers. 
</li>
<li> We are transparent and do not believe in any hidden fee. 

</li>
    </ul>
 </div>`,
    tabHeading2: "How do i start the process at auswide car removal service ?",
    tabContent2: `
    <div class="card-body">
                                In three simple steps, you can sell off your wrecked car to us:
                                <ul>
                                    <li>Call or email us and describe your car. 
</li>
                                    <li> We offer a FREE quote keeping in mind the state, value, model and current prices in the market. We assure you will not get a better price anywhere else. </li>
                                   <li> Our team comes and tows your car and gives you cash for a wrecked car. 
</li>
                                </ul>
                            </div>`,

    someData: `<div class="new">
    <h3>Car Wreckers</h3>
    <p>Car Wrecking does not necessarily mean smashing or wrecking the car altogether. It means taking out the reusable or good parts of a car and using them in another car to make it in a working condition. The part that cannot be used anywhere else is turned into scrap and sold at the price depending on the metal value. 

    </p>
    <p>When <b style="color: #ff0000">"wrecking a car"</b>, there are a lot of parts that can be reused like windows, doors, tail lights, tires, windshields and so on. Removing these parts and using them in another car helps people because the used spare parts are usually of a lower cost. Also, just by using these parts, a non-working car is pretty much in great condition, saving a lot of money. 

    </p>
    <p>At Auswide Car Removal, if your car is wrecked in an accident, we help you clean the mess which is lying on the road. We clean up the oil, glass, or any hazardous things from the road and ensure no one is harmed in the process. We help you tow your car to the location you wish us to. You will find a lot of services under one roof at Auswide Car Removals. 

    </p>
    <h3>Why is there a need to remove a wrecked car?
</h3>
    <!--<p><a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD. is the best car removal in Sydney Australia because:-->
    <!--</p>-->
   
    <p>If you own a #wrecked car, the best decision you shall make is to remove it using Auswide Car Removals. 
A wrecked car releases poisonous fumes that are hazardous to your and your family. It takes ample of space in your garage and is an eyesore. It also releases some oil and would rust over years causing more damage to the environment. 

    </p>
    <p>It is always advisable to remove the car and get some cash for it. You don’t have to look further. Just give us a call and we would take care of everything after that.
    </p>
    <h3>Did You Know?
</h3><p>Not all car removals offer free car towing services. We are one of the leading car removal services you can trust and use. If you are not satisfied, you can always call us up and know more details. 
</p>
<h3>Can I instead call a mechanic?
</h3>
<p>We do realize some people are really attached to their cars. If your car met an accident, where the damage is not so big, it is good to call up a mechanic and fix the issue. 

</p>
<p>On the contrary, if the damage is beyond repairs, we advise you to sell your car for cash. Typically, you will spend a lot more by re-repairing it every time. This is also dangerous as you will be risking your life by driving a damaged car. 

</p>
<h3>Do I get a good price for my wrecked car? 
</h3>
<p>The cash you receive for your wrecked car depends on the type of car, the condition, make, model and a lot of other algorithms. If your car has good parts which can we used easily for other cars, you will get a good price. But if your car is beyond repair and consists of damaged parts too, then the entire car is sold as scrap. In any case, you could always receive a FREE quote from us before making a decision.
</p>
<p>Auswide Car Removal Services specializes in a lot of services. Save our number and share it with your friends and colleagues. We offer FREE towing, fast service, and exceptional customer service. Your hunt for the right company ends here. Call us today! 


</p>
</div>`,
  },
  {
    id: "accident-car-removal",
    name: "Accident Car Removal",
    type: "service",
    image01: service11,
    description: `Accidents are the biggest tragedy, and it can happen with anyone of us. Many times, the cars get so much damaged due to the accident that you are left with no choice but to remove it. It is a headache to look out for a buyer of a damaged car because the true fact is that no one will buy a damaged piece of junk.`,
    tabHeading1: "Why Select Us ?",
    tabContent1: `<div class="card-body">
    <ul>
 <li>We come to the point where the accident has occurred in a matter of minutes
 </li>
 <li>We remove all the wreckage that has caused due to the accident
 </li>
 <li>We tow your damaged car
 </li>
 <li>We pay you the right price
 </li>
 <li>We have minimal paperwork
 </li>
 <li>We offer a quick and hassle-free service
 </li>
</ul>
 </div>`,
    tabHeading2: "How does the process work ?",
    tabContent2: `
    <div class="card-body">
    We have made the process of selling your accident vehicle fast and in just three simple and easy steps.
    <ul>
        <li>You can get a quote easily over the phone. Simply dial up our number +61402700111.
</li>
        <li>We come up with the right price for you in no time.</li>
       <li>Our removal team will pay the amount quoted during the pickup and relieve your stress in a matter of hours.</li>
    </ul>
</div>`,
    tabHeading3: "Why we Best ?",
    tabContent3: `<div class="card-body">
We give the instant cash #quotation depending on your car make and model. Even if your car is totally smashed, you do not need to worry. We will still pay you the amount after seeing the make and model. <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD service is absolutely free, and you will not be charged a dime for the removal.
</div>`,

    someData: `<div class="new">
 
    <p>Accidents are the biggest tragedy, and it can happen with anyone of us. Many times, the cars get so much damaged due to the accident that you are left with no choice but to remove it. It is a headache to look out for a buyer of a damaged car because the true fact is that no one will buy a #damaged piece of junk.


    </p>
    <h3>We Accept All Vehicles.
</h3>
    <p>We usually buy cars of all makes and model. Truck, SUV, Van, Ute, or anything under the sun, you name it, and we take it. At <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD, one of our best features is that with almost no paperwork, you receive the right price. We understand that during accidents, you are already stressed, and we do not wish to increase more stress by additional paperwork. For us, our customers comfort is the most important thing. Besides, you get the right money when you remove your car with us.
    </p>
    <h3>How do we contribute to nature?
</h3>
    <p>We contribute our share to nature by disposing of the wrecked and damaged cars in an environmentally friendly way. We have a special team which handles this in such a way that it does not affect nature in a harmful way. We care for our country and the health of our people.

    </p>
<h3>Do You Think Your Accident Damaged Car Is Useless?
</h3>
<p>There can be any type of accidents. In some, there is just a minor scratch, while some totally damage the car to an unusable condition. In some cases, the car looks okay but keeps giving a lot of maintenance issues. Accidents could also mean the car is damaged in floods, or due to fire or other foreseen conditions. It could be due to human or due to environmental disasters. 
Whatever the reason is, the car is pretty much useless to you if the damage is beyond repair. In such cases, we recommend you to sell your old, wrecked and <b style="color: #ff0000;">"damaged car"</b> to us and get a new one instead. It is not feasible to spend a lot of dollars on maintenance, especially if the accidental car is not new. This would lessen your regular headaches. Also, you get a good price for it with which you can use to buy a new car.
</p>
<h3>What factors should you be looking when you search for accident car removal?</h3>
<p>If you or your loved one met with an unfortunate accident, and are looking for the best accident car removal service in <b style="color: #00ff00;">"Australia"</b>, here are the factors you would need to consider before zeroing into one service:
</p>
    <ul>
        <li>Fast and quick service: Many operators promise but never come on time. The time goes in waiting, and your work is delayed, and it causes a lot of frustration. It is more painful if the accident happens in the late or early hours of the day.
        </li>
        <li>Operates in your area: The service should be working in your area, and it should even provide emergency service in the late and wee hours of the day. Such services are needed round the clock as tragedies can happen at any point in time.
        </li>
        <li>Hassle-free: The operator should not give you more headache by asking you to take many rounds to banks or insurance. It should have minimal paperwork and relieve you from the headache as soon as possible.
        </li>
        <li>Right cash: The operator, most importantly, should give you the right market price.
        </li>
        <li>Clear the wreck: The operator should clear the wreck caused by accident and also tow your damaged, accidental car at no additional cost. With a stressed state of mind, it is very difficult to focus on cleaning or other such issues.
</li>
    </ul>
<p>All you need to do is call us on +61402700111 and speak to our customer agent. You can in no time see our team at the accident spot. If you want to do this later, perhaps a day after your accident and want us to pick the car from some other location, you can do so. All that matters to us is you having a peace of mind.

    </p>
    <p>We boasted an excellent team of drivers and equipped fleet of towing trucks that carefully tows your car and handles it with care. Our drivers have a GPS system, so you can know their location and our team is immensely knowledgeable to guide to through the entire process seamlessly.
</p>
<p>Call us today on +61402700111 or simply save our number for any such tragedies. You could also recommend to your friend or colleague and never get disappointed with our service. Besides Sydney, we also offer our services in almost every location in Australia.
</p>
</div>`,
  },

  // ==========================
  // =======================================>
  // LOCATION
  // =============================
  // =============
  // ============>
  {
    id: "liverpool-nsw-sydney-australia",
    name: "LIVERPOOL NSW",
    type: "location",
    image01: location1,
    description: `Being the quickest service in Liverpool NSW, we are delighted to inform you that we offer to tow of the vehicles and provide services.`,
    tabHeading1: "About location",
    tabContent1: `<div class="card-body">
    <ul>
        <li>Damaged Car Removal</li>
        <li>Junk Car Removal</li>
        <li>Old Car Removal</li>
        <!-- <li>New Castle Car Removal</li> -->
    </ul> 
</div>`,
    tabHeading2: "getting cash for car removal in liverpool NSW",
    tabContent2: `<div class="card-body">
    You can easily find us on Google or call us if you want any car removal. Our customer support will immediately book an appointment with you and remove your junk car when you want us to. We not only buy all kinds of vehicles but also provide car removal to all our customers in #Liverpool NSW.

</div>`,

    tabHeading3: `What models we buy and remove`,
    tabContent3: `<div class="card-body">
    We buy all sorts of make and models at Liverpool NSW like:<br>

    Japanese: Suzuki, Toyota, Nissan, Mazda, Mitsubishi, Honda, Subaru, Isuzu, Lexus, and Daihatsu brand
    European: Mercedes, Audi, BMW, Porsche, Opel, Volkswagen, Fiat, and Alfa Romeo brand
    American: Ford, Chevrolet, Chrysler, Dodge, Jeep, Tesla, Holden.
    
</div>`,

    someData: `<div class="new">
    <h3>CAR REMOVALS</h3>
    <p>We are an expert when it comes to <b><a href="https://unwantedcarremovalsydney.com.au/scrap-car.html">scrap car</a></b> removals. Our process is very simple. If you face a car
         breakdown or you want to get your car towed for some reason, all you need to do is dial up 
         our number. Our customer service will immediately take down your details, including the GPS
          location, and reach the spot in thirty minutes. This is possible because we always have a tow 
          truck ready in Liverpool NSW side for our customers. Once we reach, you can relax as we take
           the responsibility of removals your car safely and doing all the paperwork for you. All we need is your sign. Further, you just have to tell your destination, and you will get your car there as per the time is given. We are the best when it comes to scrao unwanted cars. Our drivers have a license and know their job very well. With best customer support, hassle-free service, and quick service, we are probably the best car towers in #Liverpool NSW.
        </p>
        
        <p>Usually, our customers ask to drop their car at a mechanic or their home. Whatever the location is, we do it with uttermost care because we know the value of your car. We have a record of not damaging any car up till now. We also are popular for quick delivery, and we are never late. So, if you want your car to get fixed by a mechanic and go-ahead to the airport, you can trust us that we will drop your car to the mechanic on time. We value our customers and their time.
            </p>
            <h3>When is Scrap Car #Removals needed?
                </h3>
                <p>Normally, when our customers call us when their car is smashed in an accident,
                     or it has broken down due to some reason. The reason could be like flat batteries,
                      losing keys, fuel top-ups, engine failure, sickness, or anything. Whatever the reason
                       is, our quick service, which is of low cost, always comes in handy. All you need to
                        do is just dial our +0410 300 150. Our service center in Liverpool NSW offers fast 
                        service and will reach your location promptly.
                    </p>
        <h3>How much do you charge?
            </h3>
            <p>We know our customers are already in vain when the car has broken down or been smashed. 
                We understand this and offer a low-cost service. Although our service is low cost, we do
                 not compromise on customer support and provide quality service from our end. We provide
                  an affordable 999/- flat rate for the first 10 kilometers and then impose for each 
                  additional kilometer. Our rates are fixed and do not offer a discount because they are 
                  already low. We also do not surprise our customers with an extra hidden cost at the last
                   moment. We are transparent and work with pride. You will not find our kind of service 
                   anywhere else in Liverpool, NSW. With our hassle-free service, we have managed to 
                   provide our car removing service to hundreds in Liverpool NSW.
                </p>
        <h3>Do you deal only with specific brands?
            </h3>
            <p>
                    We offer our car removing services for all kinds of car brands like:
                    </p><ul>
                        <li>Audi</li>
                        <li>BMW</li>
                        <li>Daewoo</li>
                        <li>Alfa Romeo</li>
                        <li>Ford</li>
                        <li>Holden</li>
                        <li>Hyundai</li>
                        <li>Jeep</li>
                        <li>Kia</li>
                        <li>Land Rover</li>
                        <li>Mercedes</li>
                        <li>Mitsubishi</li>
                        <li>Nissan</li>
                        <li>Volkswagen</li>
                        <li>Volve</li>
                    </ul>
            <h3>What Vehicles we Take and give you Cash?

                </h3>
                <p>
                        Our tow trucks are heavy, and we are equipped to carry all sorts of vehicles. So, if 
                        you want any of the below to be towed, you can call us on  <a href="tel: +0410 300 150">+0410 300 150</a>.

                        </p><ul>
                            <li>Cars</li>
                            <li>Trucks</li>
                            <li>Vans</li>
                        
                            <li>Four Wheel Drives</li>
                            <li>Buses</li>
                           
                        
                            <li>Mini Vans</li>
                            <li>Junk Cars</li>
                           
                           
                        </ul>
                
                <h3>Do you offer services at night?

                    </h3>
                    <p>The car does not break down at a given time. We understand your pain and are available round the clock. We offer our car removals services to Liverpool NSW residents at any time of the hour with no additional cost. You are in safe hands when you get your car towed with us. We even have good contacts of mechanics where you can repair your car in the late hours of the night.
                        </p>
            <h3> Buying Junk Cars, SUV, Trucks, Vans, and more
                </h3>
                <p>There is a point in everyone's life where they want to sell their old car. Life is all about moving ahead, leaving the old used stuff. If you want to sell your <b><a href="https://unwantedcarremovalsydney.com.au/cash-for-junk-cars-in-sydney.html">junk vehicle</a></b>, you are in the right place. We buy all sorts of junk vehicles and give you a very good quote. We are the best car buyers in Liverpool NSW, providing the best cash for used cars. We are a reputed company known as a used car buyer and used truck buyer.
                    </p>
                    <h3>What Kind Of Vehicles Do You Buy?
                        </h3>
                        <p>We buy every vehicle of any make and model and pay the right cash for them. It does not matter if you own an American, European, or Japanese make. We buy all models of vehicles, even if they are not in running condition. We pay cash on the spot, and you do not have to go through a lengthy procedure with us to sell car for cash. We offer the right cash for old cars and trucks.
                            </p>
                            <h3> What models do we buy?
                                </h3>
                                <p>We buy all models of Mercedes, BMW, Audi, Porsche, Volkswagen, Opel, Fiat, and Alfa Romeo brand of European cars. We also buy American cars like Ford, Chrysler, Chevrolet, Dodge, Jeep, Tesla, Holden American cars even more. We are proud to buy Japanese cars like Toyota, Nissan, Suzuki, Subaru, Mitsubishi, Mazda, Honda, Renault, Isuzu, Proton, Peugeot, Lexus, Chery, Citroen, Daewoo, Great Wall, Infiniti, and Daihatsu brand. We also accept Korean cars like SsangYong, Daewoo, Kia, Hyundai Korean cars, and more. So, basically, we buy all sorts of cars no matter what the make and model is. We pick your used car from the destination you want us to. With hassle-free service, outstanding customer support, and easy procedures, we are proud to be the best used car buyers in Liverpool NSW.

                                    </p>
                    <p>We know our work well and are experts in buying the old and damaged car. We follow proper rules of disposing of junk in an environment-friendly way. We examine your car in our workshop and give you the best quote, which you won't find in the entire Liverpool NSW. We also guide you well so you can know the value of your car. Our representatives can even come to your location to examine your car, saving your time.

                        </p>
            <h3>CAR REMOVAL
                </h3>
                <p>Car removal is a much-needed service in Liverpool NSW. <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> is a proud 
                    provider for <b><a href="https://unwantedcarremovalsydney.com.au/car-removal.html">car removal</a></b>. A junk piece is of no use and eats up a lot of space. 
                    Also, to maintain a junk car or truck is a headache. Everyone wants to remove
                     junk and get rid of it as soon as possible, and that is where<a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD
                      comes into action. We have removed a variety of junk cars of all models and makes in Liverpool NSW. 
                    </p>
                    <h3>Getting Cash for Cars Removal in Liverpool NSW
                        </h3>
                        <p>You can easily find us on Google or call us if you want any car removal. Our customer support will immediately book an appointment with you and remove your junk car when you want us to. We not only buy all kinds of vehicles but also provide car removal to all our customers in Liverpool NSW.

                            </p>
                            <h3>Why are car removals essential?
                                </h3>
                                <p>Unwanted Car removals are needed. One cannot keep a car for long if it is not
                                     worth it. Below are some of the reasons given on the necessity of <b><a href="https://unwantedcarremovalsydney.com.au/unwanted-car-removal.html" style="color: #ef3f49;">unwanted 
                                     car removals</a></b>.</p>
                                     <p>Broken cars eat up space – A broken car is hard to maintain as it eats up a lot of space. People need to pay parking fees if they do not have space in their garage. It is not a good sight to see old junk every day as they are usually dirty and makes the garage look cluttered.
                                        </p>
                                        <p>Broken cars are an environmental risk – Broken car usually means a faulty part/battery or a flat tire. A junk car does not just sit in your garage; it also generates lots of poisonous fumes, making them an environmental hazard. So, if you have children at home, we advise you to sell it off or scrap car removals it as soon as possible. 
                                            </p>
                                            <p>Broken cars are unsafe for humans – Driving an old junk car can risk your life as you can meet an accident if the brake fails or if the tire flattens in the middle of a highway. You surely do not like to inhale the poisonous fumes it generates. The wisest decision is to sell your old junk car and get some good cash out of it.
                                                </p>
                <h3>Why Choose Our Car Removals Liverpool NSW Service?
                    </h3>
                    <p>With our vast experience in dealing with all sorts of junk cars and trucks, we have become an expert. We know what the car's value is, and we give you the right quote. We also help you with the paperwork. Our customer service is world-class, and we never disappoint our customers. We offer them cash instantly, so they do not have to get busy with us. Customers get rid of the car soon and get their deserved cash. We ensure that you get the right direction with a real diagnostic of your vehicle.
                        </p>
                        <p>Our staff consists of professionals with immense knowledge in the field of car removals. They are fluent in English and guide you in each and every step.

                                We also offer many value-added services like a free online quote and free paperwork completion to ensure the fast and smooth deal.
                                 
                                </p>
                <h3>How our Car Removal Liverpool NSW Program Operates?
                    </h3>
                    <p>Ours is a user-friendly car removal program. We list down the steps that ensure that our customers know how easy it is to remove a car.
                        </p><ul>
                            <li>Share your vehicle details with us and receive an instant quote on your vehicle.
                                </li>
                            <li>After the physical inspection done by our experts, we make the offer. 
                                </li>
                            <li>Once you take the offer, we will tow away your vehicle as per your instructions.
                                </li>
                            <li>Get instant cash when your car is towed.
                                </li>
                        </ul>
                    
                    <h3>What Models We Buy And Remove?
                        </h3>
                        <p>We buy all sorts of make and models at Liverpool NSW like:<br>

                                Japanese: Suzuki, Toyota, Nissan, Mazda, Mitsubishi, Honda, Subaru, Isuzu, Lexus, and Daihatsu brand
                                European: Mercedes, Audi, BMW, Porsche, Opel, Volkswagen, Fiat, and Alfa Romeo brand
                                American: Ford, Chevrolet, Chrysler, Dodge, Jeep, Tesla, Holden.
                                </p>
</div>`,
  },
  {
    id: "bankstown-nsw-sydney-australia",
    name: "BANKSTOWN NSW",
    type: "location",
    image01: location2,
    description: `Being the quickest service in Bankstown NSW, we are delighted to inform you that we offer to tow of the vehicles and provide services.`,
    tabHeading1: "About free car removal in Bankstown NSW",
    tabContent1: `<div class="card-body">
    <p>If you are thinking about any hidden costs for the services we provide, put your mind at rest. We don’t do that. Our car #removal service is completely free. There are no hidden charges to haunt you. From getting a quote till <b><a href="https://unwantedcarremovalsydney.com.au/car-removal.html">car removal</a></b>, we are transparent and hate to give unpleasant surprises to our customers.</p>
</div>`,
    tabHeading2: "car removal",
    tabContent2: `<div class="card-body">
    we come to you, buy your car, and offer you a high price, depending on the
  vehicle, and that also includes free car removal around the Bankstown NSW 
  region. To sell your car for good cash and get a free <b style="color: #00ff00;">car removal</b>, all you 
  need to call us on  <a href="tel: +61402700111">+61402700111</a>. Our customer service representative shall schedule 
  an appointment and examine your car at the location you want to. We value your 
  time and try to reduce all formalities as much as we can. 
</div>`,

    tabHeading3: `Facilities`,
    tabContent3: `<div class="card-body">
    Before you sell your junk or <b style="color: #ef3f49;">car removal</b>, make sure your personal belongings have been lifted from the car. You are just a ring away from getting good cash for your <b style="color: #00ff00;">old car</b> with free pick up among Bankstown NSW and its neighborhoods.
</div>`,

    someData: `<div class="new">
    <h3>CAR Removal Service</h3>
    <p>Accidents can occur anywhere and even to the most cautious drivers. If you find yourself in a 
            crash state where your vehicle or motorbike needs to be towed,<a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD scrap can move your destroyed vehicle to the garage, storage lot, service station, or depot of your choice.

               <br> Your tow truck driver will describe your rights about choosing a scrap destination of your choice. It must be done before you sign the state-required "Authority to Tow" certificate that enables us to move your vehicle. If you are not able to sign the document, another responsible adult, a police officer, or a VicRoads official is authorized to approve the text on your behalf.
                
                <br>We have established a solid reputation for car service in Bankstown NSW over many years. We are specialists at quickly getting you and your vehicle or truck off the road so that there is no added risk of further damages to you or other drivers on the road. We will tow and prepare your car or motorcycle for secure movement and scrap from an accident scene or if you've experienced a traffic offense.</p>
                
                <h3>We are the best option</h3>
                <p>We are the best car #service Bankstown NSW has on offer.  Depending on the style and type of vehicle involved, we can securely tow front wheel, back wheel, and all-wheel drive vehicles. To limit any additional damage to other cars on the road, we are equipped to clean up any remaining crash wreckage. We understand how upsetting and preventing an accident can be, and we will be on the scene as soon as possible to manage all of the crash site scrap needs and to drive you to whatever safe location you decide.</p>
                <h3>Emergency Service</h3>
                <p>To get your cash for scrap car, call <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD , at any hour
                                 of the day or night. When you are on the road, and your carrier breaks 
                                 down, turns off the way, or stops working, you can rely on #Auswide #car Removals PTY LTD scrap to come to your rescue. If you are in a car park and your vehicle will not start, whether that is in an outside area, open area, or an underground location, or a controlled area location, we can still come to your rescue with an exceptionally low level, tow truck. We can arrive at most Bankstown NSW locations within 30 minutes to most surrounding neighborhood areas. We have 24-hour scrap services Bankstown NSW has come to rely on. If we are not able to restore your vehicle to road worthy operation, we will tow it to a location of your choosing.</p>
                                 
                              <p>Our reputation for the best quality long distance <b style="color: #0000ff;">"scrap"</b> service encourages us
                                     to provide that level of service to every customer. When you entrust your
                                      precious assets to Auswide car Removals PTY LTD scrap, you are hiring more than
                                       a service. You are employing professional, dedicated group who transport and 
                                       treat your vehicles with the same degree of care as if they were carrying their cars.</p>   
                                     
                <h3>Buying Junk Cars, SUV, Trucks, Vans, and more</h3>
                
                <p>Are you thinking of selling your old car? Do you feel it’s time to upgrade or even downsize? Are you hesitant to move forward due to the hassle of selling your car alone and dealing with a lot of formalities? Or you feel you won't get the right price for your car? </p>
                
                <p>Worry no more! Whatever the case may be at <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD,
                                                 we can make your selling experience seamless, hassle-free, and give you <b><a href="https://unwantedcarremovalsydney.com.au/cash-for-cars-in-sydney.html">cash for a used car</a></b> to you on the spot.

                                                  <br>  Gone are the days when people use to take time to decide on
                                                   where to sell their cars. At<a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD, our experts have comprehensive knowledge of cars and can even guide you to make a wiser decision for your next car. </p>
                                                   
                    <p>All you need to do is to call us on  <a href="tel: +61402700111">+61402700111</a>, and one of our friendly staff will speak to you. At <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD,
         we buy your car without any hassle. If you are a serious seller, look no more. <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD
          is the best-used car buyer in Bankstown NSW you will ever find. Once we get a call from you, we come to the location you want us to examine your car and give the best quote. Our quotes are way better than our competitors. </p>
          
          <p>We buy all kinds and makes of cars. We even buy cars which are not in running condition. We help you get rid of your junk as soon as possible. But besides getting rid, we also give you instant payments, quick service, and hassle-free service.</p>
           <h3>How It Works 
                    </h3>
                    
            <ul>
                <li>Share your vehicle details with us and receive an instant quote on your vehicle.</li>
                <li>After the physical inspection done by our experts, we make the offer.</li>
                <li>Once you take the offer, we will tow away your vehicle as per your instructions.</li>
                <li>Get instant cash when your car is towed.</li>
            </ul>
            <h3>Car Removal</h3>
            <p>If you are wondering how to sell your unwanted car in Bankstown NSW, do not worry. 
                    Sell it to the best <b><a href="https://unwantedcarremovalsydney.com.au/old-car-removal.html">car removal</a></b> in Bankstown NSW and be among those who get top 
                    cash for their vehicles. We at <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD accept all makes and 
                    models of any age and any condition and offer you great prices for free <b style="color: #ef3f49;">"car removal"</b>.
                     We offer more than what you thought your old junk would have fetched you. </p>
                     
                     
                     <p>At <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD, we come to you, buy your car, and offer you
                         a high price, depending on the vehicle, and that also includes free car removal
                          around the Bankstown NSW region. To sell your car for good cash and get a free 
                          car removal, all you need to call us on  <a href="tel: +61402700111">+61402700111</a>. Our customer service representative shall schedule an appointment and examine your car at the location you want to. We value your time and try to reduce all formalities as much as we can. </p>
                          
                          <h3>Free Car Removal in Bankstown NSW</h3>
                          <p>If you are thinking about any hidden costs for the services we provide, put your mind at rest. We don’t do that. Our car removal service is completely free. There are no hidden charges to haunt you. From getting a quote till car removal, we are transparent and hate to give unpleasant surprises to our customers.
<br>
                                    Whether your car is running or not, whether you have the keys with you, these things will not stop us from picking up your unwanted vehicle at all. Your car doesn’t even need to be working. We can work something out with you if you don’t have one or the other.</p>
                                    
                                    <h3>What Cars we buy ?</h3>
                                          <ul>
                                                    <li>Audi</li>
                                                    <li>BMW</li>
                                                    <li>Daewoo</li>
                                                    <li>Alfa Romeo</li>
                                                    <li>Ford</li>
                                                    <li>Holden</li>
                                                    <li>Hyundai</li>
                                                    <li>Jeep</li>
                                                    <li>Kia</li>
                                                    <li>Land Rover</li>
                                                    <li>Mercedes</li>
                                                    <li>Mitsubishi</li>
                                                    <li>Nissan</li>
                                                    <li>Volkswagen</li>
                                                    <li>Volvo</li>
                                            </ul>
                                            
                                    <h3>Why select COMPANY NAME for car removal?
                                            </h3>
                                            
                                            <ul>
                                                    <li>We buy cars of any model and make irrespective of the condition.
                                                        </li>
                                                    <li>Quality as well as hassle-free service for car removal over the Bankstown NSW and among its areas.
                                                        </li>
                                                    <li>We are fully licensed
                                                        </li>
                                                    <li>Immediate cash on the spot
                                                        </li>
                                                    <li>We buy cars, SUVs, vans, Ute’s and even trucks
                                                        </li>
                                                    <li>Reliable service
                                                        </li>
                                                    <li>Customer-focused
                                                        </li>
                                                </ul>
                                                
                                    <p>Having an old car lying in your yard for a long time is 
                                                not very environmentally friendly, and it can harm you as
                                                 well. Therefore, the best wise decision to make is to get
                                                  rid of such cars by calling the best car removal in 
                                                  Bankstown NSW - <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD, and getting rid of your car in the most comfortable and simplest way.

                                                 <br>   Letting an unused old car waste in your backyard is a waste of area as well as money. We are not only skilled in removing such cars immediately but also surprisingly pays a high amount of cash on the spot even for your <b style="color: #00ff00;">"old cars"</b>.
                                                    
                                                    
                                                    </p>
</div>`,
  },
  {
    id: "rockdale-nsw-sydney-australia",
    name: "ROCKDALE NSW",
    type: "location",
    image01: location3,
    description: `Being the quickest service in #Rockdale NSW, we are delighted to inform you that we offer to tow of the vehicles and provide services.`,
    tabHeading1: "we are the best option",
    tabContent1: `<div class="card-body">
    <p>We are the best car towing in the city of #Rockdale NSW. We are confident in 
        moving your car to the location you say. Besides that, we also clean up all 
        the remaining <b><a href="https://unwantedcarremovalsydney.com.au/car-wreckers-in-sydney.html">crash wreckage</a></b> so that you don’t have to worry about it, 
        especially when you are already tensed.</p>
</div>`,
    tabHeading2: "What kind of vehicles do you buy ?",
    tabContent2: `
    <div class="card-body">
    We buy almost every vehicle of any make and model you can think of. No matter if you are
     running, smashed, or wrecked. No matter if your car is European, American, Japanese, or Korean,
      we give you the best price in town, and we guarantee you won’t find our rates anywhere else in
       Rockdale NSW. 

</div>`,

    tabHeading3: `Getting cash for car removal in Rockdale NSW`,
    tabContent3: `<div class="card-body">
    To remove your old car, dial us on  <a href="tel: +61402700111">+61402700111</a>  Our customer agent shall take 
    down your details and soon send someone with our equipped truck to remove your
     <b><a href="https://unwantedcarremovalsydney.com.au/old-car-removal.html">old car</a></b>.

</div>`,

    someData: `<div class="new">
    <h3>Car Removal Services
        </h3>
        <p>We all love driving. These days all of us own cars. No matter how safe we drive, we encounter
             accidents at one point in our life, even if the fault is not ours. At times, your car needs
              to tow.  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD is usually the first word that comes in people’s minds in Rockdale NSW.
               We are the best choice because with us your damaged car will be safely transported with hassle-free service and at a low cost. What else do you want? 

            </p>
            <p>Once you dial us, our customer service will help you by connecting with our expert
                 drivers who are talented and fully licensed. They reach the location you ask us to in
                  Rockdale NSW with our equipped tow trucks.
                </p>
                <p>We have established a solid reputation for car towing in Rockdale NSW over many years. We are specialists at quickly getting you and your vehicle or truck off the road so that there is no added risk of further damages to you or other drivers on the road. We will tow and prepare your car or motorcycle or any vehicle for secure movement.  

                    </p>
                    <h3>Emergency Service 
                        </h3>
                        <p>We offer our services to #Rockdale NSW at any time of the hour. We understand
                             that accidents don’t come with time. Or a car does not break only in the day
                              time. No matter what the issue is, dial our number, and you will immediately
                               get help from us.  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD is always to the rescue. It does not matter 
                               if your car is in a car park and your vehicle will not start, whether that is
                                in an outside area, an open area, or an underground location, or a 
                                controlled area location, we can still come to your rescue with an 
                                exceptionally low level, tow truck. We can arrive at most Rockdale NSW 
                                locations within 30 minutes using our equipped trucks. The best part is
                                 you do not need to shed extra money for this emergency service. With 
                                 the best customer service, quick support, fast service and a good tracking
                                  system, we have attracted a lot of clients in Rockdale NSW.
                            </p>
    <p> <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD and team are proud of delivering excellent towing services all across Australia and Rockdale NSW for many years. We are proud of our customer portfolio, which includes big engineering, insurance, and construction companies. We are very strict about our quality improvement and appreciate regular feedback from our clients on the basis of which we improve and provide you a better service. 
          </p>
         <p>Our services include but not limited to towing services for cars, motorcycles, caravans, trucks, trailers, boats, jet skis, and other heavy-duty equipment. To learn more about us, you can call us on  <a href="tel: +61402700111">+61402700111</a>.
            </p>
        <h4>We also offer special rates for permanent clients all across Rockdale NSW and happy to discuss the options.
              </h4>
            <h3>Buying Junk Cars, SUV, Trucks, Vans, and more
                 </h3>
                <p>We all love driving new cars and their latest models. But what about your old car?
                     Now you can leave your worries on us as we  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> Removals PTY LTD offers you the best cash for old cars along with best customer support, hassle-free service, and instant payment. We even give cash for scrap cars, cash for damaged, making us the best-used car in Rockdale NSW. Our reputation is built because of our prompt services and because we buy with no paperwork. So, if you are in Rockdale NSW and are looking for the scrap car buyer, used car buyer, or a used truck buyer, look no more. Dial  <a href="tel: +61402700111">+61402700111</a>. today! 
                                            </p>
                    <h3> What models do we buy?
                          </h3>
                         <p>We buy all models of Mercedes, BMW, Audi, Porsche, Volkswagen, Opel, Fiat, and Alfa Romeo brand of European cars. We also buy American cars like Ford, Chrysler, Chevrolet, Dodge, Jeep, Tesla, Holden American cars even more. We are proud to buy Japanese cars like Toyota, Nissan, Suzuki, Subaru, Mitsubishi, Mazda, Honda, Renault, Isuzu, Proton, Peugeot, Lexus, Chery, Citroen, Daewoo, Great Wall, Infiniti, and Daihatsu brand. We also accept Korean cars like SsangYong, Daewoo, Kia, Hyundai Korean cars, and more. So, basically, we buy all sorts of cars no matter what the make and model is. If you wish, we can even come to you and pick your car, saving you valuable time. We value our customers and treat them in all ways we can. With hassle-free service, outstanding customer support, and easy procedures, we are proud to be the best-used car buyers in Rockdale NSW.

                             </p>
                             <p>Furthermore, we know how to <b><a href="https://unwantedcarremovalsydney.com.au/cars-for-disposal-in-sydney.html">dispose</a></b> of your <b><a href="https://unwantedcarremovalsydney.com.au/cash-for-junk-cars-in-sydney.html">junk car</a></b> or used car in a proper environment-friendly way. We observe the rules and ensure we do not harm nature. Our expert team examines your car in our workshop and then quotes the right price for it. We guarantee you our prices are the best.  
                                                        </p>
                                  <h3>Getting <a href="https://unwantedcarremovalsydney.com.au/cash-for-cars-in-sydney.html">Cash for Cars</a> Removal in Rockdale NSW
                                     </h3>
                                       <p>To remove your old car, dial us on  <a href="tel: +61402700111">+61402700111</a> Our customer agent shall take down your details and soon send someone with our equipped truck to remove your old car.
                                           </p>
                                           <h3>Why are car removals essential?
                                            </h3>
                                            <p>Damaged <b style="color: #ef3f49;">"Car removals"</b> are vital. There are a lot of reasons
                                                 for the <b style="color: #00ff00;">"unwanted removal car"</b>. Some of them include:
                                              </p><ul>
                                                  <li>An old <b><a href="https://unwantedcarremovalsydney.com.au/cash-for-junk-cars-in-sydney.html">junk car</a></b> takes a lot of space. If you live in
                                                       a house with a small garage, you cannot buy a new 
                                                       vehicle until you get rid of your <b style="color: #00ff00;">"junk car"</b>. If you 
                                                       are unlucky at times, you even have to pay money for
                                                        parking. Think no more! Connect with  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD
                                                         and leave damage car removal on us now.  
                                                    </li>
                                                  <li>Broken and damaged junks are injurious to your health. 
                                                      You may feel they are just lying somewhere in your garage, 
                                                      but that’s not all it does. Depending on your car situation, 
                                                      it may even generate dangerous fumes that are unhealthy for you 
                                                      and potentially dangerous for children and the elderly. Do not 
                                                      risk your lives and immediately call  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD on  <a href="tel: +61402700111">+61402700111</a>. We offer you the best quote with no paperwork.
                                                    </li>
                                                  <li>You cannot drive the old piece of junk, or even if you do, you are simply risking your life. Do not think anymore, and take advantage of the best car removal in Rockdale NSW today!
                                                    </li>
                                              </ul> 
                <h3>Why Choose Our Car Removals Rockdale NSW Service?
                    </h3>
                    
                        <ul>
                            <li> Hassle-free service
                                </li>
                            <li>Quick service
                                </li>
                            <li>Outstanding customer support
                                </li>
                            <li> No paperwork
                                </li>
                            <li>Best offer for junk vehicles
                                </li>
                        </ul>
                    
        <h3>How our Car Removal Rockdale NSW Program Operates?
            </h3>
            <p> <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD offers a complete, user-friendly car removal program. We list down the steps that ensure that our customers know how easy it is to remove a car.
                           </p>
                           <p>We removed all sorts of make and made in Rockdale NSW like:<br><br>

                                Japanese: Suzuki, Toyota, Nissan, Mazda, Mitsubishi, Honda, Subaru, Isuzu, Lexus, and Daihatsu brand
                                European: Mercedes, Audi, BMW, Porsche, Opel, Volkswagen, Fiat, and Alfa Romeo brand
                                American: Ford, Chevrolet, Chrysler, Dodge, Jeep, Tesla, Holden.
                                </p>
</div>`,
  },
  {
    id: "newtown-nsw-sydney-australia",
    name: "NEWTON NSW CAR REMOVALS",
    type: "location",
    image01: location4,
    description: `Being the quickest service in Newton NSW, we are delighted to inform you that we offer to tow of the vehicles and provide services.`,
    tabHeading1: "we are the best option",
    tabContent1: `<div class="card-body">
    <p>We are the best car towing in the city of Newton Nsw. We are confident in 
            moving your car to the location you say. Besides that, we also clean up all 
            the remaining <b><a href="https://unwantedcarremovalsydney.com.au/car-wreckers-in-sydney.html">crash wreckage</a></b> so that you don’t have to worry about it, 
            especially when you are already tensed. </p>
</div>`,
    tabHeading2: "What kind of vehices do you buy ?",
    tabContent2: `
    <div class="card-body">
                                    We buy almost every vehicle of any make and model you can think of. No matter if you are
                                    running, smashed, or wrecked. No matter if your car is European, American, Japanese, or Korean,
                                     we give you the best price in town, and we guarantee you won’t find our rates anywhere else in
                                      Newtown NSW. 
                            </div>`,
    tabHeading3: "Getting cash for cars removal in Newton",
    tabContent3: `<div class="card-body">
                            To remove your <b style="color: #00ff00;">"old car"</b>, dial us on 90909090. Our customer agent shall take 
                            down your details and soon send someone with our equipped truck to remove your
                             old car.
                    </div>`,

    someData: `<div class="new">
    <h3>Car Services
        </h3>
        <p><a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD is the best car towing service available in Newtown NSW. We have the best fleet of equipped trucks
             to handle any kind of make and model. Our drivers are experienced, polite, and safely transports your car from 
             the location you provide to the destination you want us to drop. With almost no paperwork, your problem is resolved
              in the span of 30 minutes after you call our customer representative. <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD is a reputed company and is
               known for its hassle-free service, good tracking system, and worldwide location. 
            </p>
            <p>There can be various reasons you need car towing, varying from accidents to losing keys or just a breakdown.
                 No matter whatever it is, <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD helps its customers with quick support in Newtown NSW. We also clean
                  up all the remaining crash wreckage so that you don’t have to worry about it, especially when you are already
                   tensed.
                </p>
                <p>Our drivers take great care in handling your car. They know the value and ensure no further damage is done while the whole process is carried out. You can also track our driver’s location using our good tracking system. The best part of our service is that we even give an emergency service at no extra cost because we know tragedies can happen at any hour of the day.
                        We are the best car towing in the city of Newtown NSW. To learn more about our 
                        services, call us on  <a href="tel: +0410 300 150">+0410 300 150</a> or email us contact@unwantedcarremovalsydney.com.au.
                        </p>
                <h3>Buying Junk Cars, SUV, Trucks, Vans, and more
                    </h3>
                    <p><a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD offers great cash for your old cars, SUVs, trucks of all
                         make and made. We are the name that comes to every resident mind of Newtown NSW. 
                         Besides best customer support, hassle-free service, and instant payment, <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD
                          even gives cash for <b><a href="https://unwantedcarremovalsydney.com.au/scrap-car.html">scrap cars</a></b>, <b style="color: #ef3f49;">"cash for damaged"</b>, making us the best-used car in Newtown NSW.
                        </p>
                        <p>We buy American, Japanese, European, Korean, or any make at a very good cash price paid to you
                             instantly. So, if you are looking in the yellow pages for <b style="color: #00ff00">"cash for used cars"</b>, cash for scrap 
                             cars, or <b style="color: #ef3f49;">"cash for damaged cars"</b>, look no ahead. Auswide car Removals PTY LTD carries out the entire procedure 
                             smoothly. All you need to do is just give us a call 909990000. Our customer agent shall note down
                              all the vital details and will get your car value evaluated the way you prefer. Once you
                               agree on the price, you can take the cash instantly and bid goodbye to your <b style="color: #00ff00;">"old car"</b>.
                                Selling used cars had never been so easy. Thanks to <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD, who is the best-used 
                                car buyer in Newtown NSW. 
                            </p>
                            <p>We buy all models of Mercedes, BMW, Audi, Porsche, Volkswagen, Opel, Fiat, and Alfa Romeo brand of European cars. We also buy American cars like Ford, Chrysler, Chevrolet, Dodge, Jeep, Tesla, Holden American cars even more. We are proud to buy Japanese cars like Toyota, Nissan, Suzuki, Subaru, Mitsubishi, Mazda, Honda, Renault, Isuzu, Proton, Peugeot, Lexus, Chery, Citroen, Daewoo, Great Wall, Infiniti, and Daihatsu brand. We also accept Korean cars like SsangYong, Daewoo, Kia, Hyundai Korean cars, and more. 
                                </p>
            <h3>CAR REMOVAL
                </h3>
                <p>If you reside in Newtown NSW and looking for <b><a href="https://unwantedcarremovalsydney.com.au/accident-car-removal.html">damaged car removal</a></b>, <b style="color: #ef3f49;">"junk car removal"</b>, or <b><a href="https://unwantedcarremovalsydney.com.au/old-car-removal.html">old car removal</a></b>,
                     look no further. <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD is the best <b style="color: #00ff00;">"car removal"</b> provider in Newtown NSW.
                      With our vast experience, we help you get rid of your junk in an environmentally friendly way. 
                      Junk cars are not a good sight in your garage. Further, they are also hazardous to your health 
                      and life. People often delay unwanted car removal because they don’t get the right service provider.
                      
                      At <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD, you get this service at a low cost, with instant pay, fast service, and quick support. We provide worldwide services and are not limited to Newtown NSW. Although our services are the best, we charge a pocket-friendly fee to our customers.
                    </p>
                    <p><a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD offers a complete, user-friendly <b><a href="https://unwantedcarremovalsydney.com.au/scrap-car.html">scrap car removal</a></b>. Here are the simple steps you need to follow.</p>
                       <ul>
                           <li>Share your vehicle details with us and receive an instant quote on your vehicle.
                            </li>
                           <li>After the physical inspection done by our experts, we make the offer. 
                            </li>
                           <li>Once you take the offer, we will tow away your vehicle as per your instructions.
                            </li>
                           <li>Get instant cash when your car is towed.
                            </li>
                       </ul> 
</div>`,
  },
  {
    id: "campbelltown-nsw-sydney-australia",
    name: "CAMPBELLTOWN NSW",
    type: "location",
    image01: location5,
    description: `Being the quickest service in #Campbelltown NSW, we are delighted to inform you that we offer to tow of the vehicles and provide services.`,
    tabHeading1: "we are the best option",
    tabContent1: `<div class="card-body">
    <p>We are the best car removals in the city of #Campbelltown NSW. We are confident in 
            moving your car to the location you say. Besides that, we also clean up all 
            the remaining crash wreckage so that you don’t have to worry about it, 
            especially when you are already tensed. </p>
</div>`,
    tabHeading2: "What kind of vehicles do you buy ?",
    tabContent2: `
    <div class="card-body">
                                    We buy almost every #vehicle of any make and model you can think of. No matter if you are
                                    running, smashed, or #wrecked. No matter if your car is European, American, Japanese, or Korean,
                                     we give you the best price in town, and we guarantee you won’t find our rates anywhere else in
                                      Campbelltown NSW. 

                            </div>`,
    tabHeading3: "Getting cash for cars removal in Campbelltown NSW",
    tabContent3: `<div class="card-body">
    To remove your old car, dial us on 90909090. Our customer agent shall take 
    down your details and soon send someone with our equipped truck to remove your
     old car.
</div>`,

    someData: `<div class="new">
    <h3>Car Removal
        </h3>
        <p>It is very important for every Campbelltown NSW resident to get rid of their junk cars in the
             right away. <b><a href="https://unwantedcarremovalsydney.com.au/cash-for-junk-cars-in-sydney.html">Junk cars</a></b> are hazardous to the environment and releases fumes not good for the 
             human body. <b style="color: #ef3f49;">"Junk car"</b> sights are ugly, and they eat up your space and money if you are paying
              for the parking. Bid goodbye to all your worries because <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD

              <b style="color: #00ff00;">"car removal"</b> services 
              are there to help you. We are the best <b style="color: #ef3f49;">"car removal"</b> service in Campbelltown NSW and in Australia. With worldwide locations, hassle-free services, instant <b><a href="https://unwantedcarremovalsydney.com.au/cash-for-junk-cars-in-sydney.html">cash for your junk</a></b>, you won’t find any service provider as good as ours.
            </p>
        <p>Clear up cluttered space by selling your unwanted car. Contact <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD

                to trade your unwanted vehicle for cash. We buy any type of <b style="color: #ef3f49;">"junk cars"</b> or trucks, no matter what is its age, size, or condition. The overall state of the vehicle is taken into consideration in our valuation. We have established car recycling facilities. 
            </p>
        <p>We have become the preferred <b style="color: #00ff00;">"car wrecker"</b> in Campbelltown NSW and with our advanced and low-cost solutions. Hundreds of the area residents have managed to get a nice and convenient way to dispose of their old or unwanted vehicles. Not many people are aware that it is possible to make some good cash from the <b><a href="https://unwantedcarremovalsydney.com.au/car-wreckers-in-sydney.html">wrecked cars</a></b> gathering dust and dirt in your compound. 

            </p>
            <h3>Buying Used Cars     
                </h3>
                <p>If you are looking to sell your used vehicle, you have come to the right place.
                        <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD

boasts itself as the leader of used car buyers in Campbelltown NSW and the whole of Australia. We accept all kinds and make and even cars that are not running. You read it right! 
                        
                        </p>
                        <p>No matter what kind of vehicle you’re selling, we offer you the right price for your used car. All you need to do is call us and get the evaluation done. Our procedures are straight enough, saving your valuable time and efforts. We give you a quote not only based on your car’s condition but also on the basis of the market price. With vast experience, our experts will guide you and help you sell your car in a matter of hours. Our customer agents are polite and fluent in English. 
                            </p>
                <p>We buy all models of Mercedes, BMW, Audi, Porsche, Volkswagen, Opel, Fiat, and Alfa Romeo brand of European cars. We also buy American cars like Ford, Chrysler, Chevrolet, Dodge, Jeep, Tesla, Holden American cars even more. We are proud to buy Japanese cars like Toyota, Nissan, Suzuki, Subaru, Mitsubishi, Mazda, Honda, Renault, Isuzu, Proton, Peugeot, Lexus, Chery, Citroen, Daewoo, Great Wall, Infiniti, and Daihatsu brand. We also accept Korean cars like SsangYong, Daewoo, Kia, Hyundai Korean cars, and more. 
                    </p>
                    <h3>Cash for scrap cars
                        </h3>
                        <p>Are you looking for a local scrap service near you? Look no further.  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD

                                is the most reliable and trusted name for the following reasons:
                                · <b style="color: #ef3f49;">"We offer 24-hour scrap service"</b>. Whenever there is an accident or unexpected vehicle breakdown, our customers give us a call to sort out their cars, trucks, and any flat tyres.
                                
                            </p><ul>
                                <li>We provide Campbelltown NSW area scrap and long-distance scrap as well.
                                    </li>
                                <li>Our services are affordable, hassle-free, and we give a quick response.
                                    </li>
                                <li>We follow industry safety standards strictly. We ensure that the vehicle and the car owner are both safely transported. 
                                    </li>
                            </ul>
                            <p> 
                                    We know that every scrap service in Campbelltown NSW will not be the
                                     best, and you deserve only the best. While some services are not 
                                     available 24/7, others may be expensive and only provide scrap over
                                      a short distance. <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD

                                      scrap is just the right mix of everything, we are available 24/7, offer local scrap service over long and short distances. Also, we are affordable and honest.
                                    Our fleet of trucks consists of Transporters, Cradles, and <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD

                                   <b><a href="https://unwantedcarremovalsydney.com.au/scrap-car.html"> scrap Vehicles</a></b>. We are a perfect choice for a vehicle such as a 4WD, your Motorcycle, Sports Car, Machinery, or even Race Cars! We help you out of any situation. You can trust us!
                                    </p>
                                    <p>We do not believe in using the customer’s state to our advantage. We do not surprise our customers with any hidden charges. They are already under stress.
                                            We suggest that it is always a good idea to keep our contact details for any service needed in the Campbelltown NSW area. If you are stuck on the roadside, give us a call now, and we will be there to help you out at the oddest of hours.
                                            </p>
                                <p> 
                                        We are the best <b style="color: #ef3f49;">"car removals"</b> in the city of Campbelltown NSW.
                                         To learn more about our services, call us on  <a href="tel: +61402700111">+61402700111</a> or 
                                         email us contact@unwantedcarremovalsydney.com.au.
                                        
                                        </p>
</div>`,
  },
  {
    id: "smithfield-nsw-sydney-australia",
    name: "SMITHFIELD NSW",
    type: "location",
    image01: location6,
    description: `Our trained customer representatives help you with all the services and give you the best rates in town almost instantly.`,
    tabHeading1: "our location",
    tabContent1: `<div class="card-body">
    <p>We deal with services like your <b><a href="https://unwantedcarremovalsydney.com.au/old-car-removal.html">old car</a></b>, buying it and giving you the cash for your junk cars which is perhaps the best in town. We also deal in removal of <b><a href="https://unwantedcarremovalsydney.com.au/buying-junk-cars.html">junk cars</a></b> from a given location. We are Sydney’s number 1 brand because we offer payments
         instantly, and you can sell your junk car to us without any hassle using our world-class customer support.
    </p>
</div>`,
    tabHeading2: "Emergency Service",
    tabContent2: `
    <div class="card-body">
                                    We know removal can be needed at any hour of the day. At  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD
                                    , we
                                     provide 24x7 service for car removal. We are always happy to be available and 
                                     serve you. All you need to do is give us a call on  <a href="tel: +61402700111">+61402700111</a>. We always provide 
                                     a matchless level of professionalism and dependability, no matter what time
                                      you need it.

                                    
                            </div>`,
    tabHeading3: "Fastest In Sydney",
    tabContent3: `<div class="card-body">
    Being the quickest service in Sydney, we are delighted to inform you that we 
    offer to car removal of the following vehicles:
    <ul>
        <li> Fast Cash Unwanted Car
            </li>
            <li> <b><a href="https://unwantedcarremovalsydney.com.au/scrap-car.html">Scrap Car</a></b>
            </li>
            <li> <b><a href="https://unwantedcarremovalsydney.com.au/cash-for-junk-cars-in-sydney.html">Junk Car</a></b>
            </li>
            <li> <b><a href="https://unwantedcarremovalsydney.com.au/old-car-removal.html">Old Car</a></b>
                </li>
                <li><b><a href="https://unwantedcarremovalsydney.com.au/car-removal.html">Cash for cars removal</a></b>
                 </li>
    </ul>
</div>`,

    someData: `<div class="new">
    <h3>Car Removals
        </h3>
        <p>We are Smithfield NSW most reliable car removal service. If you experience a breakdown or you
             are looking for removals to transport your vehicle,  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD
             is a trustworthy and
              dependable scrap <b style="color: #ef3f49;">"car removal"</b> service in Smithfield NSW. We can offer you quick and reliable accident
               remove <b style="color: #00ff00;">"car scrap"</b> assistance. We have the best service to provide you with a cost-effective solution, 
               no matter the kind of circumstances you’ve encountered.
            </p>
            <p>We have always offered professional tilt tray car removal services and high-quality <b style="color: #00ff00;">accident 
                recovery services</b> for different kinds of vehicles, dealing with customers all around
                 Australia. Servicing all of Smithfield NSW regional areas, we are known in the industry
                 for offering great-value scrap removal services at a fair and affordable price.
                </p>
            <p>If you are looking for a <b style="color: #ef3f49;">"scrap car removals"</b> in Smithfield NSW, look no ahead. Our tilt tray Smithfield NSW services are fully insured and reliable, offering you 24/7 round the clock service in your time of need. Our removal team is too good for that, making us Smithfield NSW top scrap car removal company.
                </p>
                <p>Our customer representatives are polite and well informed. Where ever you are, our team reaches in 30 minutes to help you come out of the situation.
                        To learn more about our <b style="color: #00ff00;">"car removal"</b>, call us at  <a href="tel: +61402700111">+61402700111</a>. Alternatively, you can email us.
                        </p>
                <h3>Buying Used Cars
                    </h3>
                    <p>Are you fed up with trying different ways to sell your car but haven’t got good luck yet?
                            Meet the used car buyer in Smithfield NSW.  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD is the best when it comes to paying the right price for your used vehicle. With our pool of expertise and talent, we fetch you the right price even if your car is not in a running state. We are known for quick hassle-free service and instant cash payments.  
                            </p>
                            <p>Are you moving abroad or wanted to sell your car quickly for some other reasons? Do not get ripped by an agent or an individual since we are able to pay the maximum money for all kinds of used vehicles, including cars, trucks, vans, 4WD &amp; SUV in Smithfield NSW region. 
                                    We are offering our premier services throughout Australia, including Smithfield NSW. We buy all models of Mercedes, BMW, Audi, Porsche, Volkswagen, Opel, Fiat, and Alfa Romeo brand of European cars. We also buy American cars like Ford, Chrysler, Chevrolet, Dodge, Jeep, Tesla, Holden American cars even more. We are proud to buy Japanese cars like Toyota, Nissan, Suzuki, Subaru, Mitsubishi, Mazda, Honda, Renault, Isuzu, Proton, Peugeot, Lexus, Chery, Citroen, Daewoo, Great Wall, Infiniti, and Daihatsu brand. We also accept Korean cars like SsangYong, Daewoo, Kia, Hyundai Korean cars, and more. 
                                    </p>
                <p>Besides, we buy a car in any condition - used or old, running or not, <b style="color: #ef3f49;">"junk or scrapped"</b>,
                    <b><a href="https://unwantedcarremovalsydney.com.au/car-wreckers-in-sydney.html">Wrecked</a></b> , damaged, totaled or smashed, etc. With friendly and responsive staff members,
                      our team handles everything right.  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD is fully licensed and specialized <b><a href="https://unwantedcarremovalsydney.com.au/buying-junk-cars.html">junk car buyers</a></b>

                    </p>
                    <h3>Car Removal
                        </h3>
                        <p> <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD is one of the top companies that Smithfield NSW residents call when they want to remove their old cars fast and for reasonable rates.
                                With our vast experience, we have dedicated our hassle-free services to help people get rid of their unwanted cars so that they can make instant cash and also clear their garages and backyards of the unsightly looks created by the <b style="color: #00ff00;">"unwanted vehicles"</b>. 
                                </p>
                        <p>We believe in car recycling methods with utmost care to protect our environment. 
                            No matter the make or the model of the car, we will remove the cars and offer you
                             instant cash for them. It is of no concern to us whether the car had been wrecked in an accident or it’s an old model and no longer available in the market.
                            </p>
                        <p>Don’t worry or fear to call us thinking your car is too old or junk to sell. The truth is, even this junk car gives you good cash at times. Call us today to get your car evaluated. 
                                At  <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD, our unwanted car removal services are available on a 24/7 basis. This gives you the convenience to call us any time you are ready to have the car removed. Furthermore, you can also trust us with our services. We also offer customers same day car removal services to get rid of your junk immediately. 
                                </p>
                        <p>To learn more about our services, call us at  <a href="tel: +61402700111">+61402700111</a>. Alternatively, you can 
                            email us on contact@unwantedcarremovalsydney.com.au today!
                            </p>
</div>`,
  },
  {
    id: "milperra-nsw-sydney-australia",
    name: "Mileprra NSW CAR REMOVALS",
    type: "location",
    image01: location7,
    description: `Being the quickest service in #Milperra NSW, we are delighted to inform you that we offer to tow of the vehicles and provide services.`,
    tabHeading1: "About location",
    tabContent1: `<div class="card-body">
    <ul>
        <li>Damaged Car Removal</li>
        <li>Junk Car Removal</li>
        <li>Old Car Removal</li>
        <!-- <li>New Castle Car Removal</li> -->
    </ul> 
</div>`,
    tabHeading2: "Getting cash for car removals in Milperra NSW",
    tabContent2: `
    <div class="card-body">
                                    You can easily find us on Google or call us if you want any car removal. Our customer support will immediately book an appointment with you and remove your <b style="color: #ef3f49;">"junk car"</b> when you want us to. We not only buy all kinds of vehicles but also provide <b style="color: 00ff00;">"car removal"</b> to all our customers in Milperra NSW.

                            </div>`,
    tabHeading3: "What models we buy and remove ?",
    tabContent3: `<div class="card-body">
                            To remove your <b style="color: #00ff00;">"old car"</b>, dial us on 90909090. Our customer agent shall take 
                            <div class="card-body">
                            We buy all sorts of make and models at Milperra NSW like:<br>

                            Japanese: Suzuki, Toyota, Nissan, Mazda, Mitsubishi, Honda, Subaru, Isuzu, Lexus, and Daihatsu brand
                            European: Mercedes, Audi, BMW, Porsche, Opel, Volkswagen, Fiat, and Alfa Romeo brand
                            American: Ford, Chevrolet, Chrysler, Dodge, Jeep, Tesla, Holden.
                            
                    </div>`,

    someData: `<div class="new">
    <h3>CAR REMOVALS</h3>
    <p><a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD is the best one-stop solution you will find in #Milperra NSW in Sydney. With a best fleet of equipped trucks to handle any sort of car towing, you will not need to see anywhere else. Our drivers are talented, speaks fluent English, and safely moves your car from the location you give us to the destination you want us to drop. With minimal paperwork, your problem is sorted out in the span of a few minutes right after you call our professional customer representative.<a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD is a reputed and known company because of our hassle-free service, good GPS tracking system, and worldwide location.

        </p>
        
        <p>Why would anyone need a car towing? There can be various reasons you need car towing, from tragedies like accidents to losing your keys or just a breakdown. Whatever the reason is, we help you with quick support in Milperra NSW. We also clean-up for you all the remaining wreckage so you can instantly be free from hassles. 

            </p>
            <p>Our experienced drivers take great care in handling your precious car. They know how valuable it is and ensure no further damage is done when the towing takes place. You can also track our driver’s location using the GPS we share. The best part of <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD service is that we even give a round the clock service at no additional cost because we know such services can be needed at any time of the hour. We are the best car towing in the city of Milperra NSW. To learn more about our services, call us on +0410 300 150 or email us on aus@unwantedcarremovalsydney.com.au.


            </p>
            <h3>Buying Junk Cars, SUV, Vans, and more.
                </h3>
                <p><a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD offers the right cash for your old useless cars, SUVs, trucks of all kind of make and made. We are known for our outstanding customer support, our swift services, and easy instant payment. We even give cash for scrap cars which cannot be used anymore or cash for damaged cars which are no longer running, making us the best-used car in Milperra NSW.

                    </p>
                    <p><a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> PTY LTD buy American, Japanese, European, Korean, or any make at a very good cash price paid to you immediately. So, there is no need to hunt for a service provider anymore for cash for used cars, cash for scrap cars, or cash for damaged cars. Auswide car Removals PTY LTD carries out the entire procedure seamlessly. All you need to do is just give us a call +0410 300 150. Our experienced team shall note down all the vital details and will get your car value evaluated the way you want it. Once you agree on our price, you can get your cash instantly and bid goodbye to your old useless car. Everything can happen in a matter of minutes, only with Company Auswide car Removals PTY LTD, who is the best-used car buyer in Milperra NSW.
</p>
<p>We buy all models of Mercedes, BMW, Audi, Porsche, Volkswagen, Opel, Fiat, and Alfa Romeo brand of European cars. We also buy American cars like Ford, Chrysler, Chevrolet, Dodge, Jeep, Tesla, Holden American cars even more. We are proud to buy Japanese cars like Toyota, Nissan, Suzuki, Subaru, Mitsubishi, Mazda, Honda, Renault, Isuzu, Proton, Peugeot, Lexus, Chery, Citroen, Daewoo, Great Wall, Infiniti, and Daihatsu brand. We also accept Korean cars like SsangYong, Daewoo, Kia, Hyundai Korean cars, and more.
</p>
        <h3>CAR REMOVAL

            </h3>
            <p>If you happen to be a resident of Milperra NSW, and if you are looking for services like <b style="color: #00ff00;">"damaged car removal"</b>, <b style="color: #ef3f49;">"junk car removal"</b>, or <b><a href="https://unwantedcarremovalsydney.com.au/old-car-removal.html">old car removal</a></b>, contact us today! If you are not, you can still store our number. We are known to get rid of your junk in an environmentally friendly way. These cars are hazardous to your health and life. We offer car removal service at a low cost, with instant pay, fast service, and quick support. Our services are not limited to Milperra NSW. 

                </p>
                <p>We offer a complete, budget friendly, <b style="color: #00ff00;">"car removal"</b> service.
</p>
        <h3>
WHY CHOOSE US?

            </h3>
                    We offer our car removing services for all kinds of car brands like:
                    <ul>
                        <li>Immediate cash: We offer you cash almost immediately with minimal paperwork. We value your time.
</li>
                        <li>Worldwide location: We deal in most of the countries.
</li>
                        <li>Huge storage: We provide huge storage for your junk vehicles.
</li>
                        <li>AlfaDelivery in time: We give our services instantly and also offer emergency service available.
</li>
                        <li>Payment instantly: We offer you cash almost instantly.
</li>
                        <li>Hassle free service: Our services are quick and hassle free.
</li>
                        <li>Affordable fees: We don’t drill a hole in your pocket.
</li>
                       
                    </ul>
</div>`,
  },
  {
    id: "sydney-australia",
    name: "Sydney Australia",
    type: "location",
    image01: location8,
    description: `A lot of people wonder how to dispose of their old cars in the right way. Disposal is to get rid of your old car which you no longer use because they are either too old to drive, or not even in a working condition. They take ample space in your garage and are of no use to you or anyone in your home.`,
    tabHeading1: "How it works ?",
    tabContent1: `<div class="card-body">
    <p><a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removal</a> Services helps its customers to dispose of the car easily. We are conveniently located and offer services in the most remote area as well in Australia. What more? You can call us anytime and get your free quote. Our team tows your old car absolutely for free and you don’t have to spend an entire day filling out papers.

             </p>
</div>`,
    tabHeading2: "How do i start disposing of my car ?",
    tabContent2: `<div class="card-body">
    We believe in a hassle-free and transparent process. Here are simple steps you would need to follow to dispose of your old car.

   </div>`,

    someData: `<div class="new">
    <h3>Accident Car Removals:  
</h3>
    <p>If you have met with an accident, and are looking for a dealer to remove your car, you have come to the right place. Auswide Car Removals PTY. LTD. is a renowned car removal service provider in Sydney and offers a fantastic price of up to $30,000 if you want to sell your Toyota car. Accidents can cause trauma, and we understand this. Our team knows well how to handle such situations. With minimal paperwork, you are out of this headache in no time. If your car is beyond repair, you can sell it to us for a great price. If you think the damage is small, we offer our car tow services, and if you are confused, we can help you in that too to help you make the right decision.

    </p>
    <p>We offer a variety of other services besides <b style="color: #00ff00;">"car removal"</b>. We are a one-stop solution to all your car problems in Sydney. Our professional team quickly comes to the accident spot, clear all the wreck, and tow your car to your given location. Our drivers are polite and are fully licensed. Our tow trucks are fully equipped and also have a GPS, so you can track where your car has reached. We are one of the very few service providers in Sydney, who is honest and offer transparent transactions. We have no hidden costs and are reliable. In simple steps, you can tow your car. All you have to do is dial our number, speak to our customer service agent, and give your location. In a short span, our team would reach there, and your car is towed with a little paperwork needed. We value our customers and their time. We know accidents can happen anytime, and we offer emergency services as well to our valued customers. We are known in Sydney for our outstanding services. Call us today, or email Auswide Car Removals PTY. LTD to know more! 
    </p>
    <h3>Car Removals Sydney
</h3>
    <p>Auswide Car Removals PTY. LTD has earned a reputation when it comes to any kind of car removals in Sydney. We are one of the leading service providers in Sydney, and offer <b style="color: #ef3f49;">"car removals"</b> for all kind of cars and make. If you have an SUV, Sedan, Jeep, or even a sports car, we can help. We also take in all cars no matter what their condition is. If you think your car is too old, you are thinking wrong. Even if the car is not running, we accept it. 

    </p>
    <p>Our team is very professional and have been doing their job for decades now. We offer the most competitive price in the market, which can be up to $30,000! How? We use our algorithms based on the market price, the value, the scrap price, and other details. We also look at the current price of your make and model.
    </p>
  
    <p>Our Car Removal services are safe because we work by the rules. We have a huge workshop and recycle <b style="color: #0000ff;">"scrapped cars"</b> in an environmentally friendly manner. We ensure we don't harm the planet. We believe in a greener planet and give in our bit. Our team is super professional and guides you well throughout your removals. We believe in minimal paperwork and quick, hassle-free services because we understand time is money. We also go to an extra mile by offering our customers emergency services when required, and car towing on weekends as well. Our quote for car removals is FREE. All you need to do is quickly call us, and let us know about your car. We get back to you as soon as possible with a FREE and best quote in Sydney. With vast experience and outstanding customer service, you will not find anyone better than us in Sydney. What are you waiting for? Give us a call today! 
    </p>
    <h3>Cash For Cars

</h3>
    <p>Are you looking for some quick cash? How about selling your <b style="color: #00ff00;">"old car"</b> to Auswide Car Removals PTY. LTD, and making some decent money? We are the best car removal services in Sydney and offer you a great price. For instance, you can make a quick $30,000 with us when you sell in your old Toyota to us. We are reliable, quick, and offer the best service in Sydney. We have a huge network in Sydney and are known to be the leading providers. What makes us so different from other providers is that we believe in an easy and straight forward process. If you wish to sell your car, call us and let us know the description of your car. We offer you a FREE quote in minutes, and if it works for you, you are good to go! Our professional team arrives at your given location and tows your car with minimal paperwork. We then give you <b style="color: #ef3f49;">"instant cash"</b>. Car removals have never been so easy before. 

    </p>
    <p>Why Auswide Car Removals PTY. LTD?
        </p><ul>
            <li>
We are one of the very few service providers in Sydney that offers the best price in town, even when your car is not in the working condition. 
</li>
<li>Our customer service is exceptional. They explain everything appropriately, especially if it is your first time. 
</li>
<li>If you are looking for hassle-free service, quick service, and one who offer emergency service if needed, you can call us. 
</li>
<li>We believe in no hidden cost and minimal paperwork. 
</li>
<li>We are established and have a fast experience in the industry, so we can offer you the best advice you need.
</li>
Is it worth selling your car for cash?

        </ul>

<p>It is absolutely worth selling your <b style="color: #00ff00;">"car for cash"</b>. With the same cash, you can invest in something more appropriate. You can also use the same amount in the down payment of your next dream car. As we mentioned earlier, we offer around $30,000 on Toyota Cars, which is a good amount for any kind of investment. We offer the best price in Sydney. 
</p>
<p>After towing your vehicle, we carefully examine your car. Your car may not be in a working condition, but still has a lot of working spare parts. We remove them carefully and resell them as spare parts. This way, people can gain by getting spare parts for a lower price. If your car doesn't have any spare parts we can resell, we sell it as scrap. We do our bit by ensuring no harm is done to mother nature in the entire process. 
</p>
<p>You would never regret your decision by selling your car with Auswide Car Removals PTY. LTD. We are the best in all terms and offer you the best price. You could save our number and share it with your friends and colleagues. We work on weekends and also offer emergency services if required. We are number one in Sydney when it comes to quick and hassle-free service. Call us now to know more! 
</p>
<h3>Cars Wreckers
</h3>
<p>At Auswide Car Removals PTY. LTD, we are the best Car Wreckers in Sydney. With our vast experience and knowledge, we assure to offer the best price to our customers. We give the best value from your <b style="color: #ef3f49;">"old wrecked car"</b>, no matter what the state of your car is – burned, damaged beyond repair, old, rusty, or not even in a working state. At Auswide Car Removals PTY. LTD, we follow the green principles of auto recycling. It means we follow laws and give our bit to nature by offering an eco-friendly car recycle with nearly 100 per cent of your vehicle recycled. By this method, you get the best value for your van, truck, sedan, jeep, bike or car. A <b style="color: #0000ff;">"wrecked car"</b> needs careful towing, and our expert drivers know how to handle this easily. They are fully licensed and speak bilingual, so it is easy for our customers to communicate. They are patient and will ensure the entire process is done in a smooth manner. 
</p>
<p>Our prices are incredible, and you can make up to $30,000 if you want to sell your car to Auswide Car Removals PTY. LTD. With our awesome network and contacts, we offer a competitive price. You can know the exact price you get by selling your wrecked car when you call us. We offer a FREE quote to our customers. If the quote works well with you, we offer our service on the time and date you want us to come over. If you want help in the wee hours of the day, we offer our emergency service. Our clients love our work and keep coming back to us for any issues regarding car removals. We are a hot favourite in Sydney because of our commitment and dedication.
</p>
<h3>Scrap Car Removals
</h3>
<p>If you are thinking of <b style="color: #00ff00;">"scrap  car removals"</b>, you have come to the right place. We are number one in Sydney and offer the best price for your scraps. A scrap part helps in a long way to our community because it reduces the manufacturing of car parts. The scrap that comes out of one non-working car is useful and turns another car running. It also helps people financially because they have to pay a lesser price for the same part. When a car is not working, a lot of other parts are in excellent condition like the windows, doors, handles, music system and more. They can be removed carefully and fitted in another car of the same make and model to make it running. 
</p>
<p>At Auswide Car Removals PTY. LTD, we believe in offering the best to our customers by being transparent. You can call us to know more about your car and scrap removal. We offer a FREE quote to our customers. Our team speaks bilingual and helps you in every step if you are confused. We also guide you and help you make the right decision. You can read our clients' testimonials to get an idea of our services. We have decades of experience and are one of the players in the Sydney market. 
</p>
<p>Our team is professional, polite and knows very well how to handle their job. We are known to offer the best price up to $30,000 for Toyota car removals, making us one of the leading car removal providers in Sydney. With Auswide Car Removals PTY. LTD, you can bid your car removal problems forever. What are you waiting for? Call us today, to learn more about <b style="color: #ff0000;">"car removals"</b> or how we can help you. 
</p>
<h3>Unwanted Car Removals</h3>
<p>You are probably here because you have an ugly unwanted car parked in your garage. An unwanted car can be a sore to your eyes, eat up all the space in your garage and be a financial burden if you have to pay for its parking. But besides these reasons, there is one more reason you should sell off an unwanted car. These cars emit dangerous fumes and rust, which are harmful when inhaled. You may want to sell your car quickly if you especially have kids and senior citizens in your house. You don't have to worry any more or lookout in the yellow pages for the right dealer. 
</p>
<p>Auswide Car Removals PTY. LTD is the best <b style="color: #ff0000;">"car removals service"</b> in Sydney and offers you the best price. We don't care if your car is ugly, not working, or just a piece of junk scrap. For us it is useful, and we would pay you cash for any kind of car you sell us. The process is pretty straight forward. Just call us and get a FREE quote. If it works for you, our team would come and tow your unwanted car in a matter of minutes and give you cash for your car. We believe in minimal paperwork and understand that time is money. We offer our services throughout the week. You can get up to $30,000 if your car deserves it. Selling unwanted car has never been so easy in Sydney. With our vast experience and knowledge, we offer the best services in town. You will never regret saving our number. We also advise you to store our number, even if you don't have an immediate need. You could recommend our services to your family or colleagues in need. Your search for the best car removal service in Sydney ends on Auswide Car Removals PTY. LTD.
</p>

<h3>Old Car Removal Sydney

</h3>
<p>An old car is an extra burden on shoulders. That rusting heap of junk is a real jalopy of no use to anyone. The best thing to do is to sell it. Driving such an old car is risky, and you may meet an accident. If you are thinking of selling your old car in Sydney, Auswide Car Removals PTY. LTD is the one-stop solution for all your problems. We offer up to $30,000 on Toyota cars and even more if your vehicle deserves it. We calculate the price according to the latest market prices and using our algorithms offer the best price in Sydney. We provide excellent deals and with our vast experience and in-house experts.
</p>
<p>Besides offering you an excellent price for your <b style="color: #ff00ff;">"old car"</b>, there are other numerous benefits when you sell your car at Auswide Car Removals PTY. LTD, Sydney. Our customer service is exceptional, and our drivers are fully licensed. We offer car towing from any location you ask us to. In easy steps, you can sell your car to us. Once you make your mind, you have to call us and describe your car details. We offer you a FREE quote. This way, you also get to know the real value of your car. If you like the amount we offer, we then come to your given location. After we make the arrangements of towing your car, with minimal paperwork, we give you instant cash. It is as easy as that. Get rid of your old car in minutes with the leading provider in Sydney. We also offer other services like wrecked car removals, accident car removals and many others. We accept all kind of cars, makes and models, even if they are discontinued models. 

</p>
<h3>
Damaged Car Removal
</h3>

<p>A damaged car is a pain for everyone. Neither can one drive it and nor can one keep the car. There are two things one can do with a <b style="color: #ffff00;">"damaged car"</b>. If the damage is beyond repairs, then it is best to sell your car. Removing your car would help you get some instant cash that you can use in buying another car for yourself. You can get up to $30,000 when you use <b style="color: #00ff00;">"car removals"</b> service with Auswide Car Removals PTY. LTD, Sydney. If your car is repairable, then you have to check with a mechanic. But if the damage is big, you will end up paying a lot of money. The possibility of the problem coming back still remains. And you may end up repairing your car every now and then. This way you sort of spend more money than needed on your old and damaged car. 

</p>
<p>If you have made your mind to remove your damaged car, then you don’t have to look anywhere else. Auswide Car Removals PTY. LTD is the best service provider in Sydney. We offer you the best price which no one else does in Sydney, making us the leading service provider. You will love our easy process and friendly customer service. In simple steps, you can get a FREE quote from us, which shall help you get the knowledge and value of your car. You can then decide if you want to remove your car with us. We guarantee you with the price we offer; you shall never go anywhere else. We require minimum documents from you, which we do let you know once you make your final decision. At Auswide Car Removals PTY. LTD, Sydney, you get what you think. Call us today to know more! 

</p>
<h3>Cash For Scrap Cars
</h3>
<p><b style="color: #ef3f49;">"scrap cars"</b> are of no use to anyone. You cannot drive it, and there is no point in repairing it. The only thing you can do is remove your scrap car. If you are looking for some cash by selling your scrap car, your ultimate destination should be Auswide Car Removals PTY. LTD. Why? At Auswide Car Removals PTY. LTD, Sydney you will get the right price for your scrap car, and you cannot find a better price than what we offer. We give up to $30,000 for Toyota cars, or even more if your car is valuable and have good and working spare parts. Selling your scrap car is not rocket science. In simple steps, you can sell it to Auswide Car Removals PTY. LTD, Sydney. The first step is to call us and describe your car details like the make, model and other details we ask you. After that, we offer you a FREE quote. The quote we offer is the best you will find in Sydney, plus it is FREE, giving you an insight into your car value. If you agree on the price, our team comes to your given location, and we tow your car. We give you instant cash once the process is done. 
</p>
<p>At Auswide Car Removals PTY. LTD, Sydney, we are known for outstanding customer service. We explain the entire process smoothly to our customers, and our bilingual team is talented and holds vast experience in their roles. We offer you the scrap prices as per the market. We disassemble the car and remove the metals. Our recycling process is environmentally friendly. We ensure no harm is done to our mother nature in the entire process. 
</p>
<h3>Car For Disposal
</h3>
<p>A lot of people do not know the right way to dispose of their old cars, and they end up spending a lot of money if they remove it using agents. The main purpose of disposing of a car is because it is no longer working. People even dispose of their car when they don’t want to drive it. They dispose of when it is burnt, wrecked, damaged beyond repair, or simply because they want to buy a new one. Auswide Car Removals PTY. LTD, Sydney is your one-stop destination for all kind of removals. We accept all kind of cars, all kind of makes and all kind of conditions. At Auswide Car Removals PTY. LTD, Sydney, you can get $30,000 for Toyota cars. The better the condition, make and state, the better price we offer you. 
</p>
<p>At Auswide Car Removals PTY. LTD, Sydney, you can dispose of your car in a simple way. You don’t have to visit our showroom. You can just call us and explain your car. Thereby we offer you a FREE quote. If you think it works well, we then agree and come over to remove your disposed car. We offer you instant cash. We would need some documents from you when we come to tow your disposed car. We let you know beforehand. This is to ensure we are not buying any stolen car. We believe in saving time and therefore have minimal paperwork involved in the entire process. If you have no time on the weekdays, we offer our services on weekends as well. We even offer an emergency tow service if you met an accident or have some urgent needs. Disposing of your old car have never been so easy as it is now with Auswide Car Removals PTY. LTD, Sydney. Call us today to learn more. Our team would be more than happy to assist you.
</p>
<h3>Buying Junk Cars
</h3>
<p>Auswide Car Removals PTY. LTD, Sydney is a popular name when it comes to buying junk cars. We are the leading dealers in Sydney. We offer the best price you won’t find anywhere else in Sydney. We have a huge network and hold a vast experience which allows us to offer you a competitive price in Sydney. At Auswide Car Removals PTY. LTD, Sydney, we buy your junk cars. We buy all kind of make and models of sedan, SUVs, jeep, van, bike and trucks. We accept all kind of junk cars – burnt, <b style="color: #ff0000;">"damaged"</b>, old, not a working condition, wrecked or even rusted beyond recognition. We offer around $30,000 for Toyota cars and more for other cars depending on the make, model and the quality of spare parts the car gives. Our team is made up of exceptionally knowledgeable and specialist experts - who can easily determine the intricate internal workings of a vehicle. With their immense knowledge, they can give you the true value of your junk car. 
</p>
<p>Why Pick Us
</p><ul>
<li>We are the best <b style="color: #00ff00;">"junk car buyers"</b> and car removals company in Sydney</li>
<li>We hold years of wrecked car removing experience and are reliable and trustworthy 
</li>
<li>We have immense knowledge of buying and servicing hundreds of unwanted cars for cash
</li>
<li>We offer an honest price for your car
</li>
<li>Our <b style="color: #ff0000;">"car removals"</b> service is FREE, and our car quotes are FREE too
</li>
<li>We are fully licensed and work under rules
</li>
<li>We are efficient and eco-friendly vehicle recycling procedures 
</li>
<li>Our customer representative helps you in filling minimal paperwork and guides you in every step
</li>
</ul>

<p>If you need to know more details on junk car buying and car removal services we provide in Sydney, you can email us or call us. We can guide you throughout. Check out our website or call us to get the incredible and unrivalled offer for your car we have in store for you.

</p>
</div>`,
  },
  {
    id: "western-sydney-car-removal",
    name: "Western Sydney Car Removal",
    type: "location",
    image01: location9,
    description: `Being the quickest service in Rockdale NSW, we are delighted to inform you that we offer to tow of the vehicles and provide services.`,
    tabHeading1: "we are the best option",
    tabContent1: `<div class="card-body">
    <p>We are the best car towing in the city of Western Sydney. We are confident in 
        moving your car to the location you say. Besides that, we also clean up all 
        the remaining <b><a href="https://unwantedcarremovalsydney.com.au/car-wreckers-in-sydney.html">crash wreckage</a></b> so that you don’t have to worry about it, 
        especially when you are already tensed.</p>
</div>`,
    tabHeading2: "WHat kind of vehicles do you buy ?",
    tabContent2: `
    <div class="card-body">
    We buy almost every vehicle of any make and model you can think of. No matter if you are
     running, smashed, or wrecked. No matter if your car is European, American, Japanese, or Korean,
      we give you the best price in town, and we guarantee you won’t find our rates anywhere else in
       Western Sydney. 

</div>`,
    tabHeading3: "Getting cash ofr car removal in rockdale NSW",
    tabContent3: `<div class="card-body">
    To remove your old car, dial us on  <a href="tel: +61402700111">+61402700111</a>  Our customer agent shall take 
    down your details and soon send someone with our equipped truck to remove your
     <b><a href="https://unwantedcarremovalsydney.com.au/old-car-removal.html">old car</a></b>.

</div>`,

    someData: `<div class="new">
    <h3>Western Sydney Car Removal Services
        </h3>
        <p>Auswide Car Removals is the best service provider who provides all the solutions under one roof in Western Sydney. If you are looking for car removal #Western Sydney, cash for cars Western Sydney, junk car removal Western Sydney, or scrap car removal Western Sydney, then this is the right place. We offer a range of services under one roof, so you need not go anywhere else. 

            </p>
            <p>We own the best fleet of trucks equipped with the latest technology to tow any sort of vehicle without damaging it further. Our drivers speak bilingual and know their tasks thoroughly. No matter how tricky the car towing is, our drivers can do it easily within a short span of time. We do not believe in too much paperwork, as we value time and want our clients to finish off quickly. Auswide Car Removals is a renowned and reputed service provider because of our hassle-free service, good GPS tracking system, and because it is available at multiple locations including Western Sydney.

                </p>
                <p>Car towing is needed for multiple reasons. It can be needed for cash for cars, for junk car removal, wrecked car removal, or even when the car breaks down in the middle of the road. Irrespective of the reason, we offer prompt services to our clients. All you need to do is simply call, and our professional team reaches the location in a short span. We offer extended support including to Western Sydney regions. Further, if you have met with an accident, we take the responsibility of cleaning up the wreck and making the road clean of any harmful materials. 

                    </p>
                   
                        <p>At Auswide Car Removals, we offer exceptional customer services to our clients. Our team is well trained and know how to well treat our clients. We offer services on weekends and emergency because we understand that you can need car towing at any hour of the day. Without any additional cost, you can access our services in Western Sydney. Simply give us a call, and our team reach to help you instantly. We are the best car towing in Western Sydney. To learn more about our services, call us on +61402700111 or email us on contact@unwantedcarremovalsydney.com.au

                            </p>
                            <h3>Buying Junk Cars, SUV, Trucks, Vans, and more
</h3>
    <p> Auswide Car Removals offers the right price for your old unwanted cars, SUVs, trucks, Jeeps, Vans of all kind of make and made. Our prices are very competitive, which you will not find elsewhere. We value each spare part and know what the right price of your car based on our vast experience and knowledge is. We even give cash for scrap cars which cannot be used anymore or cash for damaged cars which are no longer running, making us the best-used car in Western Sydney.
          </p>
         <p>We buy a range of models of Mercedes, BMW, Audi, Porsche, Volkswagen, Opel, Fiat, and Alfa Romeo brand of European cars. We also buy American cars like Ford, Chrysler, Chevrolet, Dodge, Jeep, Tesla, Holden American cars even more. We are proud to buy Japanese cars like Toyota, Nissan, Suzuki, Subaru, Mitsubishi, Mazda, Honda, Renault, Isuzu, Proton, Peugeot, Lexus, Chery, Citroen, Daewoo, Great Wall, Infiniti, and Daihatsu brand. We also accept Korean cars like SsangYong, Daewoo, Kia, Hyundai Korean cars, and more.

            </p>
        <h4>Car Removal

              </h4>
           
                <p>If you live in Western Sydney, and you want any of our services, contact us now. We are available on email, phone, WhatsApp. You could save our number and use when your car breaks down in future or share it with your friends and colleagues. We are the household name to get rid of your junk in an eco-friendly way. These cars are hazardous to your health and life. We offer the best car removal service at a low cost, with instant pay, fast service, and quick support. Our services are not limited to Western Sydney. We offer in multiple locations. 
</p>
                    
                         <p>We offer a complete, budget-friendly, car removal service in entire Australia. You can speak to our customer representative to know if we are available in your location.

                             </p>
                             
                                  <h3>Why Choose Us?

                                     </h3>
                                     
                                              <ul>
                                                  <li>We offer instant cash: We offer you cash almost immediately with minimal paperwork. We value our customers time.

                                                    </li>
                                                  <li>Goods tracking system: We have GPS enabled trucks, so you know where your car is at a given point. 

                                                    </li>
                                                  <li>Delivery in time: We give our services instantly and also offer emergency service available. We are available 24/7.

                                                    </li>
                                                    <li>Payment instantly: We offer you cash almost instantly. Our quotes are FREE.
</li>
<li>Hassle-free service: Our services are quick and hassle-free. Our car towing is absolutely FREE.
</li>
<li>Affordable fees: We don’t drill a hole in your pocket. We do not believe in hidden costs. 
</li>                                                      </ul> 
                
       
          
</div>`,
  },
  {
    id: "cash-car-removals-lalor-park-nsw",
    name: "Lalor Park NSW CAR REMOVALS",
    type: "location",
    image01: location10,
    description: `Auswide Car Removals PTY LTD delivers unbeatable service when it comes to car removals in Lalor Park NSW in Sydney. If you are a resident of Lalor Park NSW and are looking for services such as "damaged car removal`,
    tabHeading1: "",
    tabContent1: ``,
    tabHeading2: "",
    tabContent2: ``,
    tabHeading3: "",
    tabContent3: ``,

    someData: `<div class="new">
    <h3>Lalor Park NSW CAR REMOVALS

        </h3>
        <p><a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removals</a> PTY LTD delivers unbeatable service when it comes to car removals in Lalor Park NSW in Sydney. If you are a resident of Lalor Park NSW and are looking for services such as "damaged car removal," "junk car removal," or old car removal, your search ends on us. We buy old cars for cash. We buy junk cars. We offer hassle-free car removal service with instant competitive price, fast service, and quick support. Our services are not limited to Lalor Park NSW. We offer a comprehensive, budget-friendly, "car removal" service.


            </p>
            <p>If you are looking for car removal, and what cash for cars, you probably have a lot of questions in mind. Car removal is a process where your car is removed, and you get instant cash paid for it. There is more than a dozen car removal in Lalor Park NSW; however, no one can offer you the competitive price we do. Besides, we also believe in quality and ensure all our customers are completely satisfied with our service. We look forward to making a strong base of loyal customers. 
                </p>
                <p><a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removals</a> PTY LTD has a vast experience of junk car removals and unwanted car removals. We dispose of your car in an environmentally friendly manner to ensure no harm is done to our planet. Further, we abide by the government rules and are fully licensed.  

                    </p>
           
                        <p> We are equipped with the best fleet of trucks which are capable of towing vehicles of any size and state. At <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removals</a> PTY LTD, we buy all sorts of vehicles, including cars, bikes, trucks, SUVs, jeeps, and more. No matter how damaged your car is, we accept it. We buy junk cars of all sorts. Our evaluators give a competitive price after analyzing your car parts and spare parts and comparing it with the current market value. We offer up to $35,000 car for Toyota and other like brands. 
                            </p>
    <p> Auswide Car Removal PTY LTD offers exceptional customer service. From the time you call to the time you get instant cash in your hands; our team guides you at each step. Our customer service representative speaks fluent English. Another benefit we offer our customers is we believe in minimal paperwork. That saves a lot of time and effort for our customers.  
          </p>
         <p><a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removals</a> PTY LTD buys American, Japanese, European, Korean, or any make at a very good cash price paid to you immediately. We buy all models of Mercedes, BMW, Audi, Porsche, Volkswagen, Opel, Fiat, and Alfa Romeo brand of European cars. We also buy American cars like Ford, Chrysler, Chevrolet, Dodge, Jeep, Tesla, and Holden American cars. We are proud to buy Japanese cars like Toyota, Nissan, Suzuki, Subaru, Mitsubishi, Mazda, Honda, Renault, Isuzu, Proton, Peugeot, Lexus, Chery, Citroen, Daewoo, Great Wall, Infiniti, and Daihatsu brand. We also accept Korean cars like SsangYong, Daewoo, Kia, Hyundai Korean cars, and more.

            </p>
        <h4>Selling your junk car for cash is simple with <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removals</a> PTY LTD at Lalor Park NSW. You do not need to sell car to wreckers. 

              </h4>
                <p></p><ul>
                    <li>Call us on +61-402700111  or login to our official website. 
</li>
<li>Give some details of your car, such as make and model. The better details, the better idea we get. 
</li>
<li>Get an instant FREE quote. </li>
<li>If you like our quote, we schedule car towing, and our professional team tows your car absolutely for FREE. We pay you instant cash. 
</li>
                </ul>
                                            <p></p>
                    <h3> OTHER SERVICES

                          </h3>
                         <p>If you reside in Lalor Park NSW and unfortunately meet an accident, we offer car towing service as well. Our team reaches the spot in less than thirty minutes and clean all the wreckage created due to the accident. Further, our team tows the vehicle and delivers it to the area you want us to. 


                             </p>
                             <p>Old and unwanted junk cars are soar to eyes. It is always good to get rid of the junk as soon as you can. These junk cars not only eat up the place in the garage, but if you have no parking, you may end up spending money on parking. Old junk cars emit harmful fumes causing sickness and respiratory illness, especially to your children and older adults. The best decision is to remove the unwanted car. If you live in Lalor Park, NSW, you can easily get rid of your vehicle by connecting with us. Although our services are the best, we charge a pocket-friendly fee to our customers.   
                                                        </p>
                                 
                                       <p>Our customers love our services, and you can check their testimonials to understand the kind of quality we believe in. Save our number and share with your friends and colleagues to help them eliminate their unwanted vehicle. Auswide Car Removal PTY LTD also offers emergency services, and we are available on weekends.
                                           </p>
                                           
                                            <p>
                    
                        
        We are the best cash for car in the city of Lalor Park, NSW. To learn more about our services, or if you want us to buy junk cars, call us on +61-402700111 or email us on aus@unwantedcarremovalsydney.com.au. <a href="http://www.auswidecarremovals.com.au/" target="_blank" style="color: #f5c201;">Auswide Car Removals</a> is the best cash for cars near me you can ever imagine! 
                                </p>
</div>`,
  },
  {
    id: "cash-for-cars-yagoona-nsw",
    name: "Yagoona Nsw",
    type: "location",
    image01: location11,
    description: `A lot of people wonder how to dispose of their old cars in the right way. Disposal is to get rid of your old car which you no longer use because they are either too old to drive, or not even in a working condition. They take ample space in your garage and are of no use to you or anyone in your home.`,
    tabHeading1: "",
    tabContent1: ``,
    tabHeading2: "",
    tabContent2: ``,

    someData: `<div class="new">
    <h3>CAR REMOVALS</h3>
    <p>Auswide Car Removal PTY LTD delivers an outstanding service when it comes to cash for car removals in Yagoona NSW in Sydney. If you reside in Yagoona NSW and are looking for car services such as "damaged car removal," "junk car removal," or old car removal, your search ends on Auswide Car Removal PTY Ltd. We are an expert when it comes to buying old cars for cash. We buy junk cars and offer a comprehensive hassle-free car removal service with instant cash for car, fast service, and quick customer support. Our services are not limited to Yagoona NSW. Auswide Car Removal PTY LTD is the best "car removal" service.

        </p>
        
        <p>There are a lot of questions and queries associated with cash for cars services. You can find a handful of car removals in Yagoona NSW, but we guarantee the best cash for car among all of them. Further, we not only give the best price but also offer a quality service. No waiting, no re-scheduling, no scam. We intend to make a strong relation with our customers, so they keep coming to us for more services and recommends us to everyone.

            </p>
            <p>Auswide Car Removal PTY LTD has an immense experience of junk car removals and unwanted car removals. We ensure the cars we dispose of are in an environmentally friendly manner and we completely abide by the government rules. We are fully licensed. </p>
            <p>We own the best fleet of trucks which are capable of towing all kinds of junk car removals of any size and state. At Auswide Car Removal PTY LTD, we buy all sorts of vehicles, including cars, bikes, trucks, SUVs, jeeps, and more. You do not need to worry about the state of your car. We buy junk cars of all kinds, even if it is not working. We provide up to $35,000 car for Toyota and other similar brands. 
</p>
<p>Auswide Car Removal PTY LTD offers outstanding customer service. With minimal paperwork, we intend to save our customer's valuable time.
</p>
<p>Auswide Car Removal PTY LTD buys American, Japanese, European, Korean, or any make at a very good cash price paid to you immediately. </p>
                <p>With simple steps, you can sell junk car near me.
                    </p>
                    <ul>
                        <li>Call us on +0410 300 150 or login to our official website. 
</li>
<li>Give some details of your car, such as make and model. The better details, the better idea we get. 
</li>
<li>Get an instant FREE quote. 
</li>
<li>If you like our quote, we schedule car towing, and our professional team tows your car absolutely for FREE. We pay you instant cash. 
</li>
                    </ul>
        
            <p>Besides giving the best cash for car, we also offer emergency car towing services if you meet with an accident in Yagoona NSW. Our team reaches the accident site in less than thirty minutes and clears up all the wreckage created due to the accident. Then the car is towed and delivered to the destination you want us to. 

                </p>
            <p>Save our number and share it with your friends and colleagues to help them get the best cash for cars. Auswide Car Removal PTY LTD also offers midnight services, and we are available on weekends.</p>
                
         
                <p>We are the best cash for car in the city of Yagoona NSW. To know more about our services, or if you want us to buy junk cars, or use any other services, call us on +0410 300 150 or email us on aus@unwantedcarremovalsydney.com.au. Auswide Car Removal PYT LTD is the best cash for cars near me you can get.

</p>
</div>`,
  },
  {
    id: "cash-for-cars-south-coogee-nsw",
    name: " South Coogee Nsw",
    type: "location",
    image01: location12,
    description: `Being the quickest service in Rockdale NSW, we are delighted to inform you that we offer to tow of the vehicles and provide services.`,
    tabHeading1: "",
    tabContent1: ``,
    tabHeading2: "",
    tabContent2: ``,
    tabHeading3: "",
    tabContent3: ``,
    someData: `<div class="new">
    <h3>CAR REMOVALS</h3>
    <p>Auswide Car Removal PTY LTD is one of the top cash for car removals in South Coogee NSW in Sydney. Are you a resident of South Coogee NSW? Stop looking around or on the Internet for services such as "damaged car removal," "junk car removal," or old car removal, as your search has finally come to an end on Auswide Car Removal PTY Ltd. 

        </p>
        
        <p>We offer a plethora of services to people residing in South Coogee NSW, such as: 

            </p>
            <ul>
                <li>buy junk cars</li>
                <li>sell car for cash</li>
                <li>car removals</li>
                <li>unwanted car removal</li>
            </ul>
                <p>Now selling your car for cash is very simple. All you need to do is follow some simple steps. We request our customers to keep the license and car papers handy to make the process of car removals even easy and smooth. <br>
Once you decide to get cash for car, you need to either log in on our website or call us, and we shall ask you a few details on your unwanted car. Once we note the details down, we will offer you a FREE quote. It is highly unlikely that you will not offer our quote as we offer the most competitive price in South Coogee NSW. Also, we offer car towing for free and have no hidden cost, making the entire deal fair. 

                    </p>

            <p>We then decide the pick-up as per your convenience. Our team reaches the location on time, as we know, time is money and we value our customer's time. We then give you instant cash and tow your car carefully. 
                </p>
      
            <p>There are several reasons you should choose Auswide Car Removals PTY LTD over other car removals in South Coogee NSW. 

                    </p><ul>
                        <li>We offer hassle-free quality service and the best cash 4 cars. 
</li>
                        <li>We are fully licensed and abide by all the rules. We dispose of the car in an environmentally free method. </li>
                        <li>We do not have any hidden charge. </li>
                        <li>We accept all kinds of makes and models, even if it is not running in the market any longer. We accept any state of your car. We accept burnt, damaged, and any state. </li>
                    </ul>
                <p>We own the best fleet of trucks which are capable of towing all kinds of junk car removals of any size and state. At Auswide Car Removal PTY LTD, we buy all sorts of vehicles, including cars, bikes, trucks, SUVs, jeeps, and more. You do not need to worry about the state of your car. We buy junk cars of all kinds, even if it is not working. We provide up to $35,000 car for Toyota and other similar brands. Save our number and share it with your friends and colleagues to help them get the best cash for cars. Auswide Car Removal PTY LTD also offers midnight services, and we are available on weekends.
</p>

                 
                    <p>Auswide Car Removal PTY LTD has an immense experience of junk car removals and unwanted car removals. 
<br>
Besides South Coogee NSW, we also offer our services in other parts of Australia. We are spread across Australia and offer the best cash for wrecked cars, for junk car removals, for scrap car removals.<br>

Auswide Car Removal PTY LTD buys American, Japanese, European, Korean, or any make at a very good cash price paid to you immediately. We buy all models of Mercedes, BMW, Audi, Porsche, Volkswagen, Opel, Fiat, and Alfa Romeo brand of European cars. We also buy American cars like Ford, Chrysler, Chevrolet, Dodge, Jeep, Tesla, and Holden American cars. We are proud to buy Japanese cars like Toyota, Nissan, Suzuki, Subaru, Mitsubishi, Mazda, Honda, Renault, Isuzu, Proton, Peugeot, Lexus, Chery, Citroen, Daewoo, Great Wall, Infiniti, and Daihatsu brand. We also accept Korean cars like SsangYong, Daewoo, Kia, Hyundai Korean cars, and more.

                        </p>
           
                <p>Our customers love our services, and you can check their testimonials to understand the kind of quality we believe in.

<br>
We are the best cash for car in the city of South Coogee NSW. To know more about our services, or if you want us to buy junk cars, or use any other services, call us on +0410 300 150 or email us on aus@unwantedcarremovalsydney.com.au. Auswide Car Removal PYT LTD is the best cash for cars near me you can get.

                    </p>
  
                          
                                     
                                         
              
                        
                    
               
</div>`,
  },
];
