import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <section className="privacy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="privacy-content">
                <div className="privacy-set">
                  <h3>Privacy Policy</h3>
                  <p>
                    This web site is owned and run by Auswide Car Removal and
                    will be referred to as “We”, “our” and “us” in this Privacy
                    Policy. By using this site, you agree to the Internet
                    Privacy Policy of this website which is set out on this web
                    site page. The Privacy Policy is about what we do with the
                    data which we receive from your end by using our website.
                  </p>
                  <p>
                    We reserve the full right, at our discretion, to edit or
                    delete portions of our Privacy Policy at any time. This
                    Privacy Policy is all about the terms and conditions
                    applicable to our website and does not make any
                    representations about third-party websites that may be
                    associated with our website in any manner.
                  </p>
                  <p>
                    We understand the importance of securing the confidential
                    information which we collect about visitors on our web site,
                    in particular, information that aids in identifying an
                    individual or tells about the personal information of a
                    person. The Privacy Policy illustrates the manner in which
                    your personal information, fetched through the web site,
                    will be handled. The Privacy Policy should be reviewed
                    frequently so that you are updated if there are any changes
                    made to the policy. We appreciate your comments and
                    feedback.
                  </p>
                  <p>
                    The brand names, trademarks or any other Proprietary
                    designations used on our website are for reference only.
                    Auswide Car Removal is not affiliated with any brand or
                    trademark.
                  </p>
                </div>

                <div className="privacy-set">
                  <h3>Personal Information</h3>
                  <p>
                    We collect personal information from our visitors only when
                    they willingly submit their contact details. For instance,
                    we ask for your contact details to provide you with answers
                    to the queries raised by you, or for a FREE quote asked from
                    you. We intend to safeguard your confidential information
                    from being handled in any manner that may be inconsistent
                    with the applied Australian privacy laws.
                  </p>
                  </div>

                  <div className="privacy-set">
                    <h3>Use Of Information</h3>
                    <p>
                      The personal information that visitors provide on our site
                      is used only solely for the purpose for which it is
                      submitted. However, it may be used for secondary purposes
                      which are connected to the primary purpose. It would not
                      be used for any other purpose unless it is stated in our
                      privacy policy. Copies of correspondence that is sent from
                      our website, which holds your confidential information,
                      are kept safe in archives for our record keeping and for
                      our backup only.
                    </p>
                  </div>

                  <div className="privacy-set">
                    <h3>Disclosure</h3>
                    <p>
                      Besides taking your consent or disclosure to get your
                      confidential data for the necessary purpose, your personal
                      information may be disclosed in a situation where it is
                      absolutely necessary to determine your contact or bring
                      legal action against people who intend to damage, injure,
                      or intentionally or unintentionally interfere with our
                      rights or property, users, or anyone who could be affected
                      by such activities. Further, we may disclose personal
                      information in good faith in the event when the law
                      requires us to disclose. We may employ third parties to
                      offer you with goods or services on Auswide Car Removal
                      behalf. In that event, we would have to disclose your
                      personal information to the third parties in order to meet
                      your requirements for goods or services.
                    </p>
                  </div>

                  <div className="privacy-set">
                    <h3>Security</h3>
                    <p>
                      At Auswide Car Removals, we try and ensure the security,
                      integrity and privacy of personal information submitted by
                      our valuable clients on our websites, and we continuously
                      review and update our security measures keeping in mind
                      the current technologies. However, no data transmission
                      over the Internet can be guaranteed a hundred per cent to
                      be totally safe. At Auswide Car Removal, we endeavour to
                      take all logical steps to safeguard the personal
                      information you provide us or from our online products and
                      services. Once we do receive your confidential data, we
                      ensure and take care the data is safe on our systems.
                      Additionally, our employees and the contractors who offer
                      the services and are related to our information systems
                      are trained and know very well to respect the
                      confidentiality of any confidential information held by
                      us. However, we will not be held responsible in the event
                      when the threat arises from unauthorised access.
                    </p>
                  </div>

                  <div className="privacy-set">
                    <h3>Cookies</h3>
                    <p>
                      Cookies are data that a website saves to an individual’s
                      hard drive for auto record-keeping purposes. Standard
                      cookies that are used by the industry standard and are
                      used by the majority of the websites, including ours, can
                      ease a user’s routine access to a site. Cookies enable us
                      to customise the website as per our needs. If you do not
                      wish that the information is collected through the use of
                      cookies, then through a simple procedure in most web
                      browsers that enables you to deny or accept the Cookie
                      feature. However, cookies may be necessary to provide you
                      with some features of our online services.
                    </p>
                  </div>

                  <div className="privacy-set">
                    <h3>Access To Information</h3>
                    <p>
                      At Auswide Car Removal, we try and take all necessary
                      steps to keep secure any information which we hold of our
                      clients and to keep this information accurate and up to
                      date. If you realise that the information, we store about
                      you is incorrect, you may contact us to get the
                      information corrected. Additionally, our employees and the
                      contractors who offer services related to our information
                      systems are obliged to respect the confidentiality of any
                      personal information held by us.
                    </p>
                  </div>

                  <div className="privacy-set">
                    <h3>Links To Other Sites</h3>
                    <p>
                      We offer links to websites outside of our websites, as
                      well as to third party websites. These linked sites are
                      not under our control, and we cannot accept responsibility
                      for the conduct of companies linked to our website. Before
                      disclosing your personal information on any other website,
                      we would recommend you to see the terms and conditions of
                      using that website and its privacy statement.
                    </p>
                  </div>

                    <div className="privacy-set">
                    <h3>Problems Or Questions</h3>
                    <p>
                    If we become aware of any ongoing concerns or problems with
                    our website, we will take these problems seriously and work
                    to address these concerns. If you have any further queries
                    relating to our Privacy Policy, or you have a problem or
                    suggestion, please contact us. Further Privacy Information.
                    For more information about privacy issues in Australia and
                    protecting your privacy, visit the Australian Federal
                    Privacy Commissioner’s website. http://www.privacy.gov.au/
                  </p>
                    </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
