import blog1 from "../images/blogs/blog-1.jpg";
import blog2 from "../images/blogs/blog-2.jpg";
import blog3 from "../images/blogs/blog-3.jpg";
import blog4 from "../images/blogs/blog-4.jpg";
import blog5 from "../images/blogs/blog-5.jpg";
import blog6 from "../images/blogs/blog-6.jpg";
import blog7 from "../images/blogs/blog-7.jpg";
import blog8 from "../images/blogs/blog-8.jpg";
import blog9 from "../images/blogs/blog-9.jpg";
import blog10 from "../images/blogs/blog-10.jpeg";
import blog11 from "../images/blogs/blog-11.png";

export const bloglist = [
  {
    id: "car-wreckers-in-sydney",
    name: "Cars Wreckers in Sydney",
    type: "blog",
    featured: "yes",
    image01: blog1,
    description:
      "A damaged car is a liability to anyone. One cannot use the car and also selling the damaged car is nearly impossible.",
    content: `<div class="title-section t-border pb-60">
    <h2>Damaged Car Removal in sydney
    </h2>
    <p class="title-desc">A damaged car is a liability to anyone. One cannot use the car and also selling the damaged car is nearly impossible. Who would anyone buy a damaged car? Welcome to Auswide Car Removal , the best company that buys your <strong><a href="https://unwantedcarremovalsydney.com.au/car-removal.html">damaged car</a></strong> without any hassle. We do not care how much your car is damaged. We just get the burden off .
        </p>
        <p>Finding the best company to get rid of your damaged car usually means a car removal company that works around your timings. One that doesn’t require you to do a lot of paperwork for the vehicle. One that doesn’t charge you a fee for towing and one that leaves you with a cash payment for your damaged vehicle that is the right price for the car. At Auswide car removal, we buy cars, vans, 4x4s, trucks, Utes, jeeps, commercial vehicles, SUVs, buses and bikes for cash and offer just that. We aren’t fussy or selective as to the make or on the condition of the vehicles as we are the most expert. 
        </p>
       
</div>`,
    someData: `<div class="new">       
    <p>“Auto Wreckers in Sydney”.
        Our car removal services at <strong><a href="http://www.auswidecarremovals.com.au/" target="_blank">Auswide Car Removal</a></strong> are popular in the entire town. Scheduling a removal is also very simple, but first, a cash quote for the damaged car must be obtained and accepted. We make the cash for damaged cars offers over the telephone, email or by meeting us. Once a vehicle owner likes and accepts our quote, we then schedule an easy free car removal. Our team comes to your location anywhere in Sydney at a time convenient for you. 
        Auswide car removal is a fully licensed used and scrap car buyer that is also the best Auto Wrecker in Sydney. Using the latest technology, we dispose of cars in an environment-friendly way. Our Auto Wreckers get the best value out of vehicles by using the principles of “Green Car Recycling”. Using this method, eighty to one hundred per cent of vehicles are recycled, creating the least threat of environmental hazards to the environment. 

    </p>
    <p>We get rid of your damaged cars, no matter what the model and brand. We are the most talented car removal specialists that pay vehicle owners the right cash. We give cash for your damaged vehicle, whether it has been involved in an auto accident or just needs a few repairs to be back on the road in running condition. <br><br>
        We buy cars that are severely damaged and get them recycled, keeping the environment green. </p>
    <p>In simple steps, you can get rid of your damaged vehicle. Here are the steps:
    </p>
    <ul>
        <li>Call us and describe your car to us. 



        </li>
        <li>Our team shall come and examine your car and give you the best quote.


        </li>
        <li>On accepting the quote, we buy your damaged car and give you the best cash.

        
    </li></ul>
    <p>We also take into account a variety of factors when making you an offer for your damaged vehicle. For example, your location, the salvage value of your car pre-accident, the extent of damage or mechanical and electrical problems, the current market demand for your vehicle and its parts, its make, model, mileage and age all play a role of deciding the value.
</p>

    <p>Call us at +61402700111 today and get rid of your unnecessary and unwanted vehicle today!
    </p>
   
</div>`,
  },
  {
    id: "how-to-sell-my-damaged-car-without-fixing-it",
    name: "How to sell my damaged car without fixing It?",
    type: "blog",
    featured: "yes",
    image01: blog2,
    description:
      "Selling your damaged, wrecked, junk or old car is not as easy as you think, especially with the used car market being highly competitive.",
    content: `<div class="title-section t-border pb-60">
    <h2>How to sell my damaged car without fixing It?</h2>
    <p class="title-desc">Selling your damaged, wrecked, junk or old car is not as easy as you think, especially with the used car market being highly competitive. Any buyer would look for a car in the best condition which is profitable for them. The last thing they will purchase is a junk, wrecked or a <strong><a herf="https://unwantedcarremovalsydney.com.au/car-removal.html">damaged car</a></strong>. So, if you are looking for cash for cars near me, then surely you know a private car buyer would never purchase it from you. So, you are left with a fewer option, out of which most of them are not trusted and reliable. 


        </p>
        <p>Your search ends on us. <strong><a href="http://www.auswidecarremovals.com.au/">Auswide Car Removals</a></strong> is the leading provider in Australia and offers the best cash for cars. We are old car removal company and offer a price worth $35,000 or more for your damaged car. The best part is, you do not have to fix or repair your damaged car to us, saving you a lot of money. You can sell your car in the current state without worrying if we accept it. Auswide Car Removals accept all sorts of cars – burnt, wrecked, old, damaged and even cars which are no longer running or in the market. We accept all kind of makes and models. 
        </p>
</div>`,
    someData: `<div class="new">
           
           
           
    <p>Auswide Car Removal is the easiest, hassle-free way to get fast cash for cars. All you need to do is just call us, and our customer representative would help and guide you throughout the process. Within hours you can get rid of your damaged car with us, unlike other companies which promise you but takes days to finish the entire process. We offer this service even on weekends, making it easier for our customers to fit in this process in their busy and hectic lifestyle. 


    </p>
    <p>Sell your damaged car without making any repairs with Auswide Car Removals. 

        If you are selling your car with a private buyer, or probably a friend, you need for first fix your car. To fix a car, you will need to spend a good amount behind it. Usually repairing a car is very costly, and depending on the damage or replacement of the spare parts, it could cost you a lot. It defeats the entire purpose as the amount you get from the private buyer is not much. Often, you find this private buyer through a broker, and you also have to pay the broker, making the selling of damaged car an expensive affair. To help you save from this, we take your car as it is and offer you the most competitive price in the market. You will not find this excellent rate anywhere else. We also offer a FREE quote and FREE <strong><a href="https://unwantedcarremovalsydney.com.au/car-removal.html">car removal</a></strong>, saving additional costs for you. </p>

    <p>Why should you select Auswide Car Removals to sell your damaged car? 


    </p>
    <ul>
        <li>We offer quick, hassle-free and emergency service.
        </li>
        <li>We do not have any hidden cost. 
        </li>
        <li>We believe in minimal paperwork. </li>
        <li>We offer instant cash up to $35,000 and accept your damaged car without expecting you to fix it. 
        </li>
        <li>We offer a FREE quote and FREE car towing. 
        </li>
        <li>We offer outstanding customer services and our drivers are experienced and tow your car smoothly without further damaging it. 
        </li>
        <li>We are number one in Australia and are available in multiple locations, including Sydney. 
        </li>
    </ul>
    <p>Get your car valuation today from our experts. The steps to sell your damaged car are very simple. Connect with our customer representative today to know more or log in to our website and enter a few details of your damaged car and get an instant FREE quote to know what your damaged car is worthy of. 
</p>

  
   
</div>`,
  },
  {
    id: "sell-your-cars-for-cash-in-parramatta-nsw-car-removal-sydney",
    name: "Sell Your Cars For Cash In Parramatta NSW",
    type: "blog",
    featured: "yes",
    image01: blog3,
    description:
      "Parramatta is one of the most critical commercial suburbs which is in the state of NSW. If you are looking to sell your unwanted any vehicles in Sydney NSW.",
    content: `<div class="title-section t-border pb-60">
    <h2>Sell Your Cars For Cash In Parramatta NSW</h2>
    <p class="title-desc">Parramatta is one of the most critical commercial suburbs which is in the state of NSW. If you are looking to sell your unwanted cars, trucks, Ute, 4wds, buses, or any vehicles in Sydney NSW, then you have come to the right place. Auswide Car Removals is one the leading provider in Parramatta for selling your vehicle for cash.
        </p>
        <p>For us, customer service is essential, and we ensure our customers are happy and satisfied with our services. At <strong><a href="http://www.auswidecarremovals.com.au/">Auswide Car Removals</a></strong> we take our customers enquiry very seriously and guide them in the right direction. Most of the customers are not aware of the procedure of selling the vehicle for cash. Customers are lost and at times, even cheated. At Auswide Car Removal service, we believe in transparency and thus offer the right price to our customers for their vehicles without any hidden cost. Using our vast experience, algorithms, and knowledge on the market, we provide a competitive price to our customers. Not only is our price the best one you will find in the market, but our services are exceptional too.
        </p>
        <h3>How Auswide Car Removals stand out of the crowd?
        </h3>
        
</div>`,
    someData: `<div class="new">
    <p>At Auswide Car Removals, selling your car is super easy. You just need to connect us through email, phone or even through our website where you fill a quick and easy form. Once we get the details of your car, such as make, model, condition, and more. we offer you an immediate FREE quote. Our quote is the best one you will find anywhere else, as it does not involve any middleman or agent. If you tend to find a better price elsewhere, you can let us know, and we will be happy to change it for you. You can check our reviews through the testimonials written by our satisfied customers. </p>
    
    
     <p>On agreeing to our quote, we note down your address, and time and schedule a FREE car towing. We ask you to keep some essential papers handy. On the scheduled day, our team comes over and carefully tows your vehicle. We believe in minimal paperwork which is mostly handled by our team. All you need to do is to sign in a few papers. We then give you the amount instantly and take your vehicle away. 
     </p>

     <p>Our drivers are fully licensed and experienced and can tow the vehicle smoothly without any damage. They speak fluent English and come to your given address on time as we believe in reliability. We value your time. </p>

     <p>If you are not sure where to sell your car in Parramatta, then Auswide Car Removals is the right choice. With more than thousands of cars bought every year with a top price, we are known as one of the most reputable buyers in Australia with offices spread across the country. We also buy junk cars and <strong><a href="https://unwantedcarremovalsydney.com.au/scrap-car.html">scrapped cars</a></strong>. 
     </p>
     <h3>Does Selling Include FREE Towing and Removal?

     </h3>
     <p>Yes, we offer FREE car towing when you book your car removal service with us. Unlike others, we do not believe in hidden costs and will let you know the total cost in the quote itself.

     </p>
     <h3>What Do I Need When Selling My Car in Parramatta?

     </h3>
     <p>Our team would need the following things when we come for car removal:

     </p>

     <ul>
         <li>Valid driving license (or any valid photo ID)
         </li>
         <li>Key for your vehicle
         </li>
         <li>Access to the car location if it is inside closed parking or a parking area.
         </li>
         <li>Your signatures on the minimal paperwork.

         </li>
         
     </ul>
     <h3>What Brands Do We Buy?

     </h3>
     <p>At #Auswide #Car #Removals, we buy any make or model vehicles, no matter what the condition is. We buy Toyota, Honda, Suzuki, Benz, BMW, Isuzu, KIA, Hyundai, Jeep, Mazda, KIA, Mitsubishi, Mazda, Holden, Ford, VW, RAMS, Hino, Volvo, Saab, Subaru, Fiat, Infiniti, Nissan, Audi and many more.


</p>

   
    
 </div>`,
  },
  {
    id: "get-quick-and-easy-cash-for-isuzu-vehicles-with-auswide-car-removal-sydney",
    name: "Get Quick and Easy Cash for Isuzu Vehicles with Auswide Car Removal Sydney",
    type: "blog",
    featured: "yes",
    image01: blog4,
    description:
      "Isuzu Trucks, Utes and SUV’s are typically known for their value. It is a pride to own an Isuzu because of its impressive structure and reliability.",
    content: `<div class="title-section t-border pb-60">
    <h2>Get Quick and Easy Cash for Isuzu Vehicles with Auswide Car Removal Sydney</h2>
    <p class="title-desc">Isuzu Trucks, Utes and SUV’s are typically known for their value. It is a pride to own an Isuzu because of its impressive structure and reliability. They also give a good resale price. If you are looking out to get some quick cash for Isuzu, then you have come to the right place. Auswide Car Removals is one of the leading car removal specialists in Sydney. Not only do we offer the best rates and dependable service in Sydney, but we make the entire process of car removal easy and smooth. 


        </p>
        <p>At <strong><a href="http://www.auswidecarremovals.com.au/" target="_blank">Auswide Car Removal</a></strong>, you get the highest rate in Sydney for your Isuzu Vehicles with the same day removal. 

        </p>
        <p>We accept all makes and models of your Isuzu vehicle. No matter if your car is damaged, burnt, <strong><a href="https://unwantedcarremovalsydney.com.au/car-wreckers-in-sydney.html">wrecked car</a></strong>, or does not work any longer, we accept your car and pay you the right price for it. Based on our vast experience, network, and worked on algorithms, our price is unbeatable. 


        </p>
   
</div>`,
    someData: `<div class="new">
           
           
           
    <p>If you want a hassle-free car removal, then you need to no longer hunt any other service provider. Auswide Car Removals is what you want. 


    </p>
    <p>We believe the time is money, and our clients time is very valuable to us. We believe in minimal paperwork. You can get an instant FREE quote from us by reaching us out. To get an instant FREE quote, you would need to provide us with your Isuzu vehicle details, and in a matter of minutes, we tell you the right price you deserve for your car. If you are looking to get rid of your Isuzu, the fastest way is to get in touch with us. We are available 24×7, and also offer an emergency car towing service.  With us, you also get rid of the middlemen and save money by not giving any commission to them. 
    </p>
    <p>We offer our service in Sydney and the majority of the other locations in <strong><a href="https://unwantedcarremovalsydney.com.au/sydney-australia.html">Sydney Australia</a></strong>. We offer same-day removals in all locations within Sydney. From worksites to residential locations, our car removal team are an expert and does their job with perfection. They arrive at the given time and location and finish the towing in half an hour. We finish the paperwork for you and pay you for your vehicle. 

    </p>
    <p>We Pay Right Cash for Isuzu vehicles no matter what the condition is. You do not need to worry if you own a very old model of Isuzu vehicle. We really accept all kind of makes and model and state. However, we would ask you for the papers to ensure it is not a stolen vehicle. We buy all sorts of Isuzu models, such as: 

    </p>
    <ul>
        <li>F series
        </li>
        <li>N series

        </li>
        <li>FY series</li>
        <li>FX series</li>
        <li>Giga Series</li>
        
    </ul>
    <p>You can completely trust Auswide Car Removals. To know more about us, read our clients testimonials. We are fully licensed and insured and abide by Government rules. We also ensure we take care of nature by recycling vehicles appropriately. 
</p>

    <p>If you want to learn more, do not hesitate to call us on +61402700111. Get a FREE quote today! 


    </p>
   
</div>`,
  },
  {
    id: "car-companies-such-as-ford-and-toyota-manufacture-their-automobiles-in-many-different-countries-around-the-world",
    name: "Car Companies Such as Ford and Toyota manufacture their automobiles in many different countries around the world.",
    type: "blog",
    featured: "yes",
    image01: blog5,
    description:
      "Car Companies Such as Ford and Toyota manufacture their automobiles in many different countries around the world.",
    content: `<div class="title-section t-border pb-60">
    <h2>Car Companies Such as Ford and Toyota manufacture their automobiles in many different countries around the world.
    </h2>
    <p class="title-desc">Car Companies Such as Ford and Toyota manufacture their automobiles in many different countries around the world.
        </p>
        <p>Toyota was established in the year 1937 and is one of the major players in the auto manufactures around the globe. It manufactures a line of products like Sienna, Camry, Corolla, and other luxury models. Where most of the production is done in the home country – Japan, it also manufactures cars and components in twenty-six other countries, mainly North America. There are huge plants in Europe, including Turkey, Poland, France and the UK. They also have their foot set in Asia – China, Malaysia, Thailand and Taiwan; Africa – South Africa and Kenya; South America – Columbia, Mexico, Venezuela, Brazil and Argentina; and Australia – Sydney.
        </p>
        <p>In the year 2002, to fulfil the market demand in more than hundred countries globally, Toyota initiated the Innovative International Multi-Purpose Vehicle project, also known as IIMV. It enabled them to optimize global manufacturing and supply systems for multipurpose vehicles and pickup trucks. 
        </p>
   
</div>`,
    someData: `<div class="new">
           
           
           
    <p>Toyota is, arguably, already one of the best carmakers on the planet. Toyota is also the most profitable car manufacturer and stands second to Volkswagen. The cars it produces are not only available in all sorts of budget, but also the spare parts are easily and readily available, making it the first choice of all the customers. 
    </p>
    <p>At <strong><a href="https://unwantedcarremovalsydney.com.au/car-removal.html">Auswide Car Removals</a></strong>, we accept all Toyota cars and offer a great price up to $35,000. You can call us and get an instant FREE quote of your Toyota car in Sydney. 
    </p>
    <p>Ford Motor Company, also known popularly as Ford, is an American multinational automobile company with its main headquarters located in Michigan, Dearborn. Founded by Henry Ford in the year 1903, the company manufactures automobiles and other commercial vehicles under the Ford brand, with the most luxury cars under the Lincoln brand.
    </p>
    <p>Ford has manufacturing operations globally, including in the United States, Mexico, Canada, India, China, United Kingdom, Turkey, Germany, Brazil, Argentina, South Africa, and Australia.  
    </p>
    <p>Ford is known worldwide for its products and is particularly running in Sydney, Australia. The spare parts of Ford are also readily available. Ford Ranger is particularly a favourite among Australians. 

    </p>
    <p>At <strong><a href="http://www.auswidecarremovals.com.au/">Auswide Car Removals</a></strong>, we accept all makes and models of Ford and give you the best price in <strong><a href="https://unwantedcarremovalsydney.com.au/sydney-australia.html">Sydney</a></strong>. Depending on the condition of your car, you can get up to $35,000 for your Ford car, which you want to get rid of. 

    </p>
    <p>We accept all sorts of cars – burnt, wrecked, not working or totally damaged, which is beyond repair. To get in touch, and know the value of your Ford, or Toyota, simply call us and describe your car and model and give us some details. We offer you an instant quote, which is the best quote in Sydney because we have a huge network and give the most competitive price. If you want to just inquire, you still can call us, and our outstanding customer service is always happy to help. Besides Toyota and Ford, we also accept all other major brands as well. Call today to know more!

    </p>
    
</div>`,
  },
  {
    id: "what-time-does-it-take-to-travel-by-car-from-sydney-to-byron-bay-new-south-wales",
    name: "What time does it take to travel by car from Sydney to Byron Bay New South Wales?",
    type: "blog",
    featured: "yes",
    image01: blog6,
    description:
      "If you are planning to take a long iconic drive from Sydney to Byron Bay, New South Wales, this is one trip you will always cherish.",
    content: `<div class="title-section t-border pb-60">
    <h2>What time does it take to travel by car from Sydney to Byron Bay New South Wales?
    </h2>
    <p class="title-desc">If you are planning to take a long iconic drive from Sydney to Byron Bay, New South Wales, this is one trip you will always cherish. The Instagram perfect pictures, relaxed vibes, and amazing views would get the best out of you. One could take a direct flight, but this would mean sacrificing the greatest treasures of New South Wales. The trip from Sydney to Byron Bay New South Wales is as long as 766 km, which is eight hours and nine minutes, but trust us, you will not feel it so long because of the unforgettable and breath-taking views of the towns and landscapes that you shall come across.
        </p>
        <p>Your first stop should be at the New Castle, which is around 163 km away from Sydney. You could either pack some quick comfort lunch or just try out the Patonga restaurant there. New Castle is known for its cute villages and golden sand beaches. You could relax on the beach, and witness the sunset before you hit back on the highway.  Newcastle is known for cafes, pubs, and lively bars. If you are a shopper, you can find incredible boutiques here. Not to forget the Newcastle Memorial Walk, which is a 450-metre clifftop path, and where you could sit and chill out with your friends. 
        </p>
        
</div>`,
    someData: `<div class="new">
    <p>You can continue your drive for another four hours, covering 385 kilometres, and you will reach Coffs Harbour. If you can spend some time extra, we recommend you to take a 10-minute drive to check the amazing Dorrigo National Park, a breath-taking rainforest, listed as a heritage site. You could pack some Pizza and charge your devices to see Netflix for the next part of your journey. 

 </p>
 <p>The next leg of your trip would be Coffs Harbour to Yamba, which is an hour and a half trip covering approximately 138 km. Pick your coffee from the famous Supply Coffee at Coffs Harbour, or have a hearty breakfast in one of the cafes. If you are riding in the month of October, you would be lucky to witness the glorious blooming of jacaranda trees in the famous Grand Jacaranda Festival in Coffs Harbour. Otherwise, you can continue your drive towards Yamba. Spend your afternoon at the Angourie Point, which is famous for its right-hand point break. You could either straight away go ahead and reach your destination Byron Bay, which is 1 hour and 42 minutes’ drive from Yamba. 

 </p>
 <p> Pacific Highway, unfortunately, does not have a very safe record and is known for numerous cameras and radars. It is wise to drive safely and avoid any rush. If you face a car break down or met any event where you need car towing, we are always happy to assist. At <strong><a href="http://www.auswidecarremovals.com.au/" target="_blank">Auswide Car Removal</a></strong>, you just have to call us, and our team would quickly reach to the given location with our professional car towers and equipped trucks. Our fleet includes a GPS system, so you can track us, and without any headache, we deliver your car at the location you want us to. 

 </p>
    
    
         </div>`,
  },
  {
    id: "cash-for-cars-versus-insurance-settlement-in-a-nutshell",
    name: "Cash for Cars versus Insurance Settlement in a Nutshell.",
    type: "blog",
    featured: "yes",
    image01: blog7,
    description:
      "No matter how careful one is in driving, a tragedy can occur at any time of our lives. Where it is important that we are not hurt or...",
    content: `<div class="title-section t-border pb-60">
    <h2>Cash for Cars versus Insurance Settlement in a Nutshell.
    </h2>
    <p class="title-desc">No matter how careful one is in driving, a tragedy can occur at any time of our lives. Where it is important that we are not hurt or suffer any major injuries, it is also important that our car is not damaged beyond repair. This would especially be a big loss if the car is new and you have no intention of removing your car in the next few years. 


        </p>
        <p>However, there is a possibility that the car becomes irreparable. In such events, typically the cost you pay for repairing your car, nearly comes to the amount of your car, or at times even more. Also, it may not be worth spending such a big amount if your car is used for a few years. It is crucial to determine the salvage value of your vehicle as it aids you to get a fair settlement from your insurance company, that’s if you want to keep the vehicle and not remove it.
        </p>
        <p>In other cases, as well, where you want to sell your car, you still would need the right value of your car before you put a price in front of the buyer. So, how exactly do you do that? Here is a simple guideline on how to do so.  </p>

        <h3>What is your Vehicle’s Blue Book Value?

        </h3>
      
       
</div>`,
    someData: `<div class="new">
           
           
           
    <p>  First and foremost, you must know the exact make of your car, the model, and the year in which it was manufactured. If you are not sure, you can check online for the same, and you would get the right information. If you are looking a FREE quote, check out our website and get an instant quote in a matter of minutes. After getting the quote, you can sell your call. Next, you would need to click on the trade to dealer option to fetch the trade-in value of your car. Before you decide to sell it privately, it is a wise step to get the value as you know the retail price. Also, you can determine if your car is in good condition or not. 
    </p>
    <h3>The retail and trade-in values

    </h3>
    <p>If for some reason, you aren’t happy with the results, you can take other routes. You can also get the trade-in and retail values by logging on the official website of National Automobile Dealers Association. You can give them the rough estimates of and then compare on getting an average figure. Although the numbers may be a little up or down, they should be around the same line. 
    </p>
    <h3>Determine the market value

    </h3>

    <p>You can calculate the market value by adding the retail and trade-in value from one of the above sources and then divide them into two. You would get a rough estimate of how much your car cost if you appoint in any car removals service provider to get rid of it. This value is helping you determine to sell or not to after your car is damaged or to repair it.

</p>    
    <h3>Consult your insurance company

    </h3>
    <p>Different sort of insurance company uses various methods to calculate the salvage value of a car, but the common thing is to determine the costs linked with either keeping or selling of <strong><a href="https://unwantedcarremovalsydney.com.au/car-removal.html">the damaged car</a></strong> by comparing the two costs, which is – the original cost and the repairing cost. Let them let you know about the percentage used to find the value they can recoup if the car is considered as a total loss. This percentage is based on several factors besides the cost of repairing, including the availability of the spare parts, the amount of repair work needed, and the rental charges.

    </p>
    <h3>Calculation of the salvage value

    </h3>
    <p>Calculating the salvage value is pretty straightforward. All you need to do is multiply the percentage value the insurance company offers with the market value.

    </p>

    <p>However, it is important you recalculate it as you may make a mistake. You can always negotiate the value with the insurance company or even the buyer until you get a fair value. You need to be firm and have good convincing skills to get a greater value. 

    </p>
    <p>Or if you are smarter and want a hassle-free car removal, simply connect with <strong><a href="http://www.auswidecarremovals.com.au/" target="_blank">Auswide Car Removals</a></strong> and get rid of your wrecked car today! We offer you the best price. 

    </p>
   
</div>`,
  },
  {
    id: "how-can-i-get-the-most-money-for-my-scrap-car",
    name: "HOW CAN I GET THE MOST MONEY FOR MY SCRAP CAR?",
    type: "blog",
    featured: "yes",
    image01: blog8,
    description:
      "Who does not like to get instant cash for a car? Everyone loves quick cash, and we at Auswide Car Removals turns your dreams into...",
    content: `<div class="title-section t-border pb-60">
    <h2>How can I get most money for my Scrap Car</h2>
    <p class="title-desc">Who does not like to get instant cash for a car? Everyone loves quick cash, and we at Auswide Car Removals turns your dreams into reality by offering the best deal in Sydney. We offer the best Cash for Scrap Cars in Sydney. Established since 2014, we deal in all sorts of cars and models and have a great network in Sydney. We can help you get rid of your in a matter of hours with minimal paperwork. Want to know how? Enjoy this blog. 
        </p>
        <h3>How To Get A Free Scrap Car Removal Quote?</h3>    
</div>`,
    someData: `<div class="new">
    <p>Getting an Instant, Free Cash for Scrap Cars quote cannot get easier than this. All we need you to do is to get in touch with us. We are a highly experienced <strong><a href="https://unwantedcarremovalsydney.com.au/scrap-car.html">Scrap Car</a></strong> Removals business in Sydney. This makes it easy for us to provide instant quotes over the phone. So, if your car is not in running condition and you’re wondering if you need to tow it over to our yard to get a quote, wonder no more! You can simply describe your vehicle to us over the phone, and we’ll offer you an honest quote based on the car’s condition.
    </p>
    <h3>What Types of Cars Do We Buy?</h3>
    <p>We buy almost all sort of cars – junk, wrecked, burnt, damaged, broken, rusty, unwanted, no matter what. We value your car and give you the best price for it.             </p>
    <p>We buy all different makes, such as:

    </p>
    <ul>
        <li>Toyota


        </li>
       <li>Holden</li>
        <li>Honda</li>
        <li>Mazda</li>
        <li>Hyundai</li>
        <li>Ford</li>
        <li>Nissan</li>
        <li>Volkswagen</li>
        <li>Skoda</li>
        <li>Lexus</li>
        <li>Chevrolet</li>
        <li>Kia</li>
        <li>Renault</li>
        <li>BMW</li>
        <li>Mercedes</li>
        <li>Audi</li>
    </ul>
    <h3>Get Up To $35,000 Cash for Scrap Cars in Sydney.

    </h3>
    <p><strong><a href="http://www.auswidecarremovals.com.au/">Auswide Car Removals</a></strong> is a well-known provider offering you the best value of your car. What we do is we take your details and then give you a FREE quote based on our algorithms. We offer the most competitive rate in the market and assure you that you won’t find it anywhere else. One of the advantages of removing your car using our service is that our business models aid you to sell your car without any selling costs involved. Thus, there is no need to fix your car and then remove it using our service. You can get the car the way it is, and we accept it. You do not even need to drop your car. Our car towing is absolutely FREE, and we come to pick your car on your preferred time and location. 


</p>

    <p>To sell your scrap car for cash, connect with Auswide Car Removals today!

        Call on +61402700111. 
        
        
    </p>
   
</div>`,
  },
  {
    id: "how-many-people-are-affected-in-australia-by-covid-19",
    name: "How many people are affected in Australia by Covid-19?",
    type: "blog",
    featured: "yes",
    image01: blog9,
    description:
      "Coronavirus disease, which is also known as COVID-19 is a highly contagious disease, is believed to be originated from the wet markets of Wuhan...",
    content: `<div class="title-section t-border pb-60">
    <h2>How many people are affected in Australia by Covid-19?</h2>
    <p class="title-desc">Coronavirus disease, which is also known as COVID-19 is a highly contagious disease, is believed to be originated from the wet markets of Wuhan, China. It is caused by a newly discovered coronavirus. The disease is spreading very fast across all the nations as it is highly transmitted from one person to another. Fortunately, most people who get this disease will experience mild to moderate respiratory issues and recover without any special treatment. However, older people or people who are already suffering from an underlying medical issue, are more prone to develop a serious illness if they catch this virus.


        </p>
        <h3>What are the symptoms?</h3>
       
 
     
</div>`,
    someData: `<div class="new">
           
           
    <p>The COVID-19 behaves differently in every person. Where for some it is just a runny nose with a mild fever, for others it may mean admission in the ICU with severe respiratory issues and for some it also turns fatal. It is noted that people who are above the age of sixty, develop more serious issues when exposed to this virus. Here are some common symptoms of Covid-19:
    </p>
    <ul>
        <li>Fever</li>
        <li>Cough</li>
        <li>Fatigue</li>
    </ul>
    <p>People may also see symptoms like:


    </p>
   
    <ul>
     <li>shortness of breath
    </li>
    <li>
        body and joint pains

    </li>       
    <li>sore and itchy throat
    </li> 
    <li>diarrhea, along with nausea 
    </li>
    <li>runny nose
    </li>
    </ul>
    <p>In an event where you experience any of the above mild symptoms, it is best to self-isolate yourself. If you experience difficulty in breathing or a experience a high fever, you must call your doctor immediately. 


</p>
<h3>How can you protect yourself?</h3>

    <p>The two best ways to protect yourself from this pandemic are – to wash your hands with soap frequently and to maintain social distancing. You should avoid touching your face and use an alcohol-based sanitizer, which contains at least seventy percent alcohol. Till now, there is no vaccine or a drug introduced to resist this disease. However, many trials and potential treatments are in making at the moment. 

        Social distancing is the best method to keep yourself distant from sick people. If one sick person stays at home, it can tremendously help to stop the disease to be passed on ahead. The same way, if you are not going out, you would never meet the person infected with the virus, thus saving yourself. 
        
        
    </p>
   <h3>How does the disease spread? </h3>
   <p>The COVID-19 virus transmits typically through droplets of saliva or discharge from the nose of an infected person when he/she coughs or sneezes. Thus, it is extremely important to cover your face or cough/sneeze into your elbow to avoid the droplets float in the air. 

    A person can show the symptoms of this virus after catching it somewhere between two to fourteen days. It really depends from one case to another. According to a study, most of them show symptoms in 11.5 days after their exposure to the virus. </p>

    <h3>How many people are affected in Australia by Covid-19?</h3>
    <p>According to the latest data, a total of 3,000 people are infected until now in Australia with Covid-19. There are nine reported deaths. Currently, there are 3,004 infected patients in Australia, with 2293 suffering mild symptoms and 11 of them are serious or critical. On the positive note, 118 have recovered from this virus.</p>
    <h3>Traveling to Australia</h3>
    <p>From 9 pm AEDT 20 March 2020, only Australian citizens, residents, and immediate family members can travel to Australia. All travelers to Australia are required to self-isolate for 14 days, either at home or in a hotel. It is advisable to check the official website for the latest update.<br><br>

        Australian citizens and Australian permanent residents are restricted from traveling overseas from 25 March 2020 at 12:00hrs AEDST. However, there are exemptions, which need to clarify with the respective authorities.<br><br>
        
        We at <strong><a href="http://www.auswidecarremovals.com.au/">Auswide Car Removals</a></strong> Services, wish the best for our country and its residents. We wish for a speedy recovery to those who are suffering and urge our healthy customers to stay at home and isolate to remain fit. <br><br>
        
        Meanwhile, you can always connect us through phone or email, if you have an inquiry related to car towing, selling your old car, junk car removal or more. Our team would be more than happy to assist you in these difficult times.</p>
</div>`,
  },
  {
    id: "benefits-of-old-car-removal",
    name: "Benefits of Old Car Removal",
    type: "blog",
    featured: "yes",
    image01: blog10,
    description:
      "When you think of Car Removals, the first name that comes in everyone’s mind is Auswide Car Removal PTY LTD. ! We are...",
    content: `<div class="title-section t-border pb-60">
    <h2>Benefits of Old Car Removal</h2>
    <p class="title-desc">When you think of Car Removals, the first name that comes in everyone’s mind is <strong><a href="http://www.auswidecarremovals.com.au/" target="_blank">Auswide Car Removal PTY LTD</a></strong>. ! We are well-known all over Australia for our exceptional Car Removals service. No matter if it is for old car removals, accidental car removals or just a <strong><a href="https://unwantedcarremovalsydney.com.au/car-removal.html">damaged car removal</a></strong>. We are number one in this service with our vast experience. Get cash for damaged cars; your damaged car could be worth up to a thousand dollars. So instead of keeping it in the garage, you may sell and get that cash which can be used in buying other valuables or even a new car. We add up all the valuable factors of your damaged car to make you their best or highest quote.
        </p>
        <p>We will buy your damaged car in any condition, whether it’s running or non-running. Even if your vehicle failed your state’s smog emission test, we would buy it from you for cash. The best part is – we even offer free towing, and you do not need to come to us. We’ll come to you! 
        </p>
        <h4>Why should you choose Auswide Car Removal PTY LTD. ?</h4>
        <ul>
            <li>Hassle free service</li>
            <li>Quick service
            </li>
            <li>No fee for towing your vehicle
            </li>
            <li>Our expert drivers are fully licensed
            </li>
            <li>We finish the entire job in a few hours
            </li>
            <li>Minimal paperwork
            </li>
        </ul>
        <h4>We are environmentally friendly!

        </h4>
        <p>We deal with a high volume of car towing orders for the unwanted, accident, junk, rusted and old card in Sydney. It is not necessary that what we remove has to be a junk piece. A lot of times, people remove a car because they never found a buyer or because they do not have time to go through all the process. Whatever the case, we responsibly dispose of all the cars to protect our environment. We have a dedicated team and possibly the best one in Australia who knows very well to dispose of the car in the right way. They begin by stripping the vehicle of all its scrap parts and components until it turns out to be a bare shell. Next, they crush and press the vehicle, making scrap metal from its mainframe. The scrap metal of the vehicle and the components are then recycled, reused, and resold, thus giving you the best value for your car! 
        </p>
     
</div>`,
    someData: `<div class="new">
           
    <h4>What types of makes and models do we accept?

 </h4>
    
     <p>The good news is we accept all sorts of makes and models, even the one which is no longer in the market. For us, what matters is you getting your right cash and quick, hassle-free service. No matter If your car is not running, ugly, unused, used, broken, damaged, we take it and give you the right price as per the markets. We also give a free towing service.  


     </p>
     <h4>How does the process work?

     </h4>
     <p>All you need to do is call our team, and we shall analyse your car and give you the right quote. You can sit, relax and sip your coffee while we do all the paperwork for you and you can then just sign and get free of your damaged car. No matter what location you want us to pick your car from, we will do it with utmost care and concern.<br><br>

         Our expert drivers are fully licensed. They know very well how to tow your car. We know even if it is a damaged car, there is a proper way to pick up the car. Additionally, we also share the GPS, so you can know where our drivers reached. Things are all easy as far as you book Auswide Car Removal PTY LTD. for your car removals.
         
         

     </p>
     <h4>Sell A Broken Or Damaged Car

     </h4>
     <p>There are different reasons which can cause a car to be called as damaged or broken. The causes fall into one of the following three categories – engine or parts damage, bodywork damage, or interior damages. It is usually a part which is damaged, and not the whole of the car, it means that the car can often be sold as salvage and the car parts will be used to help repair other vehicles.<br><br>

         For instance, if you had two of the same cars in terms of year, make, and model but one of the car had a damaged engine or some part and was a non-starter, and one of the cars had a smash-damaged shell, a car specialist would be able to use parts of each car to create one proper working vehicle. But no one has one make or the capability of fixing damaged cars.<br><br>
         
         Also, it can be time-consuming and expensive. People don’t have the needed talent to be able to do so themselves, which is the reason why vehicles that require a certain level of work. To save one from all these complex things, people prefer selling their car. Also, sometimes even repairing does not help, and the car keeps getting the same issues. The end result – you tend to spend a lot more on the car, and you do not feel safe driving it because you will have a fear that it shall break down in the middle of the journey. <br><br>
         
         Driving a damaged vehicle is also dangerous because if example the breaks are damaged, and you are on a high speed, you will meet up an accident which can cost you your life and life of your loved ones! Better than facing all the consequences is to go for car removal from a quick and professional operator. That is when Auswide Car Removal PTY LTD. comes into action.
</p>

<h4>Does it take a long time to finish the process?

</h4>
     <p>Once you have made your mind, you can simply call us, and we shall give you the best quote, something you would not find in the entire Sydney or even Australia. Further, if you have any questions or are not ready but need guidance, you can still call our customer service and understand how the entire process works. On contentment, you can then decide if you want to remove your damaged car or not. We are sure you would, and you will never regret your decision because we are known internationally for our incredible services. <br><br>

         What are you waiting for? Give us a call now! 
         
         
     </p>
    
     <h3>Accidental Car Removal
     </h3>
     <p>Accidents are the biggest tragedy, and it can happen with anyone of us. Many times, the cars get so much damaged due to the accident that you are left with no choice but to remove it. It is a headache to look out for a buyer of a damaged car because the true fact is that no one will buy a damaged piece of junk.<br><br>

         <strong><a href="http://www.auswidecarremovals.com.au/" target="_blank">Auswide Car Removal PTY LTD</a></strong> is the biggest service provider in Sydney, that would help you remove your junk and damaged car which happened due to an accident. Worry no more, because we do not only take your wrecked car but also tow it for absolute free cost. All you need to do is save our number because you never know who needs such a service next. Besides, we also provide other services like car towing no matter what the reason is, or also buying your junk car at the right price.</p>
     <h4>Why select us?</h4>
     <ul>
         <li>We come to the point where the accident has occurred in a matter of minutes
         </li>
         <li>We remove all the wreckage that has caused due to the accident
         </li>
         <li>We tow your damaged car
         </li>
         <li>We pay you the right price
         </li>
         <li>We have minimal paperwork
         </li>
         <li>We offer a quick and hassle-free service
         </li>
     </ul>

     <h4>How does the process work?

     </h4>
     <p>We have made the process of selling your accident vehicle fast and in just three simple and easy steps.

     </p>
     <ul>
         <li>You can get a quote easily over the phone. Simply dial up our number +61402700111.
         </li>
         <li>We come up with the right price for you in no time.
         </li>
         <li>Our removal team will pay the amount quoted during the pickup and relieve your stress in a matter of hours.
         </li>
     </ul>
     <p>We give the instant cash quotation depending on your car make and model. Even if your car is totally smashed, you do not need to worry. We will still pay you the amount after seeing the make and model. Auswide Car Removal PTY LTD. service is absolutely free, and you will not be charged a dime for the removal.

     </p>

     <h4>We Accept All Vehicles
     </h4>
     <p>We usually buy cars of all makes and model. Truck, SUV, Van, Ute, or anything under the sun, you name it, and we take it. At Auswide Car Removal PTY LTD. , one of our best features is that with almost no paperwork, you receive the right price. We understand that during accidents, you are already stressed, and we do not wish to increase more stress by additional paperwork. For us, our customers comfort is the most important thing. Besides, you get the right money when you remove your car with us.

     </p>

     <h4>Do You Think Your Accident Damaged Car Is Useless?

     </h4>
     <p>There can be any type of accidents. In some, there is just a minor scratch, while some totally damage the car to an unusable condition. In some cases, the car looks okay but keeps giving a lot of maintenance issues. Accidents could also mean the car is damaged in floods, or due to fire or other foreseen conditions. It could be due to human or due to environmental disasters. <br><br>
         Whatever the reason is, the car is pretty much useless to you if the damage is beyond repair. In such cases, we recommend you to sell your old, wrecked and damaged car to us and get a new one instead. It is not feasible to spend a lot of dollars on maintenance, especially if the accidental car is not new. This would lessen your regular headaches. Also, you get a good price for it with which you can use to buy a new car.</p>

 <h4>What factors should you be looking when you search for car removal?

 </h4>
 <p>If you or your loved one met with an unfortunate accident, and are looking for the best car removal service in Australia, here are the factors you would need to consider before zeroing into one service:

 </p>
 <ul>
     <li>Fast and quick service: Many operators promise but never come on time. The time goes in waiting, and your work is delayed, and it causes a lot of frustration. It is more painful if the accident happens in the late or early hours of the day.
     </li>
     <li>Operates in your area: The service should be working in your area, and it should even provide emergency service in the late and wee hours of the day. Such services are needed round the clock as tragedies can happen at any point in time.
     </li>
     <li>Hassle-free: The operator should not give you more headache by asking you to take many rounds to banks or insurance. It should have minimal paperwork and relieve you from the headache as soon as possible.
     </li>
     <li>Right cash: The operator, most importantly, should give you the right market price.
     </li>
     <li>Clear the wreck: The operator should clear the wreck caused by accident and also tow your damaged, accidental car at no additional cost. With a stressed state of mind, it is very difficult to focus on cleaning or other such issues.
     </li>
 </ul>
 <p>All the above features are available at Auswide Car Removal PTY LTD. removals.

 </p>
 <p>All you need to do is call us on +61402700111 and speak to our customer agent. You can in no time see our team at the accident spot. If you want to do this later, perhaps a day after your accident and want us to pick the car from some other location, you can do so. All that matters to us is you having a peace of mind.

 </p>
 <p>We boasted an excellent team of drivers and equipped fleet of towing trucks that carefully tows your car and handles it with care. Our drivers have a GPS system, so you can know their location and our team is immensely knowledgeable to guide to through the entire process seamlessly.

 </p>
 <p>Call us today on +61402700111 or simply save our number for any such tragedies. You could also recommend to your friend or colleague and never get disappointed with our service. Besides Sydney, we also offer our services in almost every location in Australia.

 </p>

 <h3>Old Car Removal </h3>
 <p>If you are thinking to sell your old car or remove it, only one place will give you the best cash for your vehicle – Auswide Car Removal PTY LTD. We are known for providing not only the best price but also provide the quickest and hassle-free service. 

 </p>
 <h4>Why would you sell your old car?

 </h4>
 <p>There are several reasons one can sell their old car.

 </p>
 <ul>
     <li>
         It is ancient, and you wish to buy a new one
     </li>
     <li>It is not working properly and giving you a maintenance cost every month
     </li>
     <li>The parts of the car are not easily available
     </li>
     <li>You do not have space for your old car parking after getting a new one
     </li>
 </ul>
 <p>Whatever the reason is, Auswide Car Removal PTY LTD. will provide you with the best service in town. 

 </p>
 <h4>What is the procedure to sell the old car?

 </h4>
 <p>The procedure is straightforward, and anyone can do it. 

 </p>
 <ul>
     <li>Call our number +61402700111, and our customer service representative shall guide you. 
     </li>
     <li>We give you the quote which we assure you is the best in the market. 
     </li>
     <li>We come and pick your car from the location you want us to and give you instant cash. 
     </li>
 </ul>
 <p>Selling an old car was never easy. Thanks to Auswide Car Removal PTY LTD. , this procedure hardly takes a matter of hours and gets rid of your headache. 

 </p>
 <h4>What kind of car do we buy?

 </h4>
 <p>We understand you may be hesitant to contact us, especially if your car is a very old model. But the good news is, we accept all cars, all makes, all models and all conditions. Even if your car is not working properly, or the model is discontinued, you do not have to worry. We still accept it and provide you with the best quote in Sydney, Australia. 

 </p>
 <h4>Are we reliable?

 </h4>
 <p>Yes! Absolutely. With a massive experience in this field, we are proudly one of the top leading service providers. Our drivers are experienced with a full license, and we have a fleet of equipped vehicle that can easily tow any sort of vehicle without damaging it. We also have GPS installed so you can know where our tow truck has reached. Further, our customer service is polite and knowledgeable and can answer your queries patiently. We know selling an old car has a lot of queries and you can find all the answers here. Additionally, we provide emergency services for car towing if your car breaks down in the middle of the night. All you need to do is save our number and call us when in need. In a matter of minutes, our team would be there at the spot to help you.

 </p>
 <h4>How do we contribute to nature?

 </h4>
 <p>We contribute our share to nature by disposing of the wrecked and damaged cars in an environmentally friendly way. We have a special team which handles this in such a way that it does not affect nature in a harmful way. We care for our country and the health of our people.

 </p>
 <h4>What sets us apart for other providers?

 </h4>
 <p>You can find plenty of services on the web or in the yellow pages. But we stand out of the crowd because:

 </p>
 <ul>
     <li>Our services are super quick. If you call us, we would be there in a matter of minutes. We value time and understand your time is valuable.
     </li>
     <li>Our services are transparent. Unlike other providers, we do not have any hidden cost. We give you a clear price which is also pocket-friendly, so in the end, there are no unpleasant surprises for you.
     </li>
     <li>Our services are hassle-free. We do not expect you to fill forms or take multiple rounds to our office. All you need to do is give us a quick call, and things can happen in a day’s time without any hassle. 
     </li>
     <li>Our services are round the clock and even on weekends. We understand our customers’ needs and are super flexible for them. 
     </li>
     <li>We give the best price in the industry which no one can beat. 
     </li>
 </ul>
 <h3>Benefits of Old Car Removal</h3>
 <p>If you own a car that is no longer in use, you may want to consider the benefits of old car removal by a professional company like Auswide Car Removal PTY LTD.

 </p>
 <ul>
     <li><h5>Others Can Save on Used Parts:</h5> By old car removal, you are indirectly helping other people get scrap parts off your car so they can use in their cars. This helps them save money on parts when compared to buying new car parts. Parts like fenders, side panels, hoods, doors and engine parts are just some examples of the scrap parts that other drivers may need. And if your car is in a working condition, people may benefit by buying your car from us. </li><br>
     <li><h5>Helps Prevent Environmental Pollution:</h5> You keep the old car from causing environmental pollution. The fumes these cars releases are harmful, especially when it is deteriorating and rusting in your garage. In addition, you prevent the car and its oil and fluids, tires and other elements from being in a landfill.</li><br>
     <li><h5>Removes Potential Hazards and Eyesores from Your Property:</h5> Hiring professionals like Auswide Car Removal PTY LTD. to tow off your old vehicle removes an eyesore and potential hazard from your property. Often children can get injured by playing near your old junk. The fluids leak from your car can be a potential threat to them as well as it would contaminate the soil and groundwater.</li><br>
     <li><h5>Increases the Value of Your Property:</h5> Cleaning up your property by removing an old car will maintain or even increase your property value. The entire view is better to look at.</li><br>
     <li><h5>Prevents Possible Parking Fines or Legal Issues:</h5> At times, a few local authorities or neighbourhoods you live in, may have certain regulations about parking an unusable car on your property, especially if it is very old and rusted. By having it towed away, you might avoid legal issues or fines. And in case you are paying a parking fee for that old junk, old car removal will help you relieve your burden.</li><br>
     <li><h5>Fetches You Extra Cash:</h5>Above all the other benefits, old car removal helps you gain some extra cash. It would easily fetch a few hundred dollars. You could always use this for some better investments or might as well buy a brand-new car.
     </li><br>
 </ul>
 <p>Get your free quote today! Call us at +61402700111

 </p>
 </div>`,
  },
  {
    id: "damaged-car-removal",
    name: "Damaged Car Removal in sydney",
    type: "blog",
    featured: "yes",
    image01: blog11,
    description:
      "A damaged car is a liability to anyone. One cannot use the car and also selling the damaged car is nearly impossible. Who would anyone buy a damaged car?...",
    content: `<div class="title-section t-border pb-60">
    <h2>Damaged Car Removal in sydney
    </h2>
    <p class="title-desc">A damaged car is a liability to anyone. One cannot use the car and also selling the damaged car is nearly impossible. Who would anyone buy a damaged car? Welcome to Auswide Car Removal , the best company that buys your <strong><a href="https://unwantedcarremovalsydney.com.au/car-removal.html">damaged car</a></strong> without any hassle. We do not care how much your car is damaged. We just get the burden off .
        </p>
        <p>Finding the best company to get rid of your damaged car usually means a car removal company that works around your timings. One that doesn’t require you to do a lot of paperwork for the vehicle. One that doesn’t charge you a fee for towing and one that leaves you with a cash payment for your damaged vehicle that is the right price for the car. At Auswide car removal, we buy cars, vans, 4x4s, trucks, Utes, jeeps, commercial vehicles, SUVs, buses and bikes for cash and offer just that. We aren’t fussy or selective as to the make or on the condition of the vehicles as we are the most expert. 
        </p>
       
</div>`,
    someData: `<div class="new">
           
           
           
    <p>“Auto Wreckers in Sydney”.
        Our car removal services at <strong><a href="http://www.auswidecarremovals.com.au/" target="_blank">Auswide Car Removal</a></strong> are popular in the entire town. Scheduling a removal is also very simple, but first, a cash quote for the damaged car must be obtained and accepted. We make the cash for damaged cars offers over the telephone, email or by meeting us. Once a vehicle owner likes and accepts our quote, we then schedule an easy free car removal. Our team comes to your location anywhere in Sydney at a time convenient for you. 
        Auswide car removal is a fully licensed used and scrap car buyer that is also the best Auto Wrecker in Sydney. Using the latest technology, we dispose of cars in an environment-friendly way. Our Auto Wreckers get the best value out of vehicles by using the principles of “Green Car Recycling”. Using this method, eighty to one hundred per cent of vehicles are recycled, creating the least threat of environmental hazards to the environment. 

    </p>
    <p>We get rid of your damaged cars, no matter what the model and brand. We are the most talented car removal specialists that pay vehicle owners the right cash. We give cash for your damaged vehicle, whether it has been involved in an auto accident or just needs a few repairs to be back on the road in running condition. <br><br>
        We buy cars that are severely damaged and get them recycled, keeping the environment green. </p>
    <p>In simple steps, you can get rid of your damaged vehicle. Here are the steps:
    </p>
    <ul>
        <li>Call us and describe your car to us. 



        </li>
        <li>Our team shall come and examine your car and give you the best quote.


        </li>
        <li>On accepting the quote, we buy your damaged car and give you the best cash.

        
    </li></ul>
    <p>We also take into account a variety of factors when making you an offer for your damaged vehicle. For example, your location, the salvage value of your car pre-accident, the extent of damage or mechanical and electrical problems, the current market demand for your vehicle and its parts, its make, model, mileage and age all play a role of deciding the value.
</p>

    <p>Call us at +61402700111 today and get rid of your unnecessary and unwanted vehicle today!
    </p>
   
</div>`,
  },
];
