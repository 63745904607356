import React, { useState, useEffect, useRef } from "react";
import AOS from "aos";
import { NavLink } from "react-router-dom";
import { BsCardList } from "react-icons/bs";
import { useParams } from "react-router";
import { GrCloudSoftware } from "react-icons/gr";
import { MdBusiness } from "react-icons/md";
import { FaWarehouse } from "react-icons/fa";
import { AiFillAndroid, AiFillCar, AiFillPrinter } from "react-icons/ai";
import { FaFingerprint, FaBluetooth } from "react-icons/fa";
import { CgTrack } from "react-icons/cg";
import { GiWorld, GiSteeringWheel } from "react-icons/gi";
import { BiCurrentLocation } from "react-icons/bi";
import { SiPivotaltracker, SiTransportforlondon } from "react-icons/si";
import { servicelist } from "../../assets/data/ServicesData";
import { useHistory } from "react-router-dom";

const ServiceList = () => {
  const history = useHistory();
  const { id } = useParams();
  const [service, setService] = useState(servicelist);

  useEffect(() => {
    let service = servicelist.find((service) => service.id === id);
    if (service) {
      history.push(`/services/${id}`);
      setService(service);
    }
  }, [service]);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      AOS.init({
        duration: 50,
      });
      AOS.refresh();
    }
    // return () => (mounted.current = false);
  }, []);

  return (
    <>
      <ul className="service-menu-items">
        
        <li className="service-menu-item" data-aos="fade-right">
          <a exact href="/services.html" className="nav-link">
            <span className="icon">
              <BsCardList />
            </span>
            <span>All Services</span>
          </a>
        </li>

        <li className="service-menu-item" data-aos="fade-right">
          <a exact href="/buying-junk-cars.html" className="nav-link">
            <span className="icon">
              <AiFillCar />
            </span>
            <span>Buy Junk Cars</span>
          </a>
        </li>
        <li className="service-menu-item" data-aos="fade-right">
          <a exact href="/old-car-removal.html" className="nav-link">
            <span className="icon">
              <GiSteeringWheel />
            </span>
            <span>Old Car Removal</span>
          </a>
        </li>
        <li className="service-menu-item" data-aos="fade-right">
          <a exact href="/car-wreckers-in-sydney.html" className="nav-link">
            <span className="icon">
              <SiTransportforlondon />
            </span>
            <span>Car Wreckers</span>
          </a>
        </li>
        
        <li className="service-menu-item" data-aos="fade-right">
          <a exact href="/scrap-car.html" className="nav-link">
            <span className="icon">
              <MdBusiness />
            </span>
            <span>Scrap Cars</span>
          </a>
        </li>
        <li className="service-menu-item" data-aos="fade-right">
          <a exact href="/accident-car-removal.html" className="nav-link">
            <span className="icon">
              <FaFingerprint />
            </span>
            <span>Accident Car Removal</span>
          </a>
        </li>
        <li className="service-menu-item" data-aos="fade-right">
          <a exact href="/cash-for-scrap-cars-in-sydney.html" className="nav-link">
            <span className="icon">
              <SiPivotaltracker />
            </span>
            <span>Cash for Scrap Cars</span>
          </a>
        </li>
      </ul>
    </>
  );
};

export default ServiceList;
