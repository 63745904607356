import React, { useState, useEffect } from "react";
import Button from "../Button";

const Banner = () => {
  const initialValues = {
    name: "",
    number: "",
    suburb: "",
    address: "",
    carinfo: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorCheck = validate(formValues);
    setFormErrors(errorCheck);
    setIsSubmit(true);
    if (Object.keys(errorCheck).length === 0) {
      const res = await fetch(
        "https://react-unwanted-default-rtdb.firebaseio.com//quote.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            formValues,
          }),
        }
      );
      if (res) {
        alert("done");
        resetInput();
      } else {
        alert("some error");
      }
    }
  };

  const resetInput = () => {
    setFormValues(initialValues);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    //name
    if (!/^[a-z A-Z0-9]+$/.test(values.name) || /^[ ]+$/.test(values.name)) {
      errors.name = "Name is not valid";
    }

    if (!values.name) {
      errors.name = "Name is Required";
    }

    if (values.number) {
      if (values.number.length < 10) {
        errors.number = "Enter atleast 10 digits";
      }
    }

    if (!values.number) {
      errors.number = "Phone Number is Required";
    }

    return errors;
  };

  return (
    <>
      <section className="banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12 banner-left">
              <h1 className="banner-heading">
                #1 Unwanted car removal company in Sydney Instant Cash For Cars Up
                to $9,999
              </h1>
              <p className="banner-text">
                Accept All Makes And Models Of Cars Free + Same Day Removal
                Service
              </p>
            </div>
            <div className="col-lg-6 col-12 banner-right">
              <div className="enquiry text-left">
                <h3 className="enquiry-heading">
                  Find & Book <br /> a Great Deal Today
                </h3>
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        name="number"
                        placeholder="Phone Number"
                        value={formValues.number}
                        onChange={handleChange}
                      />
                      <small className="text-red">{formErrors.number}</small>
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Name"
                        name="name"
                        onChange={handleChange}
                        value={formValues.name}
                      />
                      <small className="text-red">{formErrors.name}</small>
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        name="name"
                        onChange={handleChange}
                        value={formValues.email}
                      />
                      <small className="text-red">{formErrors.email}</small>
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Suburb"
                        name="suburb"
                        onChange={handleChange}
                        value={formValues.suburb}
                      />
                      <small className="text-red">{formErrors.suburb}</small>
                    </div>{" "}
                    <div className="form-group col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pick up Address"
                        name="address"
                        onChange={handleChange}
                        value={formValues.address}
                      />
                      <small className="text-red">{formErrors.address}</small>
                    </div>{" "}
                    <div className="form-group col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Car Info"
                        name="carinfo"
                        onChange={handleChange}
                        value={formValues.carinfo}
                      />
                      <small className="text-red">{formErrors.carinfo}</small>
                    </div>
                    <div className="form-group col-12 text-center">
                      <br />
                      <Button big danger type="submit" onClick={handleSubmit}>
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
