import React, { useState } from "react";
import AboutJoin from "../components/About/AboutJoin";
import Button from "../components/Button";
import aboutimg from "../assets/images/about/map.png";
import Modal from "react-bootstrap/Modal";
import { AiFillPlayCircle } from "react-icons/ai";
import MetaTags from "react-meta-tags";
import Faqs from "../components/Elements/Faqs";

const About = () => {

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);

  return (
    <><MetaTags>
    <title>About Us Unwanted car Removals in Sydney</title>
    <meta name="description" content="Auswide Car Removal is a car removal service provider in Sydney, offering you $30000
    the best price on your junk cars. Established since 2014." />
    <meta
      name="keywords"
      content="junk car removals sydney, damaged car removal sydney,unwanted car removals sydney, sell your car for cash sydney, scrap car removal sydney, sydney car removal, buy junk cars sydney, cash for junk cars in sydney, cash for scrap cars in sydney, unwanted car removals in sydney, cash for cars in sydney"
    />
  </MetaTags>
      <section className="about-page-wrapper py-4">
        <div className="about-section container pt-5">
          <div className="about-text row">
            <div className="about-quality col-lg-6 col-md-6 col-12">
              <div className="about-quality-1">
                <h4>We deal with services like scrap your old car, buying it and giving you the cash for your junk cars.</h4>
                <p>
                At Auswide Car Removal, we understand how valuable your time is. Our trained customer representatives help you with all the services and give you the best rates in town almost instantly.
                </p>
                <p>With a vast experience, we quote the best for your junk cars and guide you throughout so you can relax and sell your car smoothly. We buy cars in any condition such as smashed, not running, burned, water damaged, hail damaged, very old and dirty condition, or used roughly and is in a bad state.</p>
                <p>We deal with services like scrap your old car, buying it and giving you the cash for your junk cars which is perhaps the best in town. We are Sydney’s number 1 brand because we offer payments instantly, and you can sell your junk car to us without any hassle using our world-class customer support.</p>
              </div>
              <div className="about-quality-2">
              </div>
              <div className="bn">
              <a href="tel:61402700111" title="Toll Free Number"><Button>Call Now</Button></a>
              </div>
            </div>
            <div className="about-image col-lg-6 col-md-6 col-12">
              <img src={aboutimg} alt="about-image img-fluid" loading="lazy"/>
            </div>
          </div>
        </div>
        <AboutJoin />
        <div className="join-section container py-4">
          <div className="row">
            <div className="about-text col-lg-6 col-md-6 col-12">
              <h3 className="about-heading">Who we are</h3>
              <div className="about-text-para">
                <p>
                Auswide Scrap Car Services.
                </p>
                <p>
                A car removal service provider in Sydney, offering you the best price on your junk cars. Established since 2014, we buy all types, makes, models, and years of junk cars.
                </p>
                <p>We deal with services like scrap your old car, buying it and giving you the cash for your junk cars which is perhaps the best in town. We also deal in removal of junk cars from a given location. We are Sydney’s number 1 brand because we offer payments instantly, and you can sell your junk car to us without any hassle using our world-class customer support.</p>
              </div>
            </div>
            <div className="about-image col-lg-6 col-md-6 col-12">
              <div className="youtube-service">
                      <div
                        className="btn btn--white btn--animated"
                        onClick={handleShow}
                      >
                        <AiFillPlayCircle />
                      </div>
                    </div>
              <Modal
                show={show}
                onHide={handleShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Body>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/qm37tfipAH0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </section>
      <Faqs />
    </>
  );
};

export default About;
