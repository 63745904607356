import React from "react";
import "./assets/scss/style.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

///Pages
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Blogs from "./pages/Blogs";
import Services from "./pages/Services";
import Locations from "./pages/Locations";
import ServiceDetail from "./pages/ServiceDetail";
import BlogDetail from "./pages/BlogDetail";
import Offer from "./pages/Offer";

// Elements
import MainNavigation from "./components/Navigation/MainNavigation";
import Topbar from "./components/Topbar/Topbar";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollTop";
import Whatsapp from "./components/whatsapp/Whatsapp";
import PrivacyPolicy from "./pages/PrivacyPolicy";


function App() {
 
  return (
    <Router>
      <Whatsapp />
      <ScrollToTop />
      <Topbar />
      <MainNavigation />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/about.html" component={About} />
        <Route exact="true" path="/services.html" component={Services} />
        <Route exact="true" path="/locations.html" component={Locations} />
        <Route exact  path="/blogs.html" component={Blogs} />
        <Route exact path="/contact.html" component={Contact} />
        <Route exact path="/offer.html" component={Offer} />
        <Route exact path="/privacy-policy.html" component={PrivacyPolicy} />
        <Route exact="true" path="/:id" component={ServiceDetail}/>
        <Route exact="true" path="/blogs/:id" component={BlogDetail}/>

      </Switch>
      <Footer />
      {/* </Suspense> */}
    </Router>
  );
}

export default App;
