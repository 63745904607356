import React from "react";
import { bloglist }  from "../assets/data/BlogsData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Title from "./Title/Title";
import Para from "./Title/Para";
import { Link } from "react-router-dom";

const Blog = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="blog_wrapper py-5">
        <Title title="Our blogs" />
        <Para para="Auswide Car Removal is a car removal service provider in Sydney, offering you the best price on your junk cars. Established since 2014." />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Slider {...settings}>
                {bloglist.map((blog, index) => {
                  return (
                    <div className="row mb-4" key={index}>
                      <div
                        className="card bg-white border-1 rounded-0 m-2 blog-card"
                      >
                        <div className="blog-img">
                        <img src={blog.image01} alt="" className="img-fluid" loading="lazy"/>
                        </div>
                        <div className="card-body d-flex p-4">
                          <div>
                            <ul>
                              <li>
                                {blog.date}
                              </li>
                            </ul>
                            <h4>
                              <Link to="/" className="p-0">
                                {blog.name}
                              </Link>
                            </h4>
                            <p className="blog-desc">{blog.description}</p>
                            <Link to={`/blogs/${blog.id}.html`} className="text-left p-0">
                              Read More..
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
