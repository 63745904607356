import React, { useState } from 'react'
import { faqs } from '../../assets/data/faq'
import Title from "../Title/Title";
import Para from "../Title/Para";

const Faqs = () => {

  const [data1, setData1] = useState(faqs);
  const [show, setShow] = useState(false);

  return (
    <>
        <section className='faqs'>
        <Title title="FAQ's" />
        <Para para="Auswide Car Removal is a car removal service provider in Sydney, offering you the best price on your junk cars. Established since 2014." />
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 col-12 faq-flex'>
                      {
                        data1.map((faq, id) => {
                          return (
                            <div className='accordion-item'>
                              <button className='faq-ques accordion-btn' onClick={() => setShow(!show)}><h4 className='accordion-caption'>{faq.ques}</h4> <span className='accordion-icon'>+</span></button>

                              { show && <div className='accordion-content'><p>{faq.ans}</p></div>}
                            </div>
                          )
                        })
                      }
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Faqs