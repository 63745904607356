import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const Whatsapp = () => {
  return (
    <div className="whatsapp-fixed">
      <a href="https://wa.me/61402700111" target="_blank" className="whtsp-link">
        <FaWhatsapp className="whatsapp-icon"/>
      </a>
    </div>
  );
};

export default Whatsapp;
