import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Title from "./Title/Title";

import audi from '../assets/images/brands/audi.png'
import bmw from '../assets/images/brands/bmw.png'
import ferrari from '../assets/images/brands/ferrari.png'
import fordlogo from '../assets/images/brands/fordlogo.jpg'
import honda from '../assets/images/brands/honda.png'
import mercedes from '../assets/images/brands/mercedes.png'
import toyota from '../assets/images/brands/toyota.jpg'
import volswogn from '../assets/images/brands/volswogn.jpg'


const Brands = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="brands">
      <Title title="Brands we Deal" />

      <div className="container">
        <div className="row">
          <div className="col-12">
            <Slider {...settings}>
            <div className="row mb-4">
                <img src={audi} className="img-fluid" height="100" width="150" loading="lazy"/>
            </div>
            <div className="row mb-4">
                <img src={bmw} className="img-fluid"  height="100" width="150"loading="lazy"/>
            </div> <div className="row mb-4">
                <img src={ferrari} className="img-fluid"  height="100" width="150"loading="lazy"/>
            </div> <div className="row mb-4">
                <img src={fordlogo} className="img-fluid"  height="100" width="150"loading="lazy"/>
            </div> <div className="row mb-4">
                <img src={honda} className="img-fluid"  height="100" width="150"loading="lazy"/>
            </div> <div className="row mb-4">
                <img src={mercedes} className="img-fluid" loading="lazy"/>
            </div> <div className="row mb-4">
                <img src={toyota} className="img-fluid" loading="lazy"/>
            </div>
            <div className="row mb-4">
                <img src={volswogn} className="img-fluid" loading="lazy"/>
            </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Brands;
