import React from "react";
import ServiceItem from "../Service/ServiceItem";

const LocationLists = ({ services }) => {

  return (
    <div className="col-lg-12">
      <div className="row mb-4">
        {services.filter(servicetype => servicetype.type === "location").map((service) => (
          <ServiceItem service={service} /> 
        ))}
      </div>
    </div>
  );
};

export default LocationLists;
