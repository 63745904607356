import React, { useState, useEffect, useRef } from "react";
import { bloglist } from "../assets/data/BlogsData";
import Backdrop from "../components/Backdrop";
import { BiSearch } from "react-icons/bi";
import AOS from "aos";
import BlogLists from "../components/Blog/BlogLists";
import { FiSettings } from 'react-icons/fi';
import NoData from "../components/NoData/Nodata";
import MetaTags from "react-meta-tags";


const Services = () => {
  const categoryRef = useRef(null);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [blogs, setBlogs] = useState(bloglist);
  const [searchTerm, setSearchTerm] = useState();

  const showHideFilter = () => {
    categoryRef.current.classList.toggle("active");
    setDrawerIsOpen(true);
  };

  const HideFilter = () => {
    categoryRef.current.classList.toggle("active");
    setDrawerIsOpen(false);
  };

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      // setLoading(true);
      AOS.init({
        duration: 50,
      });
      AOS.refresh();
    }
    return () => (mounted.current = false);
  }, []);

  const closeDrawerHandler = () => {
    showHideFilter();
    setDrawerIsOpen(false);
  };

  const searchservice = (e) => {
    setSearchTerm(e.target.value);
    console.log(searchTerm);
    setBlogs(
      bloglist.filter((val) => val.name.toLowerCase().includes(e.target.value.toLowerCase()))
    );
  };

  const filterItem = (catItem) => {
    console.log("true");
    const updatedItem = bloglist.filter((curelem) => {
      return curelem.websitType === catItem;
    });
    setBlogs(updatedItem);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
    <MetaTags>
          <title>[Unwanted car removal] Our Blogs</title>
          <meta name="description" content="Auswide Car Removals is the Number#1 We buyer of junk cars in Sydney. We give you the right price for your junk car, and the entire process is simple and hassle-free.offering you $30000" />
          <meta
            name="keywords"
            content="buy junk cars in sydney, cash for junk cars in sydney, cash for scrap cars in sydney, unwanted car removals in sydney, cash for cars in sydney, scrap car in sydney, old car removal, car wreckers in sydney"
          />
        </MetaTags>
      <section className="services_page_wrapper mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-12">
              <div className="category-filter-icon mb-3">
                <div className="search-box">
                  <input
                    type="text"
                    placeholder="Search Blogs"
                    onChange={searchservice}
                  />
                  <BiSearch />
                </div>
                <div className="filter-slide" onClick={() => showHideFilter()}>
                  <FiSettings className="filter-icon"/>
                </div>
              </div>
              <div className="catalog__filter mb-4" ref={categoryRef}>
                {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
                <div className="search-box desktop-device">
                  <input
                    type="text"
                    placeholder="search services"
                    onChange={searchservice}
                  />
                  <BiSearch />
                </div>
                {/* <h4>Most Viewed Blogs</h4> */}
              </div>
            </div>
            
            {
              blogs.length > 0 ? (
                <BlogLists
              blogs={blogs}
            />
              ) : (
                <>
                
                  <div className="col-9 text-center">
                  <NoData />
                </div>
                </>
              )
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
