import React from 'react';

const Topbar = () => {
  return (
      <>
        <section className='topbar'>
            <div className='topbar-container'>
                <div className='topbar-area'>
                    <div className='topbar-email'>
                    <a href="mailto:aus@unwantedcarremovalsydney.com.au" title="Toll Free Number"><i className="fa fa-envelope-o" aria-hidden="true"></i><span> aus@unwantedcarremovalsydney.com.au</span></a>
                    </div>
                   
                    <div className='topbar-number'>
                    
                    <a href="tel: +61402700111"><i className="fa fa-phone" aria-hidden="true"></i><span> +61402700111</span></a>
                    </div>
                </div>
            </div>
        </section>
      </>
  );
};



export default Topbar;
