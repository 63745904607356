import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import ServiceList from "../components/Service/ServiceList";
import Backdrop from "../components/Backdrop";
import { servicelist } from "../assets/data/ServicesData";
import { FiSettings } from "react-icons/fi";
import AOS from "aos";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import ReactHtmlParser from "react-html-parser";
import MetaTags from "react-meta-tags";

const Detail = () => {
  const { id } = useParams();

  console.log("name", id);
  const [service, setService] = useState(null);

  console.log("service", service);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      AOS.init({
        duration: 50,
      });
      AOS.refresh();
    }
    return () => (mounted.current = false);
  }, []);

  useEffect(() => {
    let service = servicelist.find((service) => service.id + ".html" == id);
    console.log("=======.", service);

    if (service) {
      setService(service);
    }
  }, [service]);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);

  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const categoryRef = useRef(null);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const showHideFilter = () => {
    categoryRef.current.classList.toggle("active");
    setDrawerIsOpen(true);
  };
  const closeDrawerHandler = () => {
    showHideFilter();
    setDrawerIsOpen(false);
  };

  return (
    <>
      <MetaTags>
        <title>[Unwanted car removal] in Sydney|cash for car Sydney</title>
        <meta
          name="description"
          content="Auswide Car Removal is a car removal service provider in Sydney, offering you $30000 the best price on your junk cars. Established since 2014."
        />
        <meta
          name="keywords"
          content="cash for car Sydney, junk car removals sydney, damaged car removal sydney,unwanted car removals sydney, sell your car for cash sydney, scrap car removal sydney, sydney car removal, buy junk cars sydney, cash for junk cars in sydney, cash for scrap cars in sydney, unwanted car removals in sydney, cash for cars in sydney"
        />
      </MetaTags>
      {service ? (
        <>
          <div
            className="servicepage_background-banner-1 py-5"
            data-aos="fade-up"
            data-aos-duration="100"
          >
            <div className="container py-5">
              <h3 className="text-left text-light">{service.name}</h3>
              <p className="text-left text-light wid-50">
                you want to sell your junk vehicle, you are in the right place.
                We buy all sorts of junk vehicles and give you a very good
                quote. We Buying Junk Cars, SUV, Trucks, Vans, and more
              </p>
            </div>
          </div>
          <section className="servicepage2_wrapper">
            <div className="container">
              <div className="row py-5">
                <div className="col-lg-3 col-md-12 col-12 service-list">
                  <div className="category-filter-icon mb-3">
                    <div
                      className="filter-slide"
                      onClick={() => showHideFilter()}
                    >
                      <FiSettings className="filter-icon" />
                    </div>
                  </div>
                  <div className="service-mobile-list mb-4" ref={categoryRef}>
                    {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
                    <ServiceList />
                  </div>
                </div>
                <div className="col-lg-9 col-md-12 col-12 content-div">
                  <div className="service-quote">
                    <p>{service.description}.</p>
                  </div>
                  <div className="servicepage-img">
                    <img src={service.image01} alt="" className="img-fluid" loading="lazy"/>
                  </div>

                  <div className="col-12 mt-5">
                    <div className="hardware-tabs">
                      <Tab.Container
                        defaultActiveKey="tab1"
                        transition={true}
                        id="noanim-tab-example"
                        className="nav-tabs"
                      >
                        <Nav variant="tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="tab1">
                              {service.tabHeading1}
                            </Nav.Link>
                          </Nav.Item>
                          {service.tabHeading2 ? (
                            <Nav.Item>
                              <Nav.Link eventKey="tab2">
                                {service.tabHeading2}
                              </Nav.Link>
                            </Nav.Item>
                          ) : (
                            ""
                          )}

                          {service.tabHeading3 ? (
                            <Nav.Item>
                              <Nav.Link eventKey="tab3">
                                {service.tabHeading3}
                              </Nav.Link>
                            </Nav.Item>
                          ) : (
                            ""
                          )}
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="tab1">
                            {ReactHtmlParser(service.tabContent1)}
                          </Tab.Pane>
                          <Tab.Pane eventKey="tab2">
                            {service.tabContent2 ? (
                              <>{ReactHtmlParser(service.tabContent2)}</>
                            ) : (
                              ""
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey="tab3">
                            {service.tabContent3 ? (
                              <>{ReactHtmlParser(service.tabContent3)}</>
                            ) : (
                              "No Data..."
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>

                  <div className="servicepage-name">
                    <h4>{service.name}</h4>
                  </div>
                  <div className="row content-row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <p>{ReactHtmlParser(service.someData)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        "Service Detail Page no Content"
      )}
    </>
  );
};

export default Detail;
